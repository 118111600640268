import React, { Component } from "react";

class AuthLoader extends Component {
  render() {
    return (
      <div className="authLoader">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default AuthLoader;
