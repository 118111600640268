export const claimTypes = [
  { key: "shortage", text: "Shortage", value: "shortage" },
  { key: "damage", text: "Damage", value: "damage" },
  { key: "return", text: "Return", value: "return" },
  { key: "loadFeedback", text: "Load Feedback", value: "loadFeedback" },
];
export const shipmentType = [
  { key: "individualItem", text: "Individual Item", value: "individualItem" },
  { key: "palletGoods", text: "Pallet Goods", value: "palletGoods" },
  { key: "tote", text: "Tote", value: "Tote" },
  { key: "directShip", text: "Direct Ship", value: "directShip" },
];
export const reasonOptions = {
  damage: [
    {
      key: "damage1",
      text: "Damage truck poorly loaded RDC error",
      value: "B - Damage: Truck Poorly Loaded RDC Error_rfa",
      invoicetype: "rfa",
    },
    {
      key: "damage2",
      text: "Damaged out of the box",
      value: "PolicyA_1",
      invoicetype: "policya",
    },
    {
      key: "damage3",
      text: "Missing Parts",
      value: "PolicyA_2",
      invoicetype: "policya",
    },
    {
      key: "damage4",
      text: "Non-functional out of the box",
      value: "PolicyA_3",
      invoicetype: "policya",
    },
    {
      key: "damage5",
      text: "Stopped working(Out of Warranty)",
      value: "PolicyA_4",
      invoicetype: "policya",
    },
    {
      key: "damage6",
      text: "Stopped working(Under Warranty)",
      value: "PolicyA_5",
      invoicetype: "policya",
    },
  ],
  return: [
    {
      key: "return1",
      text: "Adjusted pricing error",
      value: "A - Adjustment: Pricing Error_rfa",
      invoicetype: "rfa",
    },
    {
      key: "return2",
      text: "Does not match packagin Description",
      value: "PolicyA_6",
      invoicetype: "policya",
    },
    {
      key: "return3",
      text: "Mis-ship Keep",
      value: "C - Misship: Keep_rfa",
      invoicetype: "rfa",
    },
    {
      key: "return4",
      text: "Other",
      value: "PolicyA_7",
      invoicetype: "policya",
    },
    {
      key: "return5",
      text: "Overage Keep",
      value: "E - Overage: Keep_rfa",
      invoicetype: "rfa",
    },
    {
      key: "return6",
      text: "Overage Return",
      value: "F - Overage: Return_rfa",
      invoicetype: "rfa",
    },
    {
      key: "return7",
      text: "Retrun member decision",
      value: "G - Return: Member Decision_rfa",
      invoicetype: "rfa",
    },
    {
      key: "return8",
      text: "Retrun Non invoiced product",
      value: "D - Return: Non-Invoiced Product_rfa",
      invoicetype: "rfa",
    },
    {
      key: "return9",
      text: "Retrun Unacceptable Substitution",
      value: "H - Return: Unacceptable Substitution_rfa",
      invoicetype: "rfa",
    },
  ],
  shortage: [{ key: "shortage", text: "Shortage", value: "I - Shortage_rfa" }],
};
