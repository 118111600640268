import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  FormGroup,
  Input,
  FormSelect,
  Loader,
  Pagination,
  Popup,
} from "semantic-ui-react";
import { FEEDBACK_HISTORY } from "../../urlConstants";
import { useSelector } from "react-redux";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import restUtils from "../../utils/restUtils";
import { createMedia } from "@artsy/fresnel";
import { useImmer } from "use-immer";
import CustomTable from "../CustomTable";
import { BreadcrumbComp } from "../common/BreadcrumbComp";
import { loadFeedbackHistory } from "../common/TableConstant";
import { Modal, ModalContent } from "semantic-ui-react";
import useStableCallback from "../common/CustomHook/useStableCallback";
import { FeedbackHistoryDetails } from "./LoadFeedbackDetails";
import _ from "lodash";
import utilities from "../../utils/utilities";

export const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    xm: 0,
    sm: 567,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
const sortOpt = [
  { key: "Feedback_nbr", text: "load Feedback Nbr", value: "loadFeedback_Nbr" },
  { key: "invoiceNbr", text: "Invoice Number", value: "invoice_nbr" },
  { key: "crmNumber", text: "CRM Number", value: "crM_Nbr" },
  { key: "createdDate", text: "Created Date", value: "Created_Date" },
];

const searchOpt = [
  { key: "Feedback_nbr", text: "load Feedback Nbr", value: "LoadFeedback_Nbr" },
  { key: "crmNumber", text: "CRM Number", value: "crM_Nbr" },
  { key: "invoiceNbr", text: "Invoice Number", value: "Invoice_Nbr" },
  { key: "createdDate", text: "Created Date", value: "Created_Date" },
];

const sortOrder = [
  { key: "asc", text: "Ascending", value: "asc" },
  { key: "desc", text: "Descending", value: "desc" },
];

export const LoadFeedbackHistory = () => {
  const { storeId } = useSelector((state) => state.SessionReducer);
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useImmer({
    currentPage: 1,
    totalPages: 1,
    totalCount: "",
  });

  const [sorting, setSorting] = useImmer({
    sortBy: "Created_Date",
    order: "desc",
  });
  const [searchDate, setSearchDate] = useImmer({
    fromDate: "",
    toDate: "",
  });
  const [searchBy, setSearchBy] = useImmer({
    label: "",
    value: "",
  });
  const [historyDetails, setHistoryDetails] = useImmer({
    id: null,
    data: {},
  });
  const [noData, setNoData] = useState(false);
  const fetchHistory = (isSearch, searchByDate = false) => {
    let requestPayLoad = {
      filterType: isSearch ? searchBy.label : "",
      filterValue_1: isSearch ? searchBy.value : "",
      rowsPerPage: 50,
      pageNbr: pagination.currentPage,
      sortColumn: sorting.sortBy,
      sortOrder: sorting.order,
    };
    if (searchByDate) {
      requestPayLoad = {
        ...requestPayLoad,
        filterValue_1: utilities.getDateFromTimeStampShip(searchDate.fromDate),
        filterValue_2: utilities.getDateFromTimeStampShip(searchDate.toDate),
      };
    }
    setIsLoading(true);
    restUtils
      .postDataWithBearer(
        `${FEEDBACK_HISTORY}/${storeId}/GetLoadFeedbackHistory`,
        requestPayLoad,
      )
      .then((res) => {
        setIsLoading(false);
        console.log(res.data);
        if (_.isEmpty(res.data)) {
          setNoData(true);
          return;
        }
        setNoData(false);
        setProduct(res.data.lbHistoryList);
        setPagination((draft) => {
          draft.totalPages = res.data.pageCount;
          draft.totalCount = res.data.totalCount;
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    fetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, pagination.currentPage, sorting]);

  const onpageClick = useStableCallback(
    (e) => {
      let queryPage = e.currentTarget.text;
      let { totalPages, currentPage } = pagination;
      if (e.currentTarget.text === ">") {
        queryPage = currentPage < totalPages ? currentPage + 1 : currentPage;
      }
      if (e.currentTarget.text === "<") {
        queryPage = currentPage === 1 ? 1 : currentPage - 1;
      }
      if (e.currentTarget.text === "»") {
        queryPage = totalPages;
      }
      if (e.currentTarget.text === "«") {
        queryPage = 1;
      }
      setPagination((draft) => {
        draft.currentPage = queryPage;
      });
      //this.scrollPosition = window.pageYOffset;
      window.scrollTo(0, 0);
    },
    [pagination],
  );

  const viewDetailsPopup = useStableCallback(
    (id) => {
      setIsLoading(true);
      restUtils
        .getDataWithBearer(
          `${FEEDBACK_HISTORY}/${storeId}/GetLoadFeedbackHistoryDetails?loadFeedbackId=${id}`,
        )
        .then((res) => {
          setHistoryDetails((draft) => {
            draft.id = id;
            draft.data = res.data;
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    [historyDetails],
  );

  const closeDetailsPopup = () => {
    setHistoryDetails((draft) => {
      draft.id = null;
      draft.data = {};
    });
  };
  const handleSort = (e, data) => {
    setSorting((draft) => {
      draft[data.name] = data.value;
    });
  };
  const handleSearchBy = (e, data) => {
    if (data.name === "label") {
      setSearchBy((draft) => {
        draft.label = data.value;
        draft.value = "";
      });
      return;
    } else {
      setSearchBy((draft) => {
        draft[data.name] = data.value;
      });
    }
  };
  const handleSearchDate = (e, data) => {
    setSearchDate((draft) => {
      draft[data.name] = data.value;
    });
  };
  const handleSearchSubmit = () => {
    const { value } = searchBy;

    if (value === "") {
      utilities.showToast("Please Enter The Search Value", true);
      return;
    }
    setPagination((draft) => {
      draft.currentPage = "1";
    });
    fetchHistory(true);
  };
  const handleSearchDateSubmit = () => {
    const { fromDate, toDate } = searchDate;
    if (fromDate === "" || toDate === "") {
      utilities.showToast("Please Enter Valid Date", true);
      return;
    }
    setPagination((draft) => {
      draft.currentPage = "1";
    });
    fetchHistory(true, true);
  };
  const handleSearchClear = () => {
    setSearchBy((draft) => {
      draft.label = "";
      draft.value = "";
    });
    fetchHistory(false);
  };
  const breadCrumbLink = [
    { link: "/", text: "Home" },
    { link: "/loadFeedback", text: "Load Feedback" },
    { link: "/loadFeedback/", text: "View History", active: true },
    { link: "/", text: "Inactive" },
  ];
  return (
    <>
      {isLoading && <Loader active />}
      <div className="loadFeedBackHistory">
        <>
          <h3>Load Feedback History</h3>
          <div className="tableInfoHeader">
            <div>
              <BreadcrumbComp path={breadCrumbLink} />
            </div>
          </div>

          <div className="searchDataWrapper">
            <div className="searchTypeDiv">
              <FormGroup>
                <FormSelect
                  label="Search By"
                  name="label"
                  placeholder="Select Search Type "
                  value={searchBy.label}
                  options={searchOpt}
                  width={5}
                  required
                  onChange={handleSearchBy}
                />
                <div className="loadFeedbackSearchBlock">
                  {searchBy.label === "Created_Date" ? (
                    <>
                      <SemanticDatepicker
                        name="fromDate"
                        onChange={handleSearchDate}
                        id="startDatePicker1"
                        maxDate={new Date()}
                      />
                      <SemanticDatepicker
                        name="toDate"
                        onChange={handleSearchDate}
                        id="startDatePicker2"
                        minDate={
                          searchDate.fromDate !== "" && searchDate.fromDate
                        }
                        maxDate={new Date()}
                      />
                      <span
                        className="loadFeedbackSearchIcon"
                        onClick={handleSearchDateSubmit}
                      >
                        <i className="fa-search icon icon"></i>
                      </span>
                    </>
                  ) : (
                    <>
                      <Input
                        placeholder=""
                        value={searchBy.value}
                        name={"value"}
                        onChange={handleSearchBy}
                        width={5}
                        disabled={_.isEmpty(searchBy.label)}
                      />
                      <span
                        className="loadFeedbackSearchIcon"
                        onClick={handleSearchSubmit}
                      >
                        {" "}
                        <i className="fa-search icon icon"></i>
                      </span>
                    </>
                  )}
                  <Popup
                    trigger={
                      <span
                        className="loadFeedbackSearchIcon clearIcon"
                        onClick={handleSearchClear}
                      >
                        <i className="fas fa-times"></i>
                      </span>
                    }
                    content="Clear Search"
                    size="mini"
                    position="top right"
                    basic
                  ></Popup>
                </div>
              </FormGroup>
            </div>
            <div className="sortTypeDiv">
              <FormGroup>
                <FormSelect
                  label="Sort By"
                  name="sortBy"
                  value={sorting.sortBy}
                  options={sortOpt}
                  width={5}
                  onChange={handleSort}
                  required
                />
                <FormSelect
                  name="order"
                  value={sorting.order}
                  options={sortOrder}
                  width={5}
                  onChange={handleSort}
                  required
                />
              </FormGroup>
            </div>
          </div>
          {!noData ? (
            <>
              <p className="itemCountInfo">
                <span>{pagination.currentPage}</span>-{" "}
                {pagination.totalCount > 50 ? "50" : pagination.totalCount}{" "}
                <span>of {pagination.totalCount} Items</span>
              </p>
              <CustomTable
                product={product}
                tableType="loadFeedback"
                tableContent={loadFeedbackHistory}
                viewDetailsPopup={viewDetailsPopup}
              />
              <div className="paginationBlock">
                <Pagination
                  className="Pagination"
                  activePage={pagination.currentPage}
                  onPageChange={(e) => onpageClick(e)}
                  firstItem={
                    pagination.currentPage === 1
                      ? { content: <span className="disabled">«</span> }
                      : { content: "«" }
                  }
                  lastItem={
                    pagination.currentPage === pagination.totalPages
                      ? { content: <span className="disabled">»</span> }
                      : { content: "»" }
                  }
                  prevItem={
                    pagination.currentPage === 1
                      ? { content: <span className="disabled">{"<"}</span> }
                      : { content: "<" }
                  }
                  nextItem={
                    pagination.currentPage === pagination.totalPages
                      ? { content: <span className="disabled">{">"}</span> }
                      : { content: ">" }
                  }
                  totalPages={pagination.totalPages}
                  pointing
                  secondary
                />
              </div>
            </>
          ) : (
            <p className="noData">No Item to Display</p>
          )}
        </>
        {historyDetails.id && !_.isEmpty(historyDetails.data) && (
          <Modal className="viewDetailsPopup" open={true}>
            <ModalContent>
              <FeedbackHistoryDetails
                item={historyDetails.data}
                id={historyDetails.id}
                closeDetailsPopup={closeDetailsPopup}
              />
            </ModalContent>
          </Modal>
        )}
      </div>
    </>
  );
};
export default LoadFeedbackHistory;
