import React, { memo, useState, useRef, useEffect } from "react";
import {
  Form,
  FormField,
  FormGroup,
  Loader,
  FormInput,
  Modal,
  ModalContent,
  FormSelect,
} from "semantic-ui-react";
import { CLAIMS_BASE_API } from "../../urlConstants";
import { useSelector } from "react-redux";
import "./style.scss";
import restUtils from "../../utils/restUtils";
import { WindowScroller, Table, Column } from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import utilities from "../../utils/utilities";
import _ from "lodash";
import { useImmer } from "use-immer";
import history from "../../history";
const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const alphabetOpt = alphabet.map((ele) => {
  return {
    key: ele,
    text: ele,
    value: ele,
  };
});

const DirectShipSearch = () => {
  const { storeId } = useSelector((state) => state.SessionReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [popUpLoader, setPopUpLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState([]);
  const [innerWidth, setInnerWidth] = useState(0);
  const [word, setWord] = useState("");
  const [model, setModel] = useState("");
  const [vendorDetail, setVendorDetail] = useState({});
  const [description, setDescription] = useState(null);
  const divRef = useRef(null);
  const [error, setError] = useImmer({
    vendor: false,
    model: false,
  });
  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        setInnerWidth(divRef.current.clientWidth);
      }
    };

    // Initial measurement
    if (divRef.current) {
      setInnerWidth(divRef.current.clientWidth);
    }

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleSelectVendor = () => {
    setShowPopup(true);
  };
  const getVendorData = (value, isWord = false) => {
    setPopUpLoader(true);
    let url = `${CLAIMS_BASE_API}/${storeId}/SearchVendorDetails`;
    if (isWord) {
      url = url + `?vendorWord=${value}`;
    } else {
      url = url + `?vendorLetter=${value}`;
    }
    restUtils
      .getDataWithBearer(url)
      .then((res) => {
        console.log(res);
        setData(res.data);
        setPopUpLoader(false);
      })
      .catch((err) => {
        setPopUpLoader(false);
        console.log(err);
      });
  };
  const handleOnchange = (e, data) => {
    getVendorData(data.value);
  };

  function searchWord() {
    if (word === "") {
      utilities.showToast("Enter the search word");
      return;
    }
    getVendorData(word, true);
  }
  const updateVendor = (vendor) => {
    console.log(vendor);
    const getVendor = data.find((ele) => ele.vendorName === vendor);
    setError((draft) => {
      draft.vendor = false;
    });
    setVendorDetail(getVendor);
    setShowPopup(false);
  };
  function searchModel() {
    if (model === "" || _.isEmpty(model)) {
      setError((draft) => {
        draft.model = true;
      });
    }
    if (_.isEmpty(vendorDetail)) {
      setError((draft) => {
        draft.vendor = true;
      });
    }
    if (model === "" || _.isEmpty(vendorDetail)) {
      utilities.showToast("Vendor and Model fields are mandatory", true);
      return;
    }
    setIsLoading(true);
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/GetPolicyADirectShipItemShortDesc?modelNbr=${model}&vendorId=${vendorDetail.vendorNumber}`,
      )
      .then((res) => {
        setIsLoading(false);
        if (_.isEmpty(res.data)) {
          setDescription("No short description available");
          //setIsError(true)
          return;
        }
        //setIsError(false)
        setDescription(res.data);
      })
      .catch((err) => {
        console.log(err);
        setDescription(false);
      });
  }
  function clearValue() {
    setModel("");
    setVendorDetail({});
  }
  const modelChange = (e, data) => {
    setModel(data.value);
    setError((draft) => {
      draft.model = data.value === "" ? true : false;
    });
  };
  const handleConfirm = () => {
    history.push({
      pathname: "/createClamis",
      state: {
        directShipDetails: {
          vendorName: vendorDetail.vendorName,
          vendorId: vendorDetail.vendorNumber,
          model: model,
          description: description,
        },
      },
    });
  };
  return (
    <>
      {isLoading && (
        <div className="commonLoader">
          <Loader active />
        </div>
      )}
      <div className="directShipSearchWrapper">
        <h3>Model Search</h3>
        <Form>
          <FormGroup widths="equal">
            <FormField>
              <label className="selectVendor" onClick={handleSelectVendor}>
                Select Vendor
              </label>
              <FormInput
                placeholder=""
                value={vendorDetail?.vendorName}
                required
                disabled
                className="vendorBox"
                error={error?.vendor ? true : false}
              />
            </FormField>
            <FormField>
              <FormInput
                placeholder=""
                label="Model"
                value={model}
                required
                onChange={modelChange}
                error={error?.model ? true : false}
              />
            </FormField>
            {/*<FormField width={6}>
                    <FormInput placeholder='' label='Short Description' value={''} required />
    </FormField>*/}
          </FormGroup>
        </Form>
        <div className="submitClaimBtn">
          <button className="ui button" onClick={clearValue}>
            Clear
          </button>
          <button className="ui button redBtn" onClick={searchModel}>
            Search
          </button>
        </div>
        {description && (
          <div className="directShipDesc">
            <p>
              <strong>Description: </strong>
              {description}
            </p>
            <button className="ui button redBtn" onClick={handleConfirm}>
              Confirm
            </button>
          </div>
        )}

        <div ref={divRef} className="hiddenDivRef"></div>
      </div>
      {showPopup && (
        <Modal className="venderSearchPopup" open={showPopup}>
          <ModalContent>
            <div className="closeSearchPoup">
              <span onClick={() => setShowPopup(false)}>
                <i className="fas fa-times"></i>
              </span>
            </div>
            <div>
              <h3>Vendor Search</h3>
              <Form>
                <FormSelect
                  options={alphabetOpt}
                  label="Choose a letter to search:"
                  width={16}
                  onChange={handleOnchange}
                />
                <div style={{ textAlign: "center" }}>
                  <span>OR</span>
                </div>
                <FormGroup widths="equal">
                  <FormInput
                    fluid
                    label="Search value"
                    placeholder="Enter Word to Search"
                    onChange={(e, data) => {
                      setWord(data.value);
                    }}
                  />
                  <div className="submitClaimBtn">
                    <button className="ui button redBtn" onClick={searchWord}>
                      Search
                    </button>
                  </div>
                </FormGroup>
              </Form>
            </div>
            {popUpLoader && <Loader active />}
            {data.length > 0 && (
              <div className="infiniteScrollTable">
                <WindowScroller>
                  {() => (
                    <Table
                      width={innerWidth}
                      height={400}
                      headerHeight={30}
                      rowHeight={20}
                      rowCount={data.length}
                      rowGetter={({ index }) => data[index]}
                    >
                      <Column
                        width={250}
                        label="VendorName"
                        dataKey="vendorName"
                        cellRenderer={({ cellData }) => (
                          <span
                            className="viewDetailsLink"
                            onClick={() => updateVendor(cellData)}
                          >
                            {cellData}
                          </span>
                        )}
                      />
                    </Table>
                  )}
                </WindowScroller>
              </div>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default memo(DirectShipSearch);
