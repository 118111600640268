import React, { memo } from "react";
import { TabPane, Tab } from "semantic-ui-react";
import BrowseCategory from "./BrowseCategory";
import "./style.scss";
import QuickSearch from "./QuickSearch";

const SearchItem = () => {
  const panes = [
    {
      menuItem: "Quick Search",
      render: () => (
        <TabPane>
          <QuickSearch />
        </TabPane>
      ),
    },
    {
      menuItem: "Browse Category",
      render: () => (
        <TabPane>
          <BrowseCategory />
        </TabPane>
      ),
    },
  ];
  return (
    <>
      <div className="itemSearchWrapper">
        <h3>Search Item</h3>
        <Tab panes={panes} />
      </div>
    </>
  );
};

export default memo(SearchItem);
