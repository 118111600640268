
import axios from "axios";
import history from "../history";
import { error_500_text } from "../components/NavBar/menuConstants";
//var baseConfig={"headers":{"Access-Control-Allow-Origin": "*", "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS", "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"}}
export const restUtils = {
  getData: (url) => {
    let oauthConfig = {
      headers: { accesstoken: localStorage.getItem("access_token") },
    };
    let config = { ...oauthConfig, method: "get", url: url };
    return restUtils.processRequest(config);
  },

  getDataWithBearer: (url) => {
    let oauthConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    let config = { ...oauthConfig, method: "get", url: url };
    return restUtils.processRequest(config);
  },

  postData: (url, data) => {
    let oauthConfig = {
      headers: { accesstoken: localStorage.getItem("access_token") },
    };
    // const proxy = require('http-proxy-middleware');
    let config = { ...oauthConfig, method: "post", url: url, data: data };
    return restUtils.processRequest(config);
  },

  postDataWithBearer: (url, data) => {
    let oauthConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    // const proxy = require('http-proxy-middleware');
    let config = { ...oauthConfig, method: "post", url: url, data: data };
    return restUtils.processRequest(config);
  },

  putData: (url, data) => {
    let oauthConfig = {
      headers: { accesstoken: localStorage.getItem("access_token") },
    };
    let config = { ...oauthConfig, method: "put", url: url, data: data };
    return restUtils.processRequest(config);
  },

  deleteData: (url, data) => {
    let oauthConfig = {
      headers: { accesstoken: localStorage.getItem("access_token") },
    };
    let config = { ...oauthConfig, method: "delete", url: url };
    if (data) {
      config.data = data;
    }
    return restUtils.processRequest(config);
  },

  getDataWithoutToken: (url) => {
    let config = { method: "get", url: url };
    return restUtils.processRequest(config);
  },
  getDataParamWithoutToken: (url, data) => {
    let config = { method: "get", url: url, data: data };
    return restUtils.processRequest(config);
  },
  postDataWithoutToken: (url, data) => {
    let config = { method: "post", url: url, data: data };
    return restUtils.processRequest(config);
  },

  putDataWithoutToken: (url, data) => {
    let config = { method: "put", url: url, data: data };
    return restUtils.processRequest(config);
  },

  deleteDataWithoutToken: (url, data) => {
    let config = { method: "delete", url: url };
    if (data) {
      config.data = data;
    }
    return restUtils.processRequest(config);
  },

  /**
   * config object can have method, url and data and so on. Please refer axios doc for more options
   */
  processRequest: (config) => {
    return new Promise((resolve, reject) => {
      axios(config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 500) {
            history.push({
              pathname: "/serverError",
              state: {
                message: error?.response?.message
                  ? error?.response?.message
                  : error_500_text,
              },
            });
          }
          reject(error);
        });
    });
  },
};

export default restUtils;
