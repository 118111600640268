import React, { memo, useState, useEffect } from "react";
import _ from "lodash";
import "./styles.scss";
import {
  WindowScroller,
  Table,
  Column,
  createTableMultiSort,
  SortIndicator,
} from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once

export const InfiniteScrollTable = (props) => {
  const { product } = props;
  const [data, setData] = useState([]);
  const [direction, setDirection] = useState("ASC");
  const [sortCol, setSortCol] = useState(["item_Nbr"]);

  useEffect(() => {
    let itemNumberSort = _.orderBy(product, ["item_Nbr"], ["asc"]);
    setData(itemNumberSort);
  }, [product]);
  function sort({ sortBy, sortDirection }) {
    const key = Object.keys(sortDirection);
    const direction = Object.values(sortDirection);
    let sortData = _.orderBy(product, key, direction[0].toLowerCase());
    setData(sortData);
    setSortCol(sortBy);
    let des = ["DESC"];
    let dir =
      direction === des && Object.values(sortDirection)[0] === "DESC"
        ? "ASC"
        : Object.values(sortDirection)[0];
    console.log(direction === des);
    setDirection(dir);
    // 'sortBy' is an ordered Array of fields.
    // 'sortDirection' is a map of field name to "ASC" or "DESC" directions.
    // Sort your collection however you'd like.
    // When you're done, setState() or update your Flux store, etc.
  }
  const sortState = createTableMultiSort(sort, {
    defaultSortBy: sortCol,
    defaultSortDirection: {
      item_Nbr: direction,
    },
  });
  const headerRenderer = ({ dataKey, label }) => {
    const showSortIndicator = sortState.sortBy.includes(dataKey);
    return (
      <>
        <span title={label}>{label}</span>
        {showSortIndicator && (
          <SortIndicator sortDirection={sortState.sortDirection[dataKey]} />
        )}
      </>
    );
  };
  return (
    data.length > 0 && (
      <div className="infiniteScrollTable">
        {data && (
          <WindowScroller>
            {() => (
              <Table
                gridStyle={{ outline: "none" }}
                width={props.width}
                height={800}
                headerHeight={30}
                rowHeight={20}
                rowCount={data.length}
                sort={sortState.sort}
                sortBy={undefined}
                sortDirection={undefined}
                rowGetter={({ index }) => data[index]}
              >
                <Column
                  width={250}
                  headerRenderer={headerRenderer}
                  label="Number"
                  dataKey="item_Nbr"
                  cellRenderer={({ cellData }) => (
                    <span
                      className="viewDetailsLink"
                      onClick={() => props.viewItem(cellData)}
                    >
                      {cellData}
                    </span>
                  )}
                />
                <Column
                  width={250}
                  headerRenderer={headerRenderer}
                  label="VendorName"
                  dataKey="vendor_Name"
                />
                <Column
                  width={250}
                  headerRenderer={headerRenderer}
                  label="Model #"
                  dataKey="model_Nbr"
                />
                <Column
                  width={250}
                  headerRenderer={headerRenderer}
                  label="Short Description"
                  dataKey="short_Desc"
                />
              </Table>
            )}
          </WindowScroller>
        )}
      </div>
    )
  );
};

export default memo(InfiniteScrollTable);
