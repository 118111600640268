export const mainMenu = [
  {
    key: "home",
    title: "HOME",
    url: "/home",
  },
  {
    key: "newClaim",
    title: "New Claim",
    url: "/newClaim",
  },
  {
    key: "PolicyB",
    title: "POLICY B Form",
    url: "/",
  },
  {
    key: "loadFeedback",
    title: "Load Feedback Form",
    url: "/loadFeedback",
  },
];

export const userMenu = [
  {
    key: "storeInfo",
    text: "STORE INFO & ACCESS",
    value: "storeInfo",
  },
  {
    key: "NeedForHelp",
    text: "Need for Help ?",
    value: "NeedForHelp",
  },
  {
    key: "retrunToMol",
    text: "Retrun to Mol",
    value: "retrunToMol",
  },
];

export const error_500_text =
  "An error occurred while processing your request.";
