import React from "react";
const Footer = () => {
  return (
    <div className="footerWrapper">
      <p>
        This password protected site is for True Value Company Members only |
        @2024 True Value Company
      </p>
    </div>
  );
};

export default Footer;
