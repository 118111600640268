import React, { memo } from "react";
import { Modal, ModalContent } from "semantic-ui-react";
const InfoMessage = (props) => {
  return (
    <Modal open={true} className="infoMsgPoup">
      <ModalContent>
        <h4>{props.des}</h4>
        <div>
          <button className="ui button" onClick={props.noValidQty}>
            {props?.hideYes ? "Close" : "No"}
          </button>
          {!props?.hideYes && (
            <button className="ui button redBtn" onClick={props.yesValidQty}>
              Yes
            </button>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default memo(InfoMessage);
