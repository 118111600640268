import React from "react";
import "./404_error_page.css";
import { Link } from "react-router-dom";
import oops from "../images/208_oops-face-emoji-emoticon-sad-512.png";
import renderImages from "../common/RenderImages";
import { Icon } from "semantic-ui-react";
const InternalServerError = (props) => {
  return (
    <div className="text_center">
      <span className="oops-img">
        {renderImages.getImage({ src: oops, className: "oops-img" })}ops!
      </span>
      <h2>
        {props?.location?.state?.message
          ? props?.location?.state?.message
          : "Sorry, we couldn’t retrieve the data!"}
      </h2>
      <h3>
        Go back to{" "}
        <Link to="/home">
          <Icon name="home" />
        </Link>
      </h3>
    </div>
  );
};

export default InternalServerError;
