import React, { memo } from "react";
import HeroBar from "./HeroBar";
import "./header.scss";
import NavBar from "./NavBar";

const Header = (props) => {
  return (
    <div className="contentContainer">
      <div className="pageContainer">
        <HeroBar />
        <NavBar />
        <div className="gridContainer containerWrapper">{props.content}</div>
      </div>
    </div>
  );
};

export default memo(Header);
