import React from "react";
import Flyout from "./Flyout";

const NavBar = (props) => {
  return (
    <React.Fragment>
      <Flyout />
      {props.content}
    </React.Fragment>
  );
};

export default NavBar;
