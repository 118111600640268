import React, { useState, useEffect, memo } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";

const Scan = (props) => {
  const reader = new BrowserMultiFormatReader();
  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  useEffect(() => {
    getCode();
    startScanning();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCode = () => {
    reader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        setVideoInputDevices(videoInputDevices);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const startScanning = () => {
    reader && reader.reset();
    reader
      .decodeOnceFromVideoDevice(selectedDevice, "video")
      .then((result) => {
        props.handleResult(result.text);
      })
      .catch((e) => console.log(e));
  };
  const selectVideo = (e) => {
    setSelectedDevice(e.target.value);
  };
  useEffect(() => {
    startScanning();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);
  useEffect(() => {
    return () => {
      reader && reader.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <video id="video"></video>
      {videoInputDevices && videoInputDevices.length > 0 && (
        <div id="sourceSelectPanel" style={{ marginTop: "10px" }}>
          <select id="sourceSelect" onChange={selectVideo}>
            <option value="" disabled selected>
              Switch Camera
            </option>
            {videoInputDevices.map((ele) => (
              <option
                key={ele.label}
                selected={selectedDevice === ele.deviceId}
                value={ele.deviceId}
              >
                {ele.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default memo(Scan);
