import React, { useState, useEffect, memo } from "react";
import CamerCapture from "./CameraCapture";
import "./style.scss";
import { FormGroup, FormField } from "semantic-ui-react";
import { MobileView } from "react-device-detect";
import UploadDevice from "./uploadDevice";
import useStableCallback from "../common/CustomHook/useStableCallback";
import { v4 as uuidv4 } from "uuid";

export const UploadImage = (props) => {
  const {
    handleUploadImg,
    maxImage,
    clearImages,
    label,
    mandatory = false,
    isEdit = false,
    uploadImages,
    isErrorImg = false,
    enableCheckBox = false,
  } = props;
  const [images, setImages] = useState(null);

  const updateImages = useStableCallback(
    (img) => {
      setImages((prevImages) => (prevImages ? [...prevImages, img] : [img]));
    },
    [images],
  );
  useEffect(() => {
    if (isEdit) {
      setImages(uploadImages);
    }
  }, [isEdit, uploadImages]);
  useEffect(() => {
    if (images) {
      handleUploadImg(images);
    }
  }, [images, handleUploadImg]);
  useEffect(() => {
    if (clearImages) {
      setImages([]);
    }
  }, [clearImages]);
  const removeImage = (index, img) => {
    let removeImage = [...images];
    removeImage.splice(index, 1);
    console.log(removeImage);
    setImages(removeImage);
    if (isEdit) {
      props.removeEditImage(img.imageName);
    }
  };
  const handleImageStatus = (e) => {
    const { checked, dataset, value } = e.target;
    const img = images.map((ele, i) => {
      if (i === parseInt(dataset.index)) {
        let imageType = checked
          ? [...ele["imageType"], ...value]
          : ele["imageType"].filter((ele) => ele !== value);
        return {
          ...ele,
          imageType: imageType,
        };
      }
      return ele;
    });
    setImages(img);
  };
  return (
    <>
      <MobileView>
        <FormGroup
          className="uploadImageForm"
          id={isErrorImg ? "errorFieldImg" : ""}
        >
          <label style={{ textTransform: "initial" }}>
            Take a picture{" "}
            {mandatory ? <span className="mandatoryField">*</span> : ""}
          </label>
          <CamerCapture
            handleUploadImg={updateImages}
            disableBtn={images?.length >= maxImage ? true : false}
            enableCheckBox={enableCheckBox}
          />
        </FormGroup>
      </MobileView>
      <FormGroup
        className="uploadImageForm"
        id={isErrorImg ? "errorFieldImg" : ""}
      >
        <label>
          {label}
          {mandatory ? <span className="mandatoryField">*</span> : ""}
          <p className="attachmentImgMsg">
            Attachments must be of image format only and Max size allowed is 5
            MB for each image
          </p>
        </label>
        <UploadDevice
          handleUploadImg={updateImages}
          disableBtn={images?.length >= maxImage ? true : false}
          enableCheckBox={enableCheckBox}
        />
      </FormGroup>
      {images?.length > 0 && (
        <FormGroup>
          <div className="uploadImages">
            <div className="previewImg">
              <div className="imgDiv">
                {images.map((img, index) => {
                  return (
                    <div className="uploadImageWrapper" key={uuidv4()}>
                      <span>
                        <img
                          src={img.imageContent.split("&hash")[0]}
                          alt={"Images"}
                        />
                        <i
                          onClick={() => removeImage(index, img)}
                          className="fas fa-times-circle"
                        ></i>
                      </span>
                      {enableCheckBox && (
                        <div>
                          <label>Please Select appropriate option</label>
                          <FormGroup>
                            <FormField
                              label="Crushed"
                              control="input"
                              type="checkbox"
                              checked={
                                img.imageType.includes("1") ? true : false
                              }
                              value={1}
                              data-index={index}
                              onChange={handleImageStatus}
                            />
                            <FormField
                              label="Bent"
                              control="input"
                              type="checkbox"
                              checked={
                                img.imageType.includes("2") ? true : false
                              }
                              data-index={index}
                              value={2}
                              onChange={handleImageStatus}
                            />
                            <FormField
                              label="Expired"
                              control="input"
                              type="checkbox"
                              checked={
                                img.imageType.includes("3") ? true : false
                              }
                              data-index={index}
                              value={3}
                              onChange={handleImageStatus}
                            />
                            <FormField
                              label="Dent"
                              control="input"
                              type="checkbox"
                              checked={
                                img.imageType.includes("4") ? true : false
                              }
                              data-index={index}
                              value={4}
                              onChange={handleImageStatus}
                            />
                          </FormGroup>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </FormGroup>
      )}
    </>
  );
};

export default memo(UploadImage);
