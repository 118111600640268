import React, { useState, useEffect, useRef } from "react";
import {
  Loader,
  Form,
  FormInput,
  FormGroup,
  Radio,
  FormField,
  FormSelect,
  Modal,
  ModalContent,
} from "semantic-ui-react";
import { shipmentType } from "./optionConstant";
import _ from "lodash";
import "./style.scss";
import UploadImage from "../UploadImage";
import { useImmer } from "use-immer";
import { quantityValidator, uploadImagesToGcp } from "../common/productHandler";
import history from "../../history";
import useStableCallback from "../common/CustomHook/useStableCallback";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {
  CLAIMS_BASE_API,
  GET_STOREINFO,
  PRODUCT_DETAILS,
} from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import { useSelector, useDispatch } from "react-redux";
import utilities from "../../utils/utilities";
import WindowedSelect from "react-windowed-select";
import { Link } from "react-router-dom";
import InfoMessage from "./InfoMessage";
import docLink from "../../assets/PolicyAWarrantyProgram.docx";
import { updateRdc } from "../Login/actions";
import moment from 'moment-timezone';
import { v4 as uuid } from "uuid";
const CreateClaims = (props) => {
  let itemNumber = props?.location?.state?.itemNumber
    ? props?.location?.state?.itemNumber
    : props?.itemNumber
      ? props?.itemNumber
      : null;
  const productDetails = props?.location?.state?.product
    ? props?.location?.state?.product
    : props?.product
      ? props?.product
      : null;
  const directShipDetails = props?.location?.state?.directShipDetails
    ? props?.location?.state?.directShipDetails
    : props?.directShipDetails
      ? props?.directShipDetails
      : null;
  const isEditForm = props?.location?.state?.isEditForm
    ? props?.location?.state?.isEditForm
    : props?.itemNumber
      ? props?.isEditForm
      : null;
  const editData = props?.location?.state?.data
    ? props?.location?.state?.data
    : props?.itemNumber
      ? props?.data
      : {};
  const [claimNumber, setClaimNumber] = useState("");
  const [reasonOpt, setReasonOpt] = useState([]);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [isWaterHeater, setIsWaterHeater] = useState(false);
  const { storeId, userName, rdc, lastName, firstName } = useSelector(
    (state) => state.SessionReducer,
  );
  const [serialNoOpt, setSerialNoOpt] = useState([]);
  const [serialNoValues, setSerialNoValues] = useState([]);
  const [tempSerialVal, setTempSerialVal] = useState([]);
  const [msgPopup, setMsgPoup] = useState(false);
  const [isCommentMandatory, setIsCommentMandatory] = useState(false);
  const [qtyInfoPopup, setInfoPopup] = useState(false);
  let date = new Date();
  date.setDate(date.getDay() - 90);
  const [createClaim, setCreateClaim] = useImmer({
    claimType: "",
    subReason: "",
    shipmentType: "",
    qty: "",
    qtyMsg: false,
    poNum: "",
    image: null,
    claimTypeLabel: "",
    reasonId: "",
    subResonLabel: "",
    imageStatus: [],
    comment: "",
    invoice: "",
    packQty: "",
    itemDescription: "",
    subReasonId: "",
    reasonType: null,
  });
  const [qtyMsgData, setQtyMsgData] = useImmer({
    packText: "",
    allocQty: "",
    packQty: "",
    errCode: "",
  });
  const [stockedValues, setStockedValues] = useImmer({
    itemNumber: productDetails?.Item_Number
      ? productDetails?.Item_Number
      : isEditForm && editData?.itemNumberRlyBk,
    shortDesc: productDetails?.shortdesc
      ? productDetails?.shortdesc
      : isEditForm && editData?.shortdesc,
    qty: "",
    unitCost: "",
    showPopup: false,
    validQty: false,
    showMaxReachPopup: false,
  });
  const [stockedError, setStockedError] = useImmer({
    itemNumber: false,
    shortDesc: false,
    qty: false,
  });
  const [directShipValues, setDirectShipValues] = useImmer({
    desc: directShipDetails?.description,
    unitCost: "",
    infoPopup: false,
    unitCostInfoPoup: false,
    showMaxReachPopup: false,
    qty: "",
    invoice: "",
  });
  const [directShipError, setDirectShipError] = useImmer({
    desc: false,
    unitCost: false,
    qty: false,
  });
  const [clearImages, setClearImages] = useState(false);
  const [claimTypesOpt, setClaimTypesOpt] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [editedImages, setEditedImages] = useState([]);
  const [defultInvoiceVal, setDefaultInvoiceVal] = useState({});
  const [errorFields, setErrorFields] = useImmer({
    claimType: false,
    subReason: false,
    shipmentType: false,
    qty: false,
    qtyMsg: false,
    poNum: false,
    image: false,
    imageStatus: [],
    comment: false,
  });
  const [searchFormVal, setSearchFormVal] = useImmer({
    searchBy: "days",
    fromDate: "",
    toDate: "",
    errorFromDate: false,
    errorToDate: false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (_.isEmpty(rdc)) {
      const payLoad = {
        storeNumber: storeId,
      };
      restUtils
        .postDataWithBearer(GET_STOREINFO, payLoad)
        .then((res) => {
          dispatch(updateRdc(res.data.ManinRDCNumber));
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, rdc]);
  useEffect(() => {
    if (!_.isEmpty(productDetails)) {
      restUtils
        .getDataWithBearer(
          `${CLAIMS_BASE_API}/${storeId}/GetPolicyAStockItemShortDesc?itemNbr=${productDetails.Item_Number}`,
        )
        .then((res) => {
          if (_.isEmpty(res.data)) {
            setIsWaterHeater(false);
          } else {
            const value = res.data.split("|");
            if (value[1] === "F" && value[2] === "5" && value[3] === "31344") {
              setIsWaterHeater(true);
              restUtils
                .getDataWithBearer(
                  `${CLAIMS_BASE_API}/${storeId}/GetClaimReturnSubReasons?returnReasonId=${0}`,
                )
                .then((res) => {
                  const opt = res.data.map((ele) => {
                    return {
                      key: ele.reason_Id,
                      text: ele.reasonFor_Display,
                      value: `${ele.reason_Id}_${ele.reason_Type}`,
                      title: ele?.reason_Description
                        ? ele?.reason_Description
                        : "",
                    };
                  });
                  setSerialNoOpt(opt);
                })
                .catch((err) => {
                  console.log(err);
                  utilities.showToast("error", true);
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          utilities.showToast("error", true);
        });
    }
  }, [productDetails, storeId]);
  useEffect(() => {
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/GetPolicyAClaimNumberOnLoad`,
      )
      .then((res) => {
        const val = _.isEmpty(res?.data) ? "" : res.data;
        setClaimNumber(val);
        console.log(res?.data);
      })
      .catch((err) => {
        console.log(err);
        utilities.showToast("error", true);
      });
  }, [productDetails, directShipDetails, storeId]);
  const handleSearchBy = (e, data) => {
    if (data.name !== "searchBy") {
      if (data.name === "fromDate") {
        if (data.value === "" || !data.value) {
          setSearchFormVal((draft) => {
            draft.errorFromDate = true;
          });
        } else {
          setSearchFormVal((draft) => {
            draft.errorFromDate = false;
          });
        }
      } else if (data.name === "toDate") {
        if (data.value === "" || !data.value) {
          setSearchFormVal((draft) => {
            draft.errorToDate = true;
          });
        } else {
          setSearchFormVal((draft) => {
            draft.errorToDate = false;
          });
        }
      }
    }
    setSearchFormVal((draft) => {
      draft[data.name] = data.value;
    });
  };
  useEffect(() => {
    if (isEditForm) {
      const {
        invoiceNumberRDC,
        qty,
        poNumber,
        shipmentType,
        comments,
        claimType,
        reason,
        subReason,
        subReasonId,
        reasonId,
        pack,
      } = editData;
      setCreateClaim((draft) => {
        draft.invoice = { label: invoiceNumberRDC, value: invoiceNumberRDC };
        draft.qty = qty;
        draft.poNum = poNumber;
        draft.shipmentType = shipmentType;
        draft.comment = comments;
        draft.claimType = reasonId;
        draft.claimTypeLabel = reason;
        draft.subResonLabel = subReason;
        draft.subReason = `${subReasonId}_${claimType}`;
        draft.reasonId = reasonId;
        draft.packQty = !_.isEmpty(pack) ? parseInt(pack) : 0;
      });
      populateInvoice();
      setInvoiceOpt([{ label: invoiceNumberRDC, value: invoiceNumberRDC }]);
      setReasonOpt([
        {
          key: subReasonId,
          text: subReason,
          value: `${subReasonId}_${claimType}`,
        },
      ]);
      setDefaultInvoiceVal([
        { label: invoiceNumberRDC, value: invoiceNumberRDC },
      ]);
      const images =
        editData?.imageDetails &&
        editData?.imageDetails.map((ele) => {
          return {
            imageName: ele.imageObject_Name,
            imageContent: ele.imageObject_URL,
            imageType: ele.image_Damage_Type.split(","),
          };
        });
      setUploadImages(images);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditForm, setCreateClaim, editData]);
  function populateInvoice() {
    let url = `${CLAIMS_BASE_API}/${storeId}/GetInvoiceNumbers?itemNumber=${editData.subReasonId === "22" || editData.subReasonId === "23" ? "" : editData.itemNumberRlyBk}&invoiceType=${editData.claimType}`;
    restUtils
      .getDataWithBearer(url)
      .then((res) => {
        const opt = res.data.map((opt) => {
          return { label: opt, value: opt };
        });
        setInvoiceOpt(opt);
      })
      .catch((err) => {
        console.log(err);
        utilities.showToast("error", true);
      });
  }
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceOpt, setInvoiceOpt] = useState([]);
  let selectRef = useRef(null);
  useEffect(() => {
    if (invoiceOpt?.length > 0) {
      setShowSearchFilter(true);
    } else {
      setShowSearchFilter(false);
    }
  }, [invoiceOpt]);

  useEffect(() => {
    setIsLoading(true);
    restUtils
      .getDataWithBearer(`${CLAIMS_BASE_API}/${storeId}/GetClaimReturnReasons`)
      .then((res) => {
        const opt = res.data.map((ele) => {
          return {
            key: ele.returnReason_Id,
            text: ele.returnReason,
            value: ele.returnReason_Id,
          };
        });
        setClaimTypesOpt(opt);
        setIsLoading(false);
        if (isEditForm) {
          restUtils
            .getDataWithBearer(
              `${CLAIMS_BASE_API}/${storeId}/GetClaimReturnSubReasons?returnReasonId=${editData.reasonId}`,
            )
            .then((res) => {
              const opt = res.data.map((ele) => {
                return {
                  key: ele.reason_Id,
                  text: ele.reasonFor_Display,
                  value: `${ele.reason_Id}_${ele.claim_Type}`,
                  title: ele?.reason_Description ? ele?.reason_Description : "",
                };
              });
              const  filterDatOpt =  opt.filter(ele => ele.text !=="Adjusted pricing Error");
	            setReasonOpt(filterDatOpt);
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
              console.log(err);
              utilities.showToast("error", true);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        utilities.showToast("error", true);
        setIsLoading(false);
      });
  }, [storeId, isEditForm, editData.reasonId]);
  const chooseType = (e, data) => {
    setIsLoading(true);
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/GetClaimReturnSubReasons?returnReasonId=${data.value}`,
      )
      .then((res) => {
        let options = [];
        if (directShipDetails) {
          options = res.data.filter((ele) => ele.reason_Type === "D");
        } else {
          options = res.data.filter((ele) => ele.reason_Type !== "D");
        }
        let opt = options.map((ele) => {
          return {
            key: ele.reason_Id,
            text: ele.reasonFor_Display,
            value: `${ele.reason_Id}_${ele.claim_Type}_${ele.reason_Type}`,
            title: ele?.reason_Description ? ele?.reason_Description : "",
          };
        });
        const  filterDatOpt1 = opt.filter(ele => ele.text !=="Adjusted pricing Error");
	      setReasonOpt(filterDatOpt1);
        setErrorFields((draft) => {
          draft.claimType = data.value === "" ? true : false;
        });
        if (selectRef.current) {
          selectRef.current.clearValue();
        }
        setInvoiceOpt([]);
        setDefaultInvoiceVal([]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        utilities.showToast("error", true);
      });
    setCreateClaim((draft) => {
      draft.claimType = data.value;
      draft.subReason = "";
      draft.subResonLabel = "Sub Reason";
      draft.invoice = "";
      draft.comment = "";
    });
  };
  const chooseSubReason = (e, data, fromSearch) => {
    const { searchBy, fromDate, toDate } = searchFormVal;
    const invoiceType = data.value.split("_")[1];
    let itemNbr = isEditForm
      ? editData?.itemNumberRlyBk
      : _.isEmpty(itemNumber)
        ? 0
        : itemNumber;
    const value = data.value.split("_")[0];
    if (value === "22" || value === "23") {
      itemNbr = "";
    }
    setCreateClaim((draft) => {
      draft.reasonType = data.value.split("_")[2];
      draft.subReasonId = data.value.split("_")[0];
    });
    let url = `${CLAIMS_BASE_API}/${storeId}/GetInvoiceNumbers?itemNumber=${itemNbr}&invoiceType=${invoiceType}`;
    let isError = false;
    if (fromSearch) {
      if (searchBy === "invoiceDate") {
        if (fromDate === "" || !fromDate) {
          setSearchFormVal((draft) => {
            draft.errorFromDate = true;
          });
        }
        if (toDate === "" || !toDate) {
          setSearchFormVal((draft) => {
            draft.errorToDate = true;
          });
        }
        if (!fromDate || fromDate === "" || !toDate || toDate === "") {
          utilities.showToast("Please enter valid From and To date", true);
          isError = true;
          return;
        }
        url =
          url +
          `&fromDate=${utilities.getDateFromTimeStampShip(
            fromDate,
          )}&toDate=${utilities.getDateFromTimeStampShip(toDate)}`;
      }
    }
    if (isError) {
      return;
    }
    if (!directShipDetails) {
      setIsLoading(true);
      restUtils
        .getDataWithBearer(url)
        .then((res) => {
          if (_.isEmpty(res.data)) {
            utilities.showToast("No Invoice Data", true);
            setErrorFields((draft) => {
              draft.subReason = data.value === "" ? true : false;
            });
            if (selectRef.current) {
              selectRef.current.clearValue();
            }
            setInvoiceOpt([]);
            setIsLoading(false);
            return;
          }
          const opt = res.data.map((opt) => {
            return { label: opt, value: opt };
          });
          setCreateClaim((draft) => {
            draft.invoice = "";
          });
          if (selectRef.current) {
            selectRef.current.clearValue();
          }
          setInvoiceOpt(opt);
          setIsLoading(false);
          setErrorFields((draft) => {
            draft.subReason = data.value === "" ? true : false;
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setErrorFields((draft) => {
            draft.subReason = data.value === "" ? true : false;
          });
          setCreateClaim((draft) => {
            draft.invoice = "";
          });
          setInvoiceOpt([]);
        });
    }
    if (
      isEditForm &&
      invoiceType === "PolicyA" &&
      _.isEmpty(productDetails) &&
      _.isEmpty(stockedValues?.shortDesc)
    ) {
      const timestamppdp = Date.now();
      const url =
        PRODUCT_DETAILS +
        stockedValues.itemNumber +
        "&storeId=" +
        storeId +
        "&rdc=" +
        rdc +
        "&grpCollectionId=" +
        "0" +
        "&cartTimestamp=" +
        timestamppdp +
        "&aliasSKURetailerGrpID=" +
        "";
      setIsLoading(true);
      restUtils
        .getData(url)
        .then((response) => {
          console.log(response);
          setStockedValues((draft) => {
            draft.shortDesc = response.data.shortdesc;
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          utilities.showToast("Error", true);
        });
    }
    setCreateClaim((draft) => {
      draft.subReason = data.value;
      draft.comment = "";
    });
    setDefaultInvoiceVal([]);
  };
  const handleUploadImg = useStableCallback(
    (img) => {
      setClearImages(false);
      setCreateClaim((draft) => {
        draft.image = img;
      });
    },
    [createClaim.image],
  );

  const handleSelectOpt = (e, data) => {
    setCreateClaim((draft) => {
      draft[data.name] = data.value;
    });
    setErrorFields((draft) => {
      draft[data.name] = !_.isEmpty(data.value) ? false : true;
    });
  };
  /* useEffect(()=>{
        if(!_.isEmpty(createClaim.subReason)) {
            const subReason = createClaim.subReason.split('_')[0]
            if(subReason === 'PolicyA' && createClaim.shipmentType === 'directShip') {
                //setDirectShipItem(true)
                setDirectShipItem(false)
                return
            }
            setDirectShipItem(false)
        }
        else {
            setDirectShipItem(false)
        }
    },[createClaim.subReason, createClaim.shipmentType])*/
  const removeEditImage = (img) => {
    setEditedImages([...editedImages, img]);
  };
  const qtyHandler = (e) => {
    const { value } = e.target;
    const { packText, allocQty, packQty, errCode } = qtyMsgData;
    if (!quantityValidator(value, false)) {
      return;
    } else {
      handleQtyMsg(packText, allocQty, packQty, value, errCode);
      setCreateClaim((draft) => {
        draft.qty = !_.isEmpty(value) ? parseInt(value) : "";
        // draft.qtyMsg = false
      });
      setErrorFields((draft) => {
        draft.qty = !_.isEmpty(value) ? false : true;
      });
    }
  };
  const handleInvoiceChange = (e, { action }) => {
    if (e?.value) {
      setCreateClaim((draft) => {
        draft.invoice = e;
      });
      setIsLoading(true);
      const subReasonId = createClaim.subReason.split("_")[0];
      if (createClaim.reasonType === "S") {
        setIsLoading(true);
        restUtils
          .getDataWithBearer(
            `${CLAIMS_BASE_API}/${storeId}/GetPolicyAStockItemUnitCost?memberRDC=${rdc}&invoiceNumber=${e?.value}&itemNumber=${stockedValues.itemNumber}`,
          )
          .then((res) => {
            setIsLoading(false);
            if (!_.isEmpty(res.data)) {
              setStockedValues((draft) => {
                draft.unitCost = res.data.split("|")[0];
              });
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
            utilities.showToast("error", true);
          });
        setErrorFields((draft) => {
          draft.invoice = !_.isEmpty(e?.value) ? false : true;
        });
        return;
      }
      restUtils
        .getDataWithBearer(
          `${CLAIMS_BASE_API}/${storeId}/GetItemPackDetails?invoiceNbr=${e.value}&itemNbr=${itemNumber}&reasonId=${subReasonId}`,
        )
        .then((res) => {
          const data = res.data;
          const packText = data.packQty;
          const allocQty = data.allocatedQty;
          const packQty = packText !== null ? packText.substring(1) : "";
          const qty = createClaim.qty;
          const errCode = data.errorCode;
          setQtyMsgData((draft) => {
            draft.packText = data.packQty;
            draft.allocQty = data.allocatedQty;
            draft.packQty = packText !== null ? packText.substring(1) : "";
            draft.errCode = data.errorCode;
          });
          handleQtyMsg(packText, allocQty, packQty, qty, errCode);

          setCreateClaim((draft) => {
            draft.packQty = !_.isEmpty(data.packQty)
              ? parseInt(data.packQty)
              : 0;
            draft.qtyMsg = data.message;
            draft.qty = !_.isEmpty(data.claimQty)
              ? data.claimQty
              : createClaim.qty;
          });
          setErrorFields((draft) => {
            draft.invoice = !_.isEmpty(e?.value) ? false : true;
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          utilities.showToast("error", true);
        });
    }
  };
  const handleQtyMsg = (packText, allocQty, packQty, qty, errCode) => {
    let qtyMsg = "";
    if (errCode === "**" && parseInt(allocQty) > 0) {
      if (packText.indexOf("F") > -1) {
        let rem = parseInt(qty) % parseInt(packQty);
        if (rem !== 0) {
          qtyMsg = "Invalid Qty";
          return;
        }
        if (parseInt(qty) > parseInt(allocQty)) {
          qtyMsg = `QTY CANT BE GT THAN ALLOCATED QTY, ALLOCATED QTY :${allocQty}`;
        }
      } else {
        if (parseInt(qty) > parseInt(allocQty)) {
          qtyMsg = `QTY CANT BE GT THAN ALLOCATED QTY, ALLOCATED QTY :${allocQty}`;
        }
      }
    }
    setCreateClaim((draft) => {
      draft.qtyMsg = qtyMsg;
    });
  };

  const poNumHandler = (e, data) => {
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (!regex.test(data.value)) {
      utilities.showToast("Special Characters Not Allowed", true);
      return;
    }
    if (data.value.split("").length > 14) {
      utilities.showToast("Max 14 Characters Allowed", true);
      return;
    }
    setCreateClaim((draft) => {
      draft[data.name] = data.value;
    });
    setErrorFields((draft) => {
      draft[data.name] = !_.isEmpty(data.value) ? false : true;
    });
  };
  const commentHandler = (e) => {
    const { value } = e.target;

    if (
      createClaim.reasonType === "null" ||
      _.isEmpty(createClaim.reasonType)
    ) {
      const specialChars = /[#]/;
      if (specialChars.test(value)) {
        utilities.showToast("# sign not allowed in comments", true);
        return;
      }
    }
    if (value.split("").length > 50) {
      utilities.showToast("maximum 50 characters allowed", true);
      setErrorFields((draft) => {
        draft.comment = true;
      });
      return;
    }
    setCreateClaim((draft) => {
      draft.comment = value;
    });
    setErrorFields((draft) => {
      draft.comment = isCommentMandatory && _.isEmpty(value) ? true : false;
    });
  };
  const handleClear = () => {
    setCreateClaim((draft) => {
      draft.claimType = "";
      draft.subReason = "";
      draft.shipmentType = "";
      draft.qty = "";
      draft.qtyMsg = false;
      draft.poNum = "";
      draft.image = [];
      draft.imageStatus = [];
      draft.comment = "";
      draft.invoice = "";
      draft.packQty = "";
      draft.itemDescription = "";
      draft.claimTypeLabel = "Claim Reason";
      draft.subResonLabel = "Sub Reason";
      draft.claimType = null;
    });
    setInvoiceOpt(null);
    setReasonOpt([]);
    setClearImages(true);
  };
  const submitClaim = () => {
    const {
      claimType,
      subReason,
      shipmentType,
      qty,
      poNum,
      comment,
      invoice,
      image,
      packQty,
    } = createClaim;
    let errorValiadionFields = [
      "claimType",
      "subReason",
      "shipmentType",
      "qty",
      "invoice",
    ];
    if (isCommentMandatory) {
      errorValiadionFields.push("comment");
    }
    errorValiadionFields.forEach((ele) => {
      if (createClaim[ele] === "" || !createClaim[ele]) {
        setErrorFields((draft) => {
          draft[ele] = true;
        });
      } else {
        setErrorFields((draft) => {
          draft[ele] = false;
        });
      }
    });
    if (qty === "" || qty <= 0) {
      setErrorFields((draft) => {
        draft.qty = true;
      });
      return;
    }
    if (
      claimType === "" ||
      subReason === "" ||
      shipmentType === "" ||
      qty === "" ||
      invoice === ""
    ) {
      utilities.showToast("Please enter all required fields", true);
      return;
    }
    if (isCommentMandatory && comment === "") {
      utilities.showToast("Please enter all required fields1", true);
      return;
    }
    let claimImages = image.map((img) => {
      let imageType = img["imageType"].join(",");
      return {
        ...img,
        imageType,
      };
    });
    setIsLoading(true);
    let requestPayLoad = {
      storeNumber: storeId,
      invoiceNumber: invoice.value,
      itemNumber: itemNumber,
      reasonId: claimType,
      reason: "",
      subReasonId: subReason.split("_")[0],
      pack: packQty === "" ? 0 : packQty,
      qty: qty,
      comments: comment,
      rowId: 0,
      rowGUIDId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      claimNumber: null,
      delFlag: "N",
      poNumber: poNum,
      subReason: "",
      shipmentType: shipmentType,
      claimType: subReason.split("_")[1],
      images: claimImages,
      createdBy: `${firstName} ${lastName} (${userName.substr(1)})`,
    };
    let url = `${CLAIMS_BASE_API}/${storeId}/AddRfaClaimItem`;
    if (isEditForm) {
      const imageObjects = editedImages.map((ele) => {
        return {
          imageObject_Name: ele,
        };
      });
      claimImages = claimImages.map((ele) => {
        return {
          ...ele,
          imageContent: ele.imageContent.includes(
            "https://storage.googleapis.com",
          )
            ? ""
            : ele.imageContent,
        };
      });
      requestPayLoad = {
        claimItemSessionId: editData.claimItemSessionId,
        invoiceNumber: invoice.value,
        itemNumber: itemNumber.toString(),
        reasonId: claimType,
        reason: "",
        pack: packQty.toString(),
        quantity: qty,
        poNumber: poNum,
        comments: comment,
        deleteFlag: "N",
        storeNumber: storeId,
        subReason: "",
        shipmentType: shipmentType,
        claimType: subReason.includes("_")
          ? subReason.split("_")[1]
          : editData.claimType,
        subReasonId: subReason.split("_")[0],
        imageObjects,
        images: claimImages,
        createdBy: `${firstName} ${lastName} (${userName.substr(1)})`,
      };
      url = `${CLAIMS_BASE_API}/${storeId}/UpdatePendingClaimItem`;
    }
    setIsLoading(true);
    restUtils
      .postDataWithBearer(url, requestPayLoad)
      .then((res) => {
        const code = res?.code;
        if (code === "false" && res?.message) {
          utilities.showToast(res?.message, true);
          return;
        }
        let rfaPath = isEditForm ? editData.claimItemSessionId : res.data
        let imageObject = claimImages.map(ele=> {
              return {
                ...ele,
                imageName: ele.imageContent === '' ?  ele.imageName : `${utilities.getFormattedUploadDate()}/${storeId}/CW${rfaPath}/${itemNumber}/${uuid().substring(0, 5)}_${utilities.getFormattedUploadDate_1()}.jpeg` 
            }
        })
        let imageObjectNames = imageObject.filter(ele=> {
          if(ele.imageContent !==''){
            return ele
          }
        })
        if(imageObjectNames.length > 0 ) {
        let url = `${CLAIMS_BASE_API}/${storeId}/GetSignedImageURLsForClaimItemUpload?contentType=image/jpeg`
        if(imageObjectNames.length > 0) {
        uploadImagesToGcp(imageObjectNames,url).then(gcpRes=>{
          if(gcpRes && !_.isEmpty(gcpRes)) {
            Promise.all(gcpRes).then(getRes=>{
              if(getRes[0].status === 200) {
                let requestPayLoad = {};
                requestPayLoad =  imageObjectNames.map((imageItem,index)=>{
                    return {
                      "claim_LoadFeedback_Nbr": `CW${rfaPath}`,
                      "imageObject_Name":imageItem.imageName,
                      "image_Type": imageItem.imageType,
                      "created_By":`${firstName} ${lastName} (${userName.substr(1)})`,
                      "created_Date": moment().tz('America/Chicago').format(),
                      "itemNbr": isEditForm ? editData.itemNumberRlyBk.toString() : itemNumber.toString(),
                      "sessionID": rfaPath.toString()
                    }
                 
                })
                restUtils
                .postDataWithBearer(`${CLAIMS_BASE_API}/${storeId}/AddRFAImageDetailsToDB`,requestPayLoad).then(res=>{
                  if (isEditForm) {
                    utilities.showToast("Update Claim successfully");
                  } else {
                    utilities.showToast("Added Claim successfully");
                  }
                  setIsLoading(false);
                  history.push({
                    pathname: "/claimConfirmation",
                  });
                }).catch(err=>{
                  console.log(err)
                  setIsLoading(false);
                })
              }else {
                //utilities.showToast('Images Not Uploaded to GCP', true)
                setIsLoading(false);
              }
             })
            }
            else {
              utilities.showToast('Images Not Uploaded to GCP', true)
              setIsLoading(false);
            }
      })
                
    }
    }
    else {
      if (isEditForm) {
        utilities.showToast("Update Claim successfully");
      } else {
        utilities.showToast("Added Claim successfully");
      }
      setIsLoading(false);
      history.push({
        pathname: "/claimConfirmation",
      });
    }
      }).catch((err) => {
        console.log(err);
        setIsLoading(false);
        utilities.showToast('error', true)
        
      });

}
  const submitDirectShipClaim = () => {
    const {
      claimType,
      subReason,
      shipmentType,
      subReasonId,
      poNum,
      comment,
      image,
    } = createClaim;
    const { desc, qty, unitCost, invoice } = directShipValues;
    const errorValiadionFields = ["claimType", "subReason", "shipmentType"];

    errorValiadionFields.forEach((ele) => {
      if (createClaim[ele] === "" || !createClaim[ele]) {
        setErrorFields((draft) => {
          draft[ele] = true;
        });
      } else {
        setErrorFields((draft) => {
          draft[ele] = false;
        });
      }
    });
    ["desc", "unitCost"].forEach((ele) => {
      if (directShipValues[ele] === "" || !directShipValues[ele]) {
        setDirectShipError((draft) => {
          draft[ele] = true;
        });
      } else {
        setDirectShipError((draft) => {
          draft[ele] = false;
        });
      }
    });
    if (qty === "" || qty <= 0) {
      setDirectShipError((draft) => {
        draft.qty = true;
      });
      return;
    }
    if (
      claimType === "" ||
      subReason === "" ||
      shipmentType === "" ||
      qty === "" ||
      desc === "" ||
      unitCost === ""
    ) {
      utilities.showToast("Please enter all required fields", true);
      return;
    }
    let claimImages = image.map((img) => {
      let imageType = img["imageType"].join(",");
      return {
        ...img,
        imageType,
      };
    });
    let requestPayLoad = {
      storeNumber: storeId,
      invoiceNumber: invoice,
      itemNumber: 0,
      unitCost: unitCost,
      quantity: qty,
      modelNumber: directShipDetails.model,
      vendorNumber: directShipDetails.vendorId,
      shortDescription: directShipValues.desc,
      comments: comment,
      claimNumber: claimNumber,
      poNumber: poNum,
      returnReasonId: claimType,
      returnSubReasonId: subReasonId,
      shipmentType: shipmentType,
      claimType: subReason.split("_")[1],
      createdBy: `${firstName} ${lastName} (${userName.substr(1)})`,
      images: claimImages,
    };
    setIsLoading(true);
    restUtils
      .postDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/AddPolicyAClaimDirectShipItem`,
        requestPayLoad,
      )
      .then((res) => {
        const code = res?.code;
        setIsLoading(false);
        if (res?.data.item_Already_Exists_Flag) {
          utilities.showToast(
            `The Direct Ship item you have added with Model number - ${directShipDetails.model}, is already added to the claim. Please add a different item`,
            true,
          );
          return;
        }
        if (code === "false" && res?.message) {
          utilities.showToast(res?.message, true);
          return;
        }
        let imageObject = claimImages.map(ele=> {
          return {
            ...ele,
            imageName: ele.imageContent === '' ?  ele.imageName : `${utilities.getFormattedUploadDate()}/${storeId}/${res?.data?.claim_Nbr}/${uuid().substring(0, 5)}_${utilities.getFormattedUploadDate_1()}.jpeg`
        }
    })
    let imageObjectNames = imageObject.filter(ele=> {
      if(ele.imageContent !==''){
        return ele
      }
    })
    if(imageObjectNames.length > 0) {
        let url = `${CLAIMS_BASE_API}/${storeId}/GetSignedImageURLsForClaimItemUpload?contentType=image/jpeg`
        uploadImagesToGcp(imageObjectNames,url).then(gcpRes=>{
          if(gcpRes) {
            Promise.all(gcpRes).then(getRes=>{
              if(getRes[0].status === 200) {
                let requestPayLoad = {};
                requestPayLoad =  imageObjectNames.map((imageItem,index)=>{
                  return {
                    "claim_LoadFeedback_Nbr": res?.data?.claim_Nbr,
                    "imageObject_Name":imageItem.imageName,
                    "image_Type": imageItem.imageType,
                    "created_By":`${firstName} ${lastName} (${userName.substr(1)})`,
                    "created_Date": moment().tz('America/Chicago').format(),
                    "itemNbr": directShipDetails.model,
                    "sessionID": ""
                  }
                })
                restUtils
                .postDataWithBearer(`${CLAIMS_BASE_API}/${storeId}/AddPolicyAImageDetailsToDB`,requestPayLoad).then(res=>{
                  if (isEditForm) {
                    utilities.showToast("Update Claim successfully");
                  } else {
                    utilities.showToast("Added Claim successfully");
                  }
                  setIsLoading(false);
                  history.push({
                    pathname: "/claimConfirmation",
                  });
                }).catch(err=>{
                  console.log(err)
                })
              }else {
                utilities.showToast('Images Not Uploaded to GCP', true)
                setIsLoading(false);
              }
             })
            }
            else {
              utilities.showToast('Images Not Uploaded to GCP', true)
              setIsLoading(false);
            }
       })
      }else {
        utilities.showToast("Added Claim successfully");
        setIsLoading(false);
        history.push({
          pathname: "/claimConfirmation",
        });
      }      
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        utilities.showToast('error', true)
      });
  };
  const submitStockedClaim = () => {
    const {
      claimType,
      subReason,
      shipmentType,
      subReasonId,
      poNum,
      comment,
      invoice,
      image,
    } = createClaim;
    const { qty, unitCost } = stockedValues;
    const errorValiadionFields = ["claimType", "subReason", "shipmentType"];
    errorValiadionFields.forEach((ele) => {
      if (createClaim[ele] === "" || !createClaim[ele]) {
        setErrorFields((draft) => {
          draft[ele] = true;
        });
      } else {
        setErrorFields((draft) => {
          draft[ele] = false;
        });
      }
    });
    if (qty === "" || qty <= 0) {
      setStockedError((draft) => {
        draft.qty = true;
      });
      return;
    }
    if (
      claimType === "" ||
      subReason === "" ||
      shipmentType === "" ||
      qty === ""
    ) {
      utilities.showToast("Please enter all required fields", true);
      return;
    }

    const serialNo = serialNoValues
      .map((ele) => ele.serialNo)
      .some((ele) => ele === "");
    const warrenty = serialNoValues
      .map((ele) => ele.optValue)
      .some((ele) => ele === "");
    if (serialNo || warrenty) {
      setMsgPoup(true);
      return;
    }
    let claimImages = image.map((img) => {
      let imageType = img["imageType"].join(",");
      return {
        ...img,
        imageType,
      };
    });
    let serialNumbers = _.isEmpty(serialNoValues)
      ? ""
      : serialNoValues
          .filter((ele) => ele.serialNo !== "" && ele.serialNo)
          .map((ele) => ele.serialNo)
          .join(",");
    let warrantyReasonId = _.isEmpty(serialNoValues)
      ? ""
      : serialNoValues
          .filter((ele) => !_.isEmpty(ele.optValue) && ele.optValue)
          .map((ele) => ele.optValue.split("_")[0])
          .join(",");
    let requestPayLoad = {
      memberRDC: rdc,
      storeNumber: storeId,
      invoiceNumber: invoice !== "" ? invoice.value : "",
      itemNumber: stockedValues.itemNumber,
      quantity: parseInt(qty),
      unitCost: unitCost === "" ? 0 : unitCost.toString().replace(/,/g, ''),
      comments: comment,
      claimNumber: claimNumber,
      poNumber: poNum,
      serialNumbers,
      warrantyReasonId,
      isWaterheaterItem: isWaterHeater ? "true" : "false",
      returnReasonId: claimType,
      returnSubReasonId: subReasonId,
      shipmentType: shipmentType,
      claimType: subReason.split("_")[1],
      createdBy: `${firstName} ${lastName} (${userName.substr(1)})`,
      images: claimImages,
    };
    setIsLoading(true);
    restUtils
      .postDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/AddPolicyAClaimStockItem?memberRDC=${rdc}`,
        requestPayLoad,
      )
      .then((res) => {
        const code = res?.code;
        setIsLoading(false);
        if (res?.data.invalid_Item_Flag) {
          utilities.showToast(
            `A match for - ${stockedValues.itemNumber} could not be found with True Value Item# as a Policy A Vendor or item. Please try again`,
            true,
          );
          return;
        }
        if (res?.data.item_Already_Exists_Flag) {
          utilities.showToast(
            `The Item number - ${stockedValues.itemNumber} is already added to the claim. Please add a different item`,
            true,
          );
          return;
        }
        if (code === "false" && res?.message) {
          utilities.showToast(res?.message, true);
          return;
        }
        let imageObject = claimImages.map(ele=> {
          return {
            ...ele,
            imageName: ele.imageContent === '' ?  ele.imageName : `${utilities.getFormattedUploadDate()}/${storeId}/${res?.data?.claim_Nbr}/${uuid().substring(0, 5)}_${utilities.getFormattedUploadDate_1()}.jpeg`
        }
        })
        let imageObjectNames = imageObject.filter(ele=> {
          if(ele.imageContent !==''){
            return ele
          }
        })
        if(imageObjectNames.length > 0) {
          let url = `${CLAIMS_BASE_API}/${storeId}/GetSignedImageURLsForClaimItemUpload?contentType=image/jpeg`
          uploadImagesToGcp(imageObjectNames,url).then(gcpRes=>{
            if(gcpRes) {
              Promise.all(gcpRes).then(getRes=>{
                if(getRes[0].status === 200) {
                  let requestPayLoad = {};
                  requestPayLoad =  imageObjectNames.map((imageItem,index)=>{
                    return {
                      "claim_LoadFeedback_Nbr": res?.data?.claim_Nbr,
                      "imageObject_Name":imageItem.imageName,
                      "image_Type": imageItem.imageType,
                      "created_By":`${firstName} ${lastName} (${userName.substr(1)})`,
                      "created_Date": moment().tz('America/Chicago').format(),
                      "itemNbr": stockedValues.itemNumber,
                      "sessionID": ""
                    }
                  })
                  restUtils
                  .postDataWithBearer(`${CLAIMS_BASE_API}/${storeId}/AddPolicyAImageDetailsToDB`,requestPayLoad).then(res=>{
                    if (isEditForm) {
                      utilities.showToast("Update Claim successfully");
                    } else {
                      utilities.showToast("Added Claim successfully");
                    }
                    setIsLoading(false);
                    history.push({
                      pathname: "/claimConfirmation",
                    });
                  }).catch(err=>{
                    console.log(err)
                  })
                }else {
                  utilities.showToast('Images Not Uploaded to GCP', true)
                  setIsLoading(false);
                }
               })
              }
              else {
                utilities.showToast('Images Not Uploaded to GCP', true)
                setIsLoading(false);
              }
         })
        }else {
          utilities.showToast("Added Claim successfully");
          setIsLoading(false);
          history.push({
            pathname: "/claimConfirmation",
          }); 
        }
         
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        utilities.showToast('error', true)
      });
  };
  const searchInvoice = () => {
    chooseSubReason(null, { value: createClaim.subReason }, true);
  };
  const clearSearchInvoice = () => {
    setSearchFormVal((draft) => {
      draft.searchBy = "days";
      draft.fromDate = "";
      draft.toDate = "";
    });
    chooseSubReason(null, { value: createClaim.subReason }, false);
  };
  useEffect(() => {
    if (searchFormVal.searchBy === "invoiceDate") {
      setShowDateFilter(true);
    } else {
      setSearchFormVal((draft) => {
        draft.fromDate = "";
        draft.toDate = "";
      });
      setShowDateFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFormVal.searchBy]);

  const stockedQtyChange = (e, data) => {
    const { value } = e.target;
    if (value !== "") {
      const regularExpression = /^\d+$/;
      if (!regularExpression.test(value)) {
        return false;
      }
      if (data.name !== "unitCost" && value > 999) {
        utilities.showToast(`Quantity range should be between 1 - 999`, true);
        return false;
      }
    }
    if (data.name === "unitCost") {
      setStockedValues((draft) => {
        draft.unitCost = value;
      });
      return;
    }
    setStockedValues((draft) => {
      draft.qty = value;
    });
    setStockedError((draft) => {
      draft.qty = false;
    });
    if (
      data.name !== "unitCost" &&
      (parseInt(value) === 0 || _.isEmpty(value))
    ) {
      setStockedError((draft) => {
        draft.qty = true;
      });
    } else {
      setStockedError((draft) => {
        draft.qty = false;
      });
    }
  };
  const stockedQtyFocusOut = () => {
    if (!_.isEmpty(stockedValues.qty) && parseInt(stockedValues.qty) > 0) {
      setIsLoading(true);
      restUtils
        .getDataWithBearer(
          `${CLAIMS_BASE_API}/${storeId}/ValidatePolicyAStockItemQuantity?quantity=${stockedValues.qty}&itemNumber=${stockedValues.itemNumber}&invoiceNumber=${_.isEmpty(createClaim.invoice.value) ? 0 : createClaim.invoice.value}`,
        )
        .then((res) => {
          if (!res.data.isValidQty) {
            setStockedValues((draft) => {
              draft.qty = "";
            });
            setIsLoading(false);
            utilities.showToast(res.data.result, true);
            return;
          }
          if (parseInt(stockedValues.qty) > 99) {
            setStockedValues((draft) => {
              draft.showMaxReachPopup = true;
            });
            setIsLoading(false);
            return;
          } else if (parseInt(stockedValues.qty) >= 10) {
            setInfoPopup(true);
          } else {
            setStockedValues((draft) => {
              draft.validQty = true;
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          utilities.showToast("error", true);
        });
    }
  };
  const directShipQtyFocusOut = () => {
    if (parseInt(directShipValues.qty) > 99) {
      setDirectShipValues((draft) => {
        draft.showMaxReachPopup = true;
      });
      return;
    } else if (parseInt(directShipValues.qty) >= 10) {
      setDirectShipValues((draft) => {
        draft.infoPopup = true;
      });
      return;
    }
    setDirectShipValues((draft) => {
      draft.showMaxReachPopup = false;
      draft.infoPopup = false;
    });
  };
  const directShipUnitCostFocusOut = () => {
    if (parseInt(directShipValues.unitCost) >= 25) {
      setDirectShipValues((draft) => {
        draft.unitCostInfoPoup = true;
      });
    }
  };
  function yesValidQty() {
    setInfoPopup(false);
    setStockedValues((draft) => {
      draft.validQty = true;
    });
  }
  function noValidQty() {
    setInfoPopup(false);
    setStockedValues((draft) => {
      draft.qty = "";
      draft.validQty = false;
      draft.showMaxReachPopup = false;
      draft.unitCostInfoPoup = false;
    });
    setDirectShipValues((draft) => {
      draft.showMaxReachPopup = false;
      draft.qty = "";
    });
  }
  function noValidDirectShipQty() {
    setDirectShipValues((draft) => {
      draft.infoPopup = false;
      draft.qty = "";
    });
  }
  function noValidDirectShipUnitCost() {
    setDirectShipValues((draft) => {
      draft.unitCostInfoPoup = false;
      draft.unitCost = "";
    });
  }
  function yesValidDirectShipQty() {
    setDirectShipValues((draft) => {
      draft.infoPopup = false;
      draft.unitCostInfoPoup = false;
    });
  }
  useEffect(() => {
    if (stockedValues.validQty && isWaterHeater) {
      if (_.isEmpty(serialNoValues)) {
        let serialValue = _.range(stockedValues.qty);
        serialValue = serialValue.map(() => {
          return {
            serialNo: "",
            optValue: "",
          };
        });
        setSerialNoValues(serialValue);
        setTempSerialVal(serialValue);
      } else {
        if (serialNoValues.length > stockedValues.qty) {
          let serialValue = [...serialNoValues];
          serialValue.splice(parseInt(stockedValues.qty));
          setSerialNoValues(serialValue);
          setTempSerialVal(serialValue);
        } else {
          let serialValue = _.range(
            Math.abs(stockedValues.qty - serialNoValues.length),
          );
          console.log(serialValue);
          serialValue = serialValue.map(() => {
            return {
              serialNo: "",
              optValue: "",
            };
          });
          setSerialNoValues([...serialNoValues, ...serialValue]);
          setTempSerialVal([...serialNoValues, ...serialValue]);
        }
      }
      handleSerialPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockedValues.validQty]);
  const closeSerialPopup = () => {
    setStockedValues((draft) => {
      draft.showPopup = false;
      draft.validQty = false;
    });
    setSerialNoValues(tempSerialVal);
  };
  function handleSerialPopup() {
    if (stockedValues.qty > 0) {
      setStockedValues((draft) => {
        draft.showPopup = true;
      });
    }
  }
  const handleSerialNoValue = (e, data) => {
    let serialValue = [...tempSerialVal];
    if (data.name === "text") {
      serialValue.splice(data.index, 1, {
        serialNo: data.value,
        optValue: tempSerialVal[data.index].optValue,
      });
      setTempSerialVal(serialValue);
    } else {
      serialValue.splice(data.index, 1, {
        serialNo: tempSerialVal[data.index].serialNo,
        optValue: data.value,
      });
      setTempSerialVal(serialValue);
    }
  };

  const handleDirectshipVal = (e, data) => {
    const { value } = e.target;
    if (data.name === "itemDescription") {
      setDirectShipValues((draft) => {
        draft.desc = value;
      });
      return;
    }
    if (value !== "") {
      const regularExpression = /^\d+$/;
      if (!regularExpression.test(value)) {
        return false;
      }
      if (value > 999) {
        utilities.showToast(`Quantity range should be between 1 - 999`, true);
        return false;
      }
    }
    if (value !== "") {
      setDirectShipError((draft) => {
        draft.qty = false;
      });
    }
    setDirectShipValues((draft) => {
      draft.qty = value;
    });
  };
  const directShipInvoice = (e, data) => {
    setDirectShipValues((draft) => {
      draft.invoice = data.value;
    });
  };
  const handleDirectshipUnitCost = (e) => {
    const { value } = e.target;
    const regularExpression = /^\d*\.?\d*$/;
    if (!regularExpression.test(value)) {
      return;
    }
    if (value !== "") {
      setDirectShipError((draft) => {
        draft.unitCost = false;
      });
    }
    setDirectShipValues((draft) => {
      draft.unitCost = value;
    });
  };
  useEffect(() => {
    const val = parseInt(createClaim.subReasonId);
    setIsCommentMandatory(
      val === 41 || val === 22 || val === 23 ? true : false,
    );
    if (val !== 41 && val !== 22 && val !== 23) {
      setErrorFields((draft) => {
        draft.comment = false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createClaim.subReasonId]);
  if (itemNumber || productDetails || directShipDetails) {
    return (
      <>
        {isLoading && (
          <div className="commonLoader">
            <Loader active></Loader>
          </div>
        )}
        <div className="createClaimWrapper">
          <div className="backToPageLink">
            <h3>{isEditForm ? "Edit Claim" : "Create Claim"}</h3>
            <Link to="/claimConfirmation">Go to Pending Items </Link>
          </div>
          <div className="createClaimForm">
            {showSearchFilter && (
              <div className="loadFeedbackSearch">
                <Form>
                  <FormGroup>
                    <label>Search By:</label>
                    <FormGroup>
                      <FormField
                        control={Radio}
                        value="days"
                        checked={searchFormVal.searchBy === "days"}
                        label="90 Days"
                        name="searchBy"
                        onChange={handleSearchBy}
                      ></FormField>
                      <FormField
                        control={Radio}
                        value="invoiceDate"
                        label="Invoice Date"
                        name="searchBy"
                        checked={searchFormVal.searchBy === "invoiceDate"}
                        onChange={handleSearchBy}
                        disabled={
                          createClaim.subReason.split("_")[0] === "22" ||
                          createClaim.subReason.split("_")[0] === "23"
                            ? true
                            : false
                        }
                      ></FormField>
                    </FormGroup>
                    {showDateFilter && (
                      <div className="dateWrapper">
                        <SemanticDatepicker
                          name="fromDate"
                          onChange={handleSearchBy}
                          id="startDatePicker"
                          className={
                            searchFormVal.errorFromDate ? "errorDateField" : ""
                          }
                          minDate={date}
                          maxDate={new Date()}
                          placeholder={"From Date"}
                        />
                        <SemanticDatepicker
                          name="toDate"
                          onChange={handleSearchBy}
                          id="startDatePicker"
                          className={
                            searchFormVal.errorToDate ? "errorDateField" : ""
                          }
                          minDate={
                            searchFormVal.fromDate !== "" &&
                            searchFormVal.fromDate
                          }
                          maxDate={new Date()}
                          placeholder={"To Date"}
                        />
                      </div>
                    )}
                    <div className="btnWrapper">
                      <button
                        className="ui button redBtn"
                        onClick={searchInvoice}
                      >
                        Search
                      </button>
                      <button
                        className="ui button"
                        onClick={clearSearchInvoice}
                      >
                        Clear
                      </button>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            )}
            <div className="ui form">
              <FormGroup>
                <FormSelect
                  label="Claim Reason"
                  width={6}
                  onChange={chooseType}
                  options={claimTypesOpt}
                  placeholder={
                    isEditForm ? createClaim.claimTypeLabel : "Claim Reason"
                  }
                  required
                  error={errorFields?.claimType ? true : false}
                ></FormSelect>
                {_.isEmpty(reasonOpt) ? (
                  <FormInput
                    label="Sub Reason"
                    placeholder="Sub Reason"
                    width={6}
                    disabled
                    className="customDisabled"
                    error={errorFields?.subReason ? true : false}
                  />
                ) : (
                  <FormSelect
                    label="Sub Reason"
                    name="subReason"
                    onChange={chooseSubReason}
                    value={createClaim.subReason}
                    placeholder={
                      isEditForm ? createClaim.subResonLabel : "Sub Reason"
                    }
                    options={reasonOpt}
                    width={6}
                    error={errorFields?.subReason ? true : false}
                    required
                  ></FormSelect>
                )}
                <FormSelect
                  label="Shipment Type"
                  name="shipmentType"
                  width={6}
                  onChange={handleSelectOpt}
                  value={createClaim.shipmentType}
                  options={shipmentType}
                  placeholder="Confirm the shipment type"
                  className="mandatoryField"
                  error={errorFields?.shipmentType ? true : false}
                ></FormSelect>
              </FormGroup>
              <FormGroup>
                {directShipDetails ? (
                  <FormInput
                    label="Invoice Number"
                    name={"directShipInvoice"}
                    placeholder="Invoice Number"
                    value={directShipValues.invoice}
                    onChange={directShipInvoice}
                    width={7}
                  ></FormInput>
                ) : (
                  !_.isEmpty(invoiceOpt) && (
                    <div
                      style={{ marginBottom: "1rem" }}
                      className="field customSelectBoxDiv customSelectBox"
                      id={errorFields?.invoice ? "errorField" : ""}
                    >
                      <label style={{ display: "inlineBlock" }}>
                        Invoice Number{" "}
                        {!directShipDetails &&
                          (_.isEmpty(createClaim.reasonType) ||
                            createClaim.reasonType === "null") && (
                            <span className="mandatoryField">*</span>
                          )}
                      </label>
                      {createClaim.invoice !== "" &&
                        (createClaim.reasonType === "D" ||
                          directShipDetails ||
                          createClaim.reasonType === "S") && (
                          <span
                            onClick={() => {
                              setCreateClaim((draft) => {
                                draft.invoice = "";
                              });
                              selectRef.current.clearValue();
                            }}
                          >
                            <i className="fal fa-times"></i>
                          </span>
                        )}
                      <WindowedSelect
                        //components={ ClearIndicator }
                        options={invoiceOpt}
                        ref={selectRef}
                        onChange={handleInvoiceChange}
                        defaultValue={isEditForm && defultInvoiceVal[0]}
                        isLoading={isLoading}
                        //isClearable={true}
                      />
                    </div>
                  )
                )}
                {createClaim.reasonType === "S" && (
                  <>
                    <FormInput
                      label="Item #"
                      name={"stockItem"}
                      placeholder="Item #"
                      value={stockedValues.itemNumber}
                      width={8}
                      required
                      disabled
                    />
                    <FormInput
                      label="Item Description"
                      name={"itemDescription"}
                      placeholder="Item Description"
                      width={10}
                      disabled
                      value={stockedValues?.shortDesc}
                    />
                    <FormGroup width={6} className="serialNoIcon">
                      <FormInput
                        label="Qty"
                        name={"stockedQty"}
                        placeholder="Qty"
                        required
                        value={stockedValues.qty}
                        onChange={stockedQtyChange}
                        onInput={stockedQtyFocusOut}
                        error={stockedError?.qty ? true : false}
                      />
                      {stockedError?.qty && (
                        <p>Quantity range should be between 1 - 999</p>
                      )}
                      {isWaterHeater && (
                        <span onClick={handleSerialPopup}>
                          <i className="fas fa-info-circle"></i>
                        </span>
                      )}
                    </FormGroup>

                    <FormInput
                      label="Unit Cost"
                      name={"unitCost"}
                      placeholder="Unit Cost"
                      width={6}
                      value={stockedValues.unitCost}
                      disabled
                      //onChange={stockedQtyChange}
                    />
                    <FormInput
                      label="Po Number"
                      placeholder="Po Number"
                      name={"poNum"}
                      width={6}
                      value={createClaim.poNum}
                      onChange={poNumHandler}
                      //error={errorFields?.poNum ? true : false}
                    />
                  </>
                )}
                {(createClaim.reasonType === "D" || directShipDetails) && (
                  <>
                    <FormInput
                      label="Vendor"
                      name={"vendor"}
                      placeholder="Vendor Name"
                      value={directShipDetails?.vendorName}
                      width={9}
                      required
                      disabled
                    />
                    <FormInput
                      label="Model"
                      name={"Model"}
                      placeholder="Model"
                      value={directShipDetails?.model}
                      width={4}
                      required
                      disabled
                    />
                    <FormInput
                      label="Item Description"
                      name={"itemDescription"}
                      placeholder="Item Description"
                      width={12}
                      required
                      error={directShipError?.desc ? true : false}
                      value={directShipValues?.desc}
                      onChange={handleDirectshipVal}
                    />
                    <FormGroup className="qtyMsgBox" width={5}>
                      <FormInput
                        label="Qty"
                        name={"qty"}
                        placeholder="Qty"
                        required
                        value={directShipValues.qty}
                        onChange={handleDirectshipVal}
                        onBlur={directShipQtyFocusOut}
                        error={directShipError?.qty ? true : false}
                      />
                      {directShipError?.qty && (
                        <p>Qty should be greater than 0</p>
                      )}
                    </FormGroup>

                    <FormInput
                      label="Unit Cost"
                      name={"unitCost"}
                      placeholder="Unit Cost"
                      width={6}
                      required
                      error={directShipError?.unitCost ? true : false}
                      value={directShipValues?.unitCost}
                      onBlur={directShipUnitCostFocusOut}
                      onChange={handleDirectshipUnitCost}
                    />
                    <FormInput
                      label="Po Number"
                      placeholder="Po Number"
                      name={"poNum"}
                      width={6}
                      value={createClaim.poNum}
                      onChange={poNumHandler}
                      //error={errorFields?.poNum ? true : false}
                    />
                  </>
                )}
                {!directShipDetails &&
                  (_.isEmpty(createClaim.reasonType) ||
                    createClaim.reasonType === "null") && (
                    <>
                      <FormInput
                        label="Pack"
                        placeholder="Pack"
                        readOnly
                        value={createClaim.packQty}
                        width={6}
                      />
                      <FormField width={8} className="fieldMsgBox">
                        <label>
                          How many are claimed?
                          <span className="mandatoryField">*</span>
                        </label>
                        <FormInput
                          placeholder="Enter Quantity"
                          value={createClaim.qty}
                          required
                          onChange={qtyHandler}
                          error={errorFields?.qty ? true : false}
                        />
                        {createClaim.qtyMsg && <p>{createClaim.qtyMsg}</p>}
                        {errorFields?.qty && createClaim.qty <= 0 && (
                          <p>Qty should be greater than 0</p>
                        )}
                      </FormField>
                      <FormInput
                        label="Po Number"
                        placeholder="Po Number"
                        name={"poNum"}
                        width={6}
                        value={createClaim.poNum}
                        onChange={poNumHandler}
                        //error={errorFields?.poNum ? true : false}
                      />
                    </>
                  )}
              </FormGroup>
              {
                <UploadImage
                  handleUploadImg={handleUploadImg}
                  maxImage={4}
                  clearImages={clearImages}
                  uploadImages={uploadImages}
                  isEdit={true}
                  removeEditImage={removeEditImage}
                  enableCheckBox={true}
                />
              }
              <FormGroup className="commentError">
                <FormField
                  label="Comment"
                  value={createClaim.comment}
                  name={"comment"}
                  width={16}
                  control="textarea"
                  rows="3"
                  required={isCommentMandatory ? true : false}
                  onChange={commentHandler}
                  error={errorFields?.comment ? true : false}
                  className="commentTextBox"
                  placeholder={
                    isWaterHeater
                      ? "Enter Reliance RGA number if available ?"
                      : "Enter Comment"
                  }
                />
                {createClaim.comment === "" &&
                parseInt(createClaim.subReasonId) === 41 ? (
                  <p>Please enter Price Adjustment</p>
                ) : parseInt(createClaim.subReasonId) === 22 ? (
                  <p>Please enter Model and UPC information</p>
                ) : parseInt(createClaim.subReasonId) === 23 ? (
                  <p>Please enter Model and UPC information</p>
                ) : (
                  ""
                )}
              </FormGroup>
            </div>

            <div className="submitClaimBtn">
              <button className="ui button" onClick={handleClear}>
                Clear
              </button>
              {createClaim.reasonType === "D" || directShipDetails ? (
                <button
                  className="ui button redBtn"
                  onClick={submitDirectShipClaim}
                >
                  {isEditForm ? "Update Item" : "Add Item"}
                </button>
              ) : createClaim.reasonType === "S" ? (
                <button
                  className="ui button redBtn"
                  onClick={submitStockedClaim}
                >
                  {isEditForm ? "Update Item" : "Add Item"}
                </button>
              ) : (
                <button className="ui button redBtn" onClick={submitClaim}>
                  {isEditForm ? "Update Item" : "Add Item"}
                </button>
              )}
            </div>
          </div>
        </div>

        <Modal className="serialPoup" open={stockedValues.showPopup}>
          <ModalContent>
            <div className="closeSearchPoup">
              <p className="viewDetailsPdfLink">
                <Link to={docLink} target="_blank">
                  True Value POLICY A WARRANTY PROGRAM
                </Link>
              </p>
              <button className="ui button redBtn" onClick={closeSerialPopup}>
                Close
              </button>
            </div>
            <div className="">
              <Form>
                {serialNoValues.map((ele, index) => {
                  return (
                    <FormGroup
                      equal
                      className="serialPoupFromGroup"
                      key={index + 1}
                    >
                      <FormInput
                        label="Serial No #"
                        placeholder="Enter Serial Number"
                        width={8}
                        name="text"
                        index={index}
                        value={tempSerialVal[index].serialNo}
                        onChange={handleSerialNoValue}
                      />
                      <FormSelect
                        label="Warranty Reason"
                        width={8}
                        name="option"
                        onChange={handleSerialNoValue}
                        value={tempSerialVal[index].optValue}
                        index={index}
                        options={serialNoOpt}
                        placeholder="Select"
                      ></FormSelect>
                    </FormGroup>
                  );
                })}
              </Form>
            </div>
          </ModalContent>
        </Modal>

        <Modal className="serialMsgPoup" open={msgPopup}>
          <ModalContent>
            <div className="">
              <p>
                Please enter all serial numbers and warranty reasons. You can
                find this information on the water heater rating plate. -$
                {stockedValues && stockedValues.itemNumber}.
              </p>
            </div>
            <div className="closeSearchPoup">
              <button
                className="ui button redBtn"
                onClick={() => setMsgPoup(false)}
              >
                Close
              </button>
            </div>
          </ModalContent>
        </Modal>
        {qtyInfoPopup && (
          <InfoMessage
            des={"Are you sure the quantity is correct?"}
            noValidQty={noValidQty}
            yesValidQty={yesValidQty}
          />
        )}
        {stockedValues.showMaxReachPopup && (
          <InfoMessage
            des={
              "Your quantity over 99, please contact the Policy A team before processing at PolicyA@truevalue.com to confirm the next steps. Thank You!"
            }
            noValidQty={noValidQty}
            hideYes={true}
          />
        )}
        {directShipValues.infoPopup && (
          <InfoMessage
            des={"Are you sure the quantity is correct?"}
            noValidQty={noValidDirectShipQty}
            yesValidQty={yesValidDirectShipQty}
          />
        )}
        {directShipValues.showMaxReachPopup && (
          <InfoMessage
            des={
              "Your quantity over 99, please contact the Policy A team before processing at PolicyA@truevalue.com to confirm the next steps. Thank You!"
            }
            noValidQty={noValidQty}
            hideYes={true}
          />
        )}
        {directShipValues.unitCostInfoPoup && (
          <InfoMessage
            des={"Are you sure the unit cost is correct?"}
            noValidQty={noValidDirectShipUnitCost}
            yesValidQty={yesValidDirectShipQty}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="no_prdcts_display">
        <h1>SKU Number Not Found !</h1>
      </div>
    );
  }
};

export default CreateClaims;
