import React, { memo } from "react";
import renderImages from "../common/RenderImages";
import { Grid, Segment, GridColumn } from "semantic-ui-react";
import utilities from "../../utils/utilities";
import "./style.scss";
import _ from "lodash";
import history from "../../history";
import { Link } from "react-router-dom";

const ProductDetails = ({ product }) => {
  const createClamis = () => {
    history.push({
      pathname: "/createClamis",
      state: {
        itemNumber: product.Item_Number,
        product: product,
      },
    });
  };
  return (
    <div className="productDetailsWrapper">
      {!_.isEmpty(product) &&
        (!_.isEmpty(product.Item_Number) ? (
          <>
            <div className="backToPageLink">
              <h3>Confirm the Item</h3>
              <Link to={"/newClaim"}>Back To Edit the Item </Link>
            </div>

            <Segment>
              <Grid>
                <GridColumn computer={4} mobile={16} tablet={4}>
                  {renderImages.getImage({
                    src: `https://images.truevalue.com/getimage.asp?id=${product.Item_Number}&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2`,
                    style: { maxWidth: "100%" },
                  })}
                </GridColumn>
                <GridColumn computer={12} mobile={16} tablet={12}>
                  <h3>{product.Product_Title} &nbsp;&nbsp;</h3>
                  <p className="description">
                    {product.Product_Description || product.Long_Description}
                  </p>
                  <ul className="productDetailList">
                    <li>
                      {"ITEM #: " + utilities.emptyForNull(product.Item_Number)}
                    </li>
                    <li>
                      {"MODEL #: " + utilities.emptyForNull(product.Model)}{" "}
                    </li>
                    <li>
                      {"PACK: " +
                        utilities.emptyForNull(product.S_Pack_Type) +
                        utilities.emptyForNull(product.S_Pack_Qty)}
                    </li>
                  </ul>
                  <div className="comfirmBtnDiv">
                    <button className="ui button redBtn" onClick={createClamis}>
                      Confirm
                    </button>
                  </div>
                </GridColumn>
              </Grid>
            </Segment>
          </>
        ) : (
          <div className="no_prdcts_display">
            <h1>No Products to Display!</h1>
          </div>
        ))}
    </div>
  );
};

export default memo(ProductDetails);
