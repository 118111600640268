import React, { useState, useEffect, memo, useRef } from "react";
import { Loader, Form, FormGroup, FormField, Input } from "semantic-ui-react";
import "./style.scss";
import UploadImage from "../UploadImage";
import { useImmer } from "use-immer";
import useStableCallback from "../common/CustomHook/useStableCallback";
import utilities from "../../utils/utilities";
import InfoMessage from "../CreateClaims/InfoMessage";
import _ from "lodash";
import { CLAIMS_BASE_API } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import { useSelector } from "react-redux";
import moment from 'moment-timezone';
import { v4 as uuid } from "uuid";
import { uploadImagesToGcp } from "../common/productHandler";
export const EditPolicyA = (props) => {
  const qtyRef = useRef();
  const { storeId, userName, firstName, lastName } = useSelector(
    (state) => state.SessionReducer,
  );
  const [clearImages, setClearImages] = useState(false);
  const [uploadImages, setUploadImages] = useState([]);
  const [editedImages, setEditedImages] = useState([]);
  const editData = props?.editData;
  const [qty, setQty] = useImmer({
    val: "",
    error: false,
    showMaxReachPopup: false,
    infoPopup: false,
    validQty: false,
  });
  const [images, setImages] = useImmer({
    image: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const images =
      editData?.imageDetails &&
      editData?.imageDetails.map((ele) => {
        return {
          imageName: ele.imageObject_Name,
          imageContent: ele.imageObject_URL,
          imageType: ele.image_Damage_Type.split(","),
        };
      });
    setUploadImages(images);
    setQty((draft) => {
      draft.val = editData.quantity;
    });
    console.log(editData.quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);
  const handleUploadImg = useStableCallback(
    (img) => {
      setClearImages(false);
      setImages((draft) => {
        draft.image = img;
      });
    },
    [images.image],
  );
  const removeEditImage = (img) => {
    setEditedImages([...editedImages, img]);
  };
  const onChangeQty = (e) => {
    const { value } = e.target;
    if (value !== "") {
      const regularExpression = /^\d+$/;
      if (!regularExpression.test(value)) {
        return false;
      }
      if (value > 999) {
        utilities.showToast(`Quantity range should be between 1 - 999`, true);
        return false;
      }
    }
    setQty((draft) => {
      draft.val = value;
    });
  };
  const qtyFocusOut = () => {
    const { val } = qty;
    if (
      !_.isEmpty(qty.val) &&
      parseInt(qty.val) > 0 &&
      editData.item_Type === "Stock"
    ) {
      setIsLoading(true);
      restUtils
        .getDataWithBearer(
          `${CLAIMS_BASE_API}/${storeId}/ValidatePolicyAStockItemQuantity?quantity=${val}&itemNumber=${editData.item_Nbr}&invoiceNumber=${_.isEmpty(editData.invoice_Number) ? 0 : editData.invoice_Number}`,
        )
        .then((res) => {
          if (!res.data.isValidQty) {
            utilities.showToast(res.data.result, true);
            setQty((draft) => {
              draft.val = "";
            });
            setIsLoading(false);
            qtyRef.current.focus();
            return;
          }
          if (parseInt(val) > 99) {
            setQty((draft) => {
              draft.showMaxReachPopup = true;
            });
            return;
          } else if (parseInt(val) >= 10) {
            setQty((draft) => {
              draft.infoPopup = true;
            });
          } else {
            setQty((draft) => {
              draft.validQty = true;
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  function noValidQty() {
    setQty((draft) => {
      draft.showMaxReachPopup = false;
    });
  }
  function handleWrongQty() {
    setQty((draft) => {
      draft.infoPopup = false;
      draft.val = editData.quantity;
    });
    qtyRef.current.focus();
  }
  function handleCorrectQty() {
    setQty((draft) => {
      draft.infoPopup = false;
    });
  }
  function submit() {
    const { image } = images;
    if (qty.val === "" || parseInt(qty.val) === 0) {
      utilities.showToast("Enter valid qty", true);
      return;
    }
    let claimImages = image.map((img) => {
      let imageType = img["imageType"].join(",");
      return {
        ...img,
        imageType,
      };
    });
    const imageObjects = editedImages.map((ele) => {
      return {
        imageObject_Name: ele,
      };
    });
    claimImages = claimImages.map((ele) => {
      return {
        ...ele,
        imageContent: ele.imageContent.includes(
          "https://storage.googleapis.com",
        )
          ? ""
          : ele.imageContent,
      };
    });
    let requestPayLoad = {
      claimNumber: editData.claim_Nbr,
      itemOrModelNbr:
        editData.item_Type === "Stock"
          ? editData.item_Nbr.toString()
          : editData.model_Nbr,
      quantity: qty.val,
      claimItemId: editData.claim_Item_Id,
      updatedBy: `${firstName} ${lastName} (${userName.substr(1)})`,
      imageObjects,
      images: claimImages,
    };
    setIsLoading(true);
    restUtils
      .postDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/RecalculateAndUpdatePolicyAClaimItem`,
        requestPayLoad,
      )
      .then((res) => {
      let imageObject = claimImages.map(ele=> {
        return {
          ...ele,
          imageName: ele.imageContent === '' ?  ele.imageName : `${utilities.getFormattedUploadDate()}/${storeId}/${editData.claim_Nbr}/${uuid().substring(0, 5)}_${utilities.getFormattedUploadDate_1()}.jpeg`
      }
      })
      let imageObjectNames = imageObject.filter(ele=> {
        if(ele.imageContent !==''){
          return ele
        }
      })
      if(imageObjectNames.length > 0) {
        console.log(imageObjectNames)
        let url = `${CLAIMS_BASE_API}/${storeId}/GetSignedImageURLsForClaimItemUpload?contentType=image/jpeg`
        uploadImagesToGcp(imageObjectNames,url).then(gcpRes=>{
          if(gcpRes) {
            Promise.all(gcpRes).then(getRes=>{
              if(getRes[0].status === 200) {
                let requestPayLoad = {};
                requestPayLoad =  imageObjectNames.map((imageItem,index)=>{
                  return {
                    "claim_LoadFeedback_Nbr":  editData.claim_Nbr,
                    "imageObject_Name":imageItem.imageName,
                    "image_Type": imageItem.imageType,
                    "created_By":`${firstName} ${lastName} (${userName.substr(1)})`,
                    "created_Date": moment().tz('America/Chicago').format(),
                    "itemNbr":  editData.item_Type === "Stock"
                    ? editData.item_Nbr.toString()
                    : editData.model_Nbr,
                    "sessionID": ""
                  }
                })
                restUtils
                .postDataWithBearer(`${CLAIMS_BASE_API}/${storeId}/AddPolicyAImageDetailsToDB`,requestPayLoad).then(res_1=>{
                  utilities.showToast("Claim Update successfully");
                  props.editClosePoup();
                }).catch(err=>{
                  console.log(err)
                })
              }else {
                utilities.showToast('Images Not Uploaded to GCP', true)
                setIsLoading(false);
              }
             })
            }
            else {
              utilities.showToast('Images Not Uploaded to GCP', true)
              setIsLoading(false);
            }
       })
      }else {
        utilities.showToast("Claim Update successfully");
        props.editClosePoup();
        setIsLoading(false);
        }
       
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  function clear() {
    setQty((draft) => {
      draft.val = "";
    });
    setImages((draft) => {
      draft.image = [];
    });
    setClearImages(true);
  }
  return (
    <>
      {isLoading && (
        <div className="commonLoader">
          <Loader active></Loader>
        </div>
      )}
      <Form>
        <FormGroup>
          <FormField width={5}>
            <label>
              Qty<span className="mandatoryField">*</span>
            </label>
            <Input
              ref={qtyRef}
              fluid
              placeholder="Qty"
              value={qty.val}
              name={"qty"}
              onChange={onChangeQty}
              onBlur={qtyFocusOut}
            />
          </FormField>
        </FormGroup>
        <UploadImage
          handleUploadImg={handleUploadImg}
          maxImage={4}
          clearImages={clearImages}
          uploadImages={uploadImages}
          isEdit={true}
          removeEditImage={removeEditImage}
          enableCheckBox={true}
        />
      </Form>
      <div className="closeSearchPoup">
        <button className="ui button" onClick={clear}>
          Clear
        </button>
        <button className="ui button redBtn" onClick={submit}>
          Update Claim
        </button>
      </div>
      {qty.infoPopup && (
        <InfoMessage
          des={"Are you sure the quantity is correct?"}
          noValidQty={handleWrongQty}
          yesValidQty={handleCorrectQty}
        />
      )}
      {qty.showMaxReachPopup && (
        <InfoMessage
          des={
            "Your quantity over 99, please contact the Policy A team before processing at PolicyA@truevalue.com to confirm the next steps. Thank You!"
          }
          noValidQty={noValidQty}
          hideYes={true}
        />
      )}
    </>
  );
};

export default memo(EditPolicyA);
