import {
  persistCombineReducers,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import SessionReducer from "./views/Login/reducer";
import UtilityReducer from "./utils/utilitySlice";
import { configureStore } from "@reduxjs/toolkit";
import { reducer as toastr } from "react-redux-toastr";
const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["toastr"],
};

const rootReducer = persistCombineReducers(rootPersistConfig, {
  SessionReducer: persistReducer({ key: "session", storage }, SessionReducer),
  toastr,
  UtilityReducer: persistReducer(
    {
      key: "utility",
      storage,
    },
    UtilityReducer,
  ),
});

const history = createBrowserHistory();
export const store = configureStore({
  reducer: rootReducer,
  //devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
export { history };
export default store;
