import _ from "lodash";
import store from "../configureStore";
import { setCommonLoader } from "./utilitySlice";

export const utilities = {
  emptyForNull: (value) => {
    return value && value !== "" && value !== "0" ? value : "";
  },
  hyphenForNull: (value) => {
    if (typeof value == "boolean") {
      return value ? "Yes" : "No";
    }
    return value && value !== "" ? value : " - ";
  },
  isEmptyOrNullString: (stringOrObjectOrNull) => {
    return (
      _(stringOrObjectOrNull).toString().trim() === "" ||
      _.isEmpty(stringOrObjectOrNull.toString())
    );
  },
  decodeToken: function (token) {
    try {
      // if the token has more or less than 3 parts or is not a string
      // then is not a valid token
      if (token.split(".").length !== 3 || typeof token !== "string") {
        return null;
      }

      // payload ( index 1 ) has the data stored and
      // data about the expiration time
      const payload = token.split(".")[1];
      // determine the padding characters required for the base64 string
      const padding = "=".repeat((4 - (payload.length % 4)) % 4);
      // convert the base64url string to a base64 string
      const base64 = payload.replace("-", "+").replace("_", "/") + padding;
      // parse base64 into json
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join(""),
      );
      // decode json
      const decoded = JSON.parse(jsonPayload);

      return decoded;
    } catch (error) {
      console.log(error)
      // Return null if something goes wrong
      return null;
    }
  },
  setLoader: function (showLoader) {
    store.dispatch(setCommonLoader(showLoader));
  },
  replaceAll: (string, find, replaceStr) => {
    if (!string || string === null) {
      return "";
    }
    return string.toString().replace(new RegExp(find, "g"), replaceStr);
  },

  showToast: (toastText, isError) => {
    var x = document.getElementById("snackbar");
    if (x) {
      const className = isError ? "red show" : "show";
      x.innerHTML = utilities.replaceAll(toastText, "\n", "<br/>");
      x.className = className;
      setTimeout(function () {
        x.className = x.className.replace(className, "");
      }, 5000);
    }
  },
  padWithZeros: (value, reqLength) => {
    value = value.toString();
    const strLength = value.length;
    return reqLength <= strLength
      ? value
      : new Array(reqLength - strLength + 1).join("0") + value;
  },
  cookieClear: () => {
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      // expire date is set to past, so that cookie will get expired
      document.cookie = name + "=;expires=Thu,01 Jan 1970 00:00:00 GMT;path=/";
    });
  },
  hasParentWithMatchingSelector: (target, selector) => {
    if (document.querySelectorAll("#" + selector)[0] === undefined) {
      return;
    }
    return document.querySelectorAll("#" + selector)[0].contains(target);
  },
  validateFileType: (file) => {
    const fileType = ["jpg", "png", "jpeg"];

    return fileType.includes(file.toLowerCase());
  },
  getFormattedDate: (datetime) => {
    const dateOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "America/Chicago",
    };
    let fullDate = new Date(datetime);
    let newDate = fullDate.toLocaleDateString("en-US", dateOptions);
    return newDate;
  },
  getCurrDate: () => {
    let tempDate = new Date();
    return (
      tempDate.getMonth() +
      1 +
      "-" +
      tempDate.getDate() +
      "-" +
      tempDate.getFullYear()
    );
  },
  getDateFromTimeStamp1: (datetime) => {
    let fullDate = new Date(datetime);
    return fullDate.toLocaleTimeString("en-US");
  },
  getDateFromTimeStampShip: (date) => {
    let fullDate = new Date(date);
    return fullDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  },
  getFormattedUploadDate: () => {
    const dateOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "America/Chicago",
    };
    let fullDate = new Date();
    let newDate = fullDate.toLocaleDateString("en-US", dateOptions);
    let tempDate = new Date(newDate);
    const year = tempDate.getFullYear()
    const month = `${tempDate.getMonth() + 1}`.padStart(2, "0")
    return [year, month].join("/") // 13/12/2017
  },
  getFormattedUploadDate_1: () => {
    const dateOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: "America/Chicago",
    }
    let fullDate = new Date();
    let newDate = fullDate.toLocaleDateString("en-US", dateOptions);
    let tempDate = new Date(newDate);
    const year = tempDate.getFullYear()
    const month = `${tempDate.getMonth() + 1}`.padStart(2, "0")
    const date = tempDate.getDate()
    const hour = `${tempDate.getHours()}`.padStart(2, "0")
    const mins = `${tempDate.getMinutes()}`.padStart(2, "0")
    const sec = `${tempDate.getSeconds()}`.padStart(2, "0")
    return [year, month, date, hour, mins, sec].join("-") // 13/12/2017
  },
  roundWithdollar: (value) => {
    if (value && typeof value === "number")
      return "$" + String(value.toFixed(2));
    return value === 0 ? `$0.00` : "";
  },
  forEmptyValiadation: (fields, data) => {
    const errorField = [];
    for (const [key, value] of Object.entries(data)) {
      if (fields.includes(key)) {
        if (_.isEmpty(value)) {
          console.log(key);
          errorField.push(key);
        }
      }
    }
    console.log(errorField);
    return errorField;
  },
};

export default utilities;
