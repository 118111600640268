import React, { memo } from "react";
import SearchBox from "../../components/NavBar/SearchBox";
import { Modal, ModalContent } from "semantic-ui-react";
import { BrowserView, MobileView } from "react-device-detect";
import history from "../../history";
import { Link } from "react-router-dom";

export const SearchSku = (props) => {
  const redirectToScan = () => {
    history.push("/scan");
  };
  const getContent = () => {
    return (
      <>
        <div>
          <MobileView className="scanBarcode">
            <p>Scan or Enter the Sku for the Item</p>
          </MobileView>
          <BrowserView>
            <p>Enter the Sku for the Item</p>
          </BrowserView>
          <div className="searckSkuBoxWrapper">
            <MobileView className="scanBarcode scanBarcodeDiv">
              <i className="fal fa-barcode-read" onClick={redirectToScan}></i>
            </MobileView>
            <SearchBox />

            <div className="noItemDiv">
              <Link className="viewDetailsLink" to={"/searchItem"}>
                Don't know the item #?
              </Link>
              <Link className="" to={"/claimConfirmation"}>
                Go to pending items
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  };
  return props?.closePoup ? (
    <Modal className="searckSkuPopUp" open={true}>
      <ModalContent>
        <span className="closeSearchPoup" onClick={() => props.closePoup()}>
          <i className="fas fa-times"></i>
        </span>
        {getContent()}
      </ModalContent>
    </Modal>
  ) : (
    <div className="searckSkuPopUp searchSkuWrapper">{getContent()}</div>
  );
};

export default memo(SearchSku);
