import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authorizeUser,
  getSecretKey,
  login,
  logout,
  setAuthentication,
} from "../Login/actions";
import utilities from "../../utils/utilities";
import Auth from "../../auth";
import AuthLoader from "./AuthLoader";
import useStableCallback from "./CustomHook/useStableCallback";
const auth = new Auth();
export const AuthPage = (props) => {
  const { isAuthenticated, access_token, secretKey } = useSelector(
    (state) => state.SessionReducer,
  );
  const dispatch = useDispatch();
  const [isStateAuthenticated, setIsStateAuthenticated] =
    useState(isAuthenticated);
  const handleAuthentication = useStableCallback(() => {
    console.log("trying to parse Hash");
    if (/access_token|id_token|error/.test(window.location.hash)) {
      console.log("hash Found");
      const authObj = auth.handleAuthentication(window.location.hash);
      const { id_token, access_token, expires_at } = authObj;
      dispatch(authorizeUser(id_token, access_token, expires_at));
      dispatch(getSecretKey(access_token));
      let isAuthenticated = !utilities.isEmptyOrNullString(access_token);
      localStorage.setItem("expires_at", expires_at);
      let userObj = {
        userName: access_token.username,
        viewOnly:
          access_token?.viewonly === "Y"
            ? true
            : false /* Remove user name later */,
        csr: access_token.csr,
        storeId: utilities.padWithZeros(access_token.storeid, 5),
        appName: "NW",
        viewOrders: access_token.vieworders,
        userguid: access_token.userguid,
        storeguid: access_token.storeguid,
        firstName: access_token.fname,
        lastName: access_token.lname,
      };
      if (isAuthenticated) {
        getUser(
          userObj,
        ); /* supposed to pass storeid and user id from id token */
        setTimeout(() => {
          setIsStateAuthenticated(true);
          dispatch(setAuthentication(true));
        },1500);
      }
      //this.retrieveDetails(id_token,access_token,expires_at);
    } else {
      console.log("hash Not Found - Re authenticating");
      auth.login();
    }
  }, []);
  const retrieveDetails = useStableCallback(
    (id_token, access_token, expires_at) => {
      dispatch(authorizeUser(id_token, access_token, expires_at));
      dispatch(getSecretKey(access_token));
      let isAuthenticated = !utilities.isEmptyOrNullString(access_token);
      let userObj = {
        userName: access_token.username,
        viewOnly:
          access_token?.viewonly === "Y"
            ? true
            : false /* Remove user name later */,
        csr: access_token.csr,
        storeId: utilities.padWithZeros(access_token.storeid, 5),
        appName: "NW",
        viewOrders: access_token.vieworders,
        userguid: access_token.userguid,
        storeguid: access_token.storeguid,
        firstName: access_token.fname,
        lastName: access_token.lname,
      };
      if (isAuthenticated) {
        getUser(
          userObj,
        ); /* supposed to pass storeid and user id from id token */
        setIsStateAuthenticated(true);
        dispatch(setAuthentication(true));
      }
    },
    [],
  );
  useEffect(() => {
    if (access_token) {
      const expiresAt = localStorage.getItem("expires_at");
      const now = new Date().getTime();
      if (expiresAt && +expiresAt - now > 0) {
        console.log("tokenValid");
      } else {
        utilities.showToast("Token Expired", true);
        dispatch(logout());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);
  useEffect(() => {
    if (
      !localStorage.getItem("access_token") &&
      !localStorage.getItem("isAuthInvoked")
    ) {
      auth.login();
      console.log("reauthenticating - ");
    } else if (!localStorage.getItem("access_token")) {
      handleAuthentication();
      console.log("handleAuthentication - ");
    }
  }, [access_token, secretKey, handleAuthentication, retrieveDetails]);

  function getUser(userObj) {
    dispatch(
      login(
        utilities.emptyForNull(userObj.userName),
        userObj.storeId,
        true,
        userObj.csr,
        userObj.userguid,
        userObj.storeguid,
        userObj.firstName,
        userObj.lastName,
      ),
    );
  }

  return (
    <div>
      {isStateAuthenticated && props.children}
      {!isStateAuthenticated && <AuthLoader />}
    </div>
  );
};

export default AuthPage;
