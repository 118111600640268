import React, { memo } from "react";
import BreadcrumbComp from "../common/BreadcrumbComp";
import { CustomTable } from "../CustomTable";
import { claimDeletedItemTable } from "../common/TableConstant";
import utilities from "../../utils/utilities";
const DeletedItem = (props) => {
  const data = props?.location?.state?.data
    ? props?.location?.state?.data
    : props?.data
      ? props?.data
      : null;
  console.log(data);

  return data ? (
    <div className="deletedItemWrapper">
      <h3>Deleted Items</h3>
      <BreadcrumbComp
        path={[
          { link: "/", text: "Home" },
          { link: "/ClaimsHistory", text: "Claim History" },
          { link: "/claimsDetails", text: "Deleted Item", active: true },
          { link: "/", text: "Inactive" },
        ]}
      ></BreadcrumbComp>
      <h4 style={{ color: "#c7112d" }}>Claim Submitted Successfully</h4>
      <div className="deletedItemHeading">
        <p>
          <strong>Claim No: </strong>
          <span>{data.claimNumber}</span>
        </p>
        <p>
          <strong>Total Claim Value: </strong>
          <span>{utilities.roundWithdollar(data.totalClaimValue)}</span>
        </p>
      </div>
      <CustomTable
        tableType="claimDeletedItemTable"
        product={data.rfaClaimConfirmDetails}
        tableContent={claimDeletedItemTable}
      />
    </div>
  ) : (
    <p>''</p>
  );
};

export default memo(DeletedItem);
