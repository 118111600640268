import React, { useState } from "react";
import { mainMenu } from "./menuConstants";
import { Link } from "react-router-dom";
import history from "../../history";
import { createMedia } from "@artsy/fresnel";
import { useDispatch, useSelector } from "react-redux";
import { retrunToMol } from "../../views/Login/actions";
import { CLAIMS_BASE_API, getPolicyBLink } from "../../urlConstants";
import restUtils from "../../utils/restUtils";

export const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
const Flyout = () => {
  const currentPath = history.location.pathname;
  const [showMenu, setShowMenu] = useState(false);
  const { storeId, storeguid, csr } = useSelector(
    (state) => state.SessionReducer,
  );
  const dispatch = useDispatch();
  const renderMenu = (menus) => {
    return menus.map((menu) => {
      const { key, title, url } = menu;
      return key === "PolicyB" ? (
        <span
          className={
            currentPath === url ? "activeMenu customMenuLink" : "customMenuLink"
          }
          key={key}
          onClick={() => {
            if (key === "PolicyB") {
              restUtils
                .getDataWithBearer(
                  `${CLAIMS_BASE_API}/${storeId}/GetPolicyBClaimGuidToNavigate?userGuid=${storeguid}`,
                )
                .then((res) => {
                  dispatch(
                    retrunToMol(
                      `${getPolicyBLink()}?guid=${res.data}&csr=${csr}&startpage=claimform`,
                    ),
                  );
                });
            }
            return;
          }}
        >
          {title}
        </span>
      ) : (
        <Link
          className={currentPath === url ? "activeMenu" : ""}
          key={key}
          to={url}
        >
          {title}
        </Link>
      );
    });
  };
  return (
    <div className="mainMenuWrapper">
      <div className="gridContainer mobileMenuContainer">
        <MediaContextProvider>
          <Media at="sm">
            <div>
              {showMenu && (
                <span
                  className="backDropShadow"
                  onClick={() => setShowMenu(false)}
                ></span>
              )}
              <div className="mobileMenuIcon" onClick={() => setShowMenu(true)}>
                <i className="fas fa-bars"></i>
              </div>
            </div>
          </Media>
        </MediaContextProvider>
        <div className="navList" id={showMenu ? "showMenu" : ""}>
          <MediaContextProvider>
            <Media at="sm">
              <span className="closeMenu" onClick={() => setShowMenu(false)}>
                <i className="fas fa-times-square"></i>
              </span>
            </Media>
          </MediaContextProvider>
          {renderMenu(mainMenu)}
        </div>
      </div>
    </div>
  );
};
export default Flyout;
