import React from "react";
import { Modal, ModalContent } from "semantic-ui-react";
import ImageGallery from "react-image-gallery";
export const ViewImageGallery = ({ closePopUp, images }) => {
  return (
    <Modal className="imageGalleryPoup" open={true}>
      <ModalContent>
        <span className="closeImagePoup">
          <i className="fas fa-times" onClick={closePopUp}></i>
        </span>
        {images.length > 0 ? (
          <ImageGallery
            items={images}
            lazyLoad={true}
            infinite={false}
            showFullscreenButton={false}
            showPlayButton={false}
          ></ImageGallery>
        ) : (
          <p className="noData">No Images</p>
        )}
      </ModalContent>
    </Modal>
  );
};
