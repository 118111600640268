import React, { useState } from "react";
import Scan from "./Scanner";
import history from "../../history";
import "./style.scss";
import useStableCallback from "../common/CustomHook/useStableCallback";
import BreadcrumbComp from "../common/BreadcrumbComp";

const Scanner = () => {
  const [showScan, setShowScan] = useState(true);
  const [result, setResult] = useState("");
  const handleResult = useStableCallback(
    (value) => {
      setResult(value);
      if (isNaN(value)) {
        setShowScan(false);
        return true;
      }
      history.push(`/product/${value}`);
    },
    [result],
  );
  const scanAgain = () => {
    setShowScan(true);
    setResult("");
  };
  return (
    <div>
      <BreadcrumbComp
        path={[
          { link: "/", text: "Home" },
          { link: "/scan", text: "Scan", active: true },
          { link: "/", text: "Inactive" },
        ]}
      ></BreadcrumbComp>
      <div style={{ marginTop: "1rem" }}>
        {showScan && <Scan handleResult={handleResult} />}
        {isNaN(result) && (
          <div className="scanAgain">
            <p>Invalid Code, Please Scan Again</p>
            <button className="ui button redBtn" onClick={scanAgain}>
              Scan Again
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Scanner;
