import React, { useState, memo } from "react";
import utilities from "../../utils/utilities";
import history from "../../history";
const SearchBox = () => {
  const [sku, setSku] = useState("");
  const handleSkuValue = (e) => {
    const { value } = e.target;
    const regularExpression = /^\d+$/;
    if (!regularExpression.test(value)) {
      //return false;
    }
    setSku(value);
  };
  const searchItem = () => {
    if (sku === "") {
      utilities.showToast("Please enter the Sku");
      return true;
    }
    history.push(`/product/${sku}`);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchItem();
    }
  };
  return (
    <div className="searchBox">
      <input
        type="text"
        value={sku}
        placeholder="Enter Sku"
        onChange={handleSkuValue}
        onKeyDown={(e) => handleKeyDown(e)}
      />
      <span onClick={searchItem}>
        <i className="fa-search icon icon"></i>
      </span>
    </div>
  );
};
export default memo(SearchBox);
