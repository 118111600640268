import React from "react";
import { Link } from "react-router-dom";
export const BreadcrumbComp = (props) => {
  const pathLength = props?.path?.length;
  return pathLength > 0
    ? props.path?.map((item, index) => {
        const isLastItem = index === pathLength - 1;
        const lastTwoItem =
          index === pathLength - 2 || index === pathLength - 1;
        const divider = !lastTwoItem ? (
          <div className="divider" key={item.text + "div"}>
            <i className="chevron right icon"></i>
          </div>
        ) : null;

        return (
          <div className="ui small breadcrumb" key={item.text}>
            <div>
              <div className="section caps">
                {!isLastItem && (
                  <Link
                    to={item.link}
                    className={item?.active ? "activeBreadcrumb" : ""}
                    onClick={item.onClick}
                  >
                    {decodeURIComponent(item.text)}
                  </Link>
                )}
              </div>
              {divider}
            </div>
          </div>
        );
      })
    : null;
};

export default BreadcrumbComp;
