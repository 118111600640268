import React, { memo, useEffect, useState, useRef } from "react";
import {
  FormSelect,
  Loader,
  FormGroup,
  Form,
  FormInput,
} from "semantic-ui-react";
import { CLAIMS_BASE_API } from "../../urlConstants";
import useStableCallback from "../common/CustomHook/useStableCallback";
import restUtils from "../../utils/restUtils";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import _ from "lodash";
import utilities from "../../utils/utilities";
import "react-virtualized/styles.css"; // only needs to be imported once
import InfiniteScrollTable from "../CustomTable/InfiniteScrollTable";
import { browseCatTable } from "../common/TableConstant";
import history from "../../history";

const QuickSearch = () => {
  const [searchOpt, setSearchOpt] = useState([]);
  const { storeId } = useSelector((state) => state.SessionReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBy, setSearchBy] = useImmer({
    label: "",
    value: "",
  });
  const [data, setData] = useState([]);
  const [innerWidth, setInnerWidth] = useState(0);
  const divRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        setInnerWidth(divRef.current.clientWidth);
      }
    };

    // Initial measurement
    if (divRef.current) {
      setInnerWidth(divRef.current.clientWidth);
    }

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const fetchSearchOpt = useStableCallback(() => {
    setIsLoading(true);
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/GetItemSearchCatagories`,
      )
      .then((res) => {
        console.log(res);
        const opt = res.data.map((ele) => {
          return {
            key: ele.id,
            text: ele.description,
            value: ele.id,
          };
        });
        setSearchOpt(opt);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    fetchSearchOpt();
  }, [fetchSearchOpt]);
  const handleSearchBy = (e, data) => {
    setSearchBy((draft) => {
      draft.label = data.value;
      draft.value = "";
    });
  };
  const onChangeText = (e, data) => {
    if (searchBy.label === "tsi" || searchBy.label === "vnd") {
      const regularExpression = /^\d+$/;
      if (!regularExpression.test(data.value)) {
        utilities.showToast("Value should be number", true);
        return;
      }
    }
    setSearchBy((draft) => {
      draft.value = data.value;
    });
  };
  const handleClear = () => {
    setSearchBy((draft) => {
      draft.label = "";
      draft.value = "";
    });
    setData([]);
  };
  const handleViewItem = useStableCallback((item) => {
    history.push(`/product/${item}`);
  }, []);
  const submitSearch = () => {
    if (_.isEmpty(searchBy.value)) {
      utilities.showToast("Please enter search value", true);
      return;
    }
    setIsLoading(true);
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/PolicyAItemSearch?itemSearchCatagory=${searchBy.label}&itemSearchValue=${searchBy.value}`,
      )
      .then((res) => {
        setIsLoading(false);
        if (_.isEmpty(res?.data)) {
          utilities.showToast("No Data", true);
          return;
        }
        const data = res.data.map((ele) => {
          return {
            ...ele,
            model_Nbr: ele.model_Nbr.trim(),
            vendor_Name: ele.vendor_Name.trim(),
            short_Desc: ele.short_Desc.trim(),
          };
        });
        setData(data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading && <Loader active />}
      <div className="quickSearchWrapper" ref={divRef}>
        <div className="searchTypeDiv">
          <Form>
            <FormGroup widths="equal">
              <FormSelect
                label="Search By"
                name="label"
                placeholder="Select Search Type "
                value={searchBy.label}
                options={searchOpt}
                required
                onChange={handleSearchBy}
              />
              <FormInput
                fluid
                label="Search value"
                placeholder=""
                onChange={onChangeText}
                value={searchBy.value}
                required
                disabled={_.isEmpty(searchBy.label) ? true : false}
              />
              <div className="submitClaimBtn">
                <button className="ui button" onClick={handleClear}>
                  Clear
                </button>
                <button className="ui button redBtn" onClick={submitSearch}>
                  Search
                </button>
              </div>
            </FormGroup>
          </Form>
        </div>
      </div>
      <InfiniteScrollTable
        tableContent={browseCatTable}
        product={data}
        tableType={"browseCatTable"}
        width={innerWidth}
        viewItem={handleViewItem}
      />
      <p>Search results limited to 2000 rows</p>
    </>
  );
};

export default memo(QuickSearch);
