export const loadFeedbackHistory = [
  {
    header: "Load Feedback Nbr",
    key: "loadFeedback_Nbr",
    uniqueId: "loadFeedback_Nbr_history",
    classVal: "",
  },
  { header: "CRM Number", key: "crM_Nbr", uniqueId: "crM_Nbr", classVal: "" },
  { header: "Invoice Number", key: "invoice_Nbr", uniqueId: "", classVal: "" },
  { header: "Created Date", key: "created_Date", uniqueId: "", classVal: "" },
  { header: "Created By", key: "created_By", uniqueId: "", classVal: "" },
];

export const loadFeedBackItemDetailsTable = [
  {
    header: "Load Feedback Nbr",
    key: "loadFeedback_Nbr",
    uniqueId: "customId",
    classVal: "",
  },
  { header: "Invoice Number", uniqueId: "", key: "invoice_Nbr", classVal: "" },
  { header: "CRM Nbr", uniqueId: "", key: "crM_Nbr", classVal: "" },
  { header: "Created Date", uniqueId: "", key: "created_Date", classVal: "" },
  { header: "Upload Images", uniqueId: "imgPopup", key: "", classVal: "" },
];

export const pendingItemTableRfa = [
  {
    header: "Invoice",
    key: "invoiceNumberRDC",
    policyaKey: "invoice_Number",
    uniqueId: "",
    classVal: "",
  },
  {
    header: "Type",
    key: "item_Type",
    policyaKey: "item_Type",
    uniqueId: "",
    classVal: "",
  },
  {
    header: "Item Number",
    uniqueId: "",
    key: "itemNumberRlyBk",
    policyaKey: "item_Nbr",
    classVal: "",
  },
  {
    header: "Short Description",
    uniqueId: "",
    key: "short_Desc",
    policyaKey: "short_Desc",
    classVal: "",
  },
  {
    header: "MFR/Vendor",
    uniqueId: "",
    key: "vendor_Name",
    policyaKey: "vendor_Name",
    classVal: "",
  },
  {
    header: "Return Reason",
    uniqueId: "pendingReturnReason",
    key: "subReason",
    policyaKey: "returnReason",
    classVal: "",
  },
  {
    header: "Model #",
    uniqueId: "",
    key: "model_Nbr",
    policyaKey: "model_Nbr",
    classVal: "",
  },
  { header: "Qty", uniqueId: "pendingItemQty", key: "qty", classVal: "" },
  { header: "Pack", uniqueId: "", key: "pack", policyaKey: "", classVal: "" },
  {
    header: "PO Number",
    uniqueId: "pendingItemPo",
    key: "poNumber",
    policyaKey: "po_Nbr",
    classVal: "",
  },
  {
    header: "Comment",
    uniqueId: "",
    key: "comments",
    policyaKey: "comments",
    classVal: "",
  },
  {
    header: "Claim Value",
    uniqueId: "doller",
    key: "totalClaimValue",
    policyaKey: "totalClaimValue",
    classVal: "",
  },
  { header: "", uniqueId: "edit/delete", key: "", classVal: "" },
];

export const submitConfrimationTable = [
  {
    header: "Invoice",
    key: "invoiceNumber",
    uniqueId: "",
    policyaKey: "invoice_Number",
    classVal: "",
  },
  {
    header: "Type",
    key: "item_Type",
    policyaKey: "item_Type",
    uniqueId: "",
    classVal: "",
  },
  {
    header: "Item Number",
    uniqueId: "",
    key: "itemNumber",
    policyaKey: "item_Nbr",
    classVal: "",
  },
  {
    header: "Short Description",
    uniqueId: "",
    key: "description",
    policyaKey: "short_Desc",
    classVal: "",
  },
  {
    header: "MFR/Vendor",
    uniqueId: "",
    key: "",
    policyaKey: "vendor_Name",
    classVal: "",
  },
  {
    header: "Return Reason",
    uniqueId: "pendingReturnReason",
    key: "",
    policyaKey: "reason",
    classVal: "",
  },
  {
    header: "Model #",
    uniqueId: "",
    key: "model_Nbr",
    policyaKey: "model_Nbr",
    classVal: "",
  },
  {
    header: "Qty",
    uniqueId: "",
    policyaKey: "quantity",
    key: "qtyAppeared",
    classVal: "",
  },
  {
    header: "Comment",
    uniqueId: "",
    key: "comments",
    policyaKey: "comments",
    classVal: "",
  },
  {
    header: "Claim Value",
    uniqueId: "doller",
    policyaKey: "totalClaimValue",
    key: "claim_Value",
    classVal: "",
  },
  {
    header: "Status",
    uniqueId: "statusMsg",
    key: "status",
    policyaKey: "",
    classVal: "",
  },
];

export const confirmationTable = [
  {
    header: "Invoice Number",
    key: "invoiceNumber",
    uniqueId: "",
    classVal: "",
  },
  { header: "Item Number", uniqueId: "", key: "itemNumber", classVal: "" },
  { header: "Description", uniqueId: "", key: "description", classVal: "" },
  { header: "Reason Reason", uniqueId: "", key: "returnReason", classVal: "" },
  { header: "Comment", uniqueId: "", key: "comments", classVal: "" },
  { header: "Qty", uniqueId: "", key: "qtyAppeared", classVal: "" },
  { header: "Status", uniqueId: "", key: "status", classVal: "" },
];

export const confirmationPolicyaTable = [
  { header: "Type", key: "item_Type", uniqueId: "", classVal: "" },
  { header: "Item #", uniqueId: "", key: "item_Nbr", classVal: "" },
  { header: "Qty", uniqueId: "", key: "quantity", classVal: "" },
  {
    header: "Short Description",
    uniqueId: "",
    key: "short_Desc",
    classVal: "",
  },
  { header: "MRF/Vendor", uniqueId: "", key: "vendor_Name", classVal: "" },
  { header: "Model #", uniqueId: "", key: "model_Nbr", classVal: "" },

  {
    header: "Cliam Value",
    uniqueId: "doller",
    key: "claim_Value",
    classVal: "",
  },
];

export const claimHistoryTable = [
  { header: "Claim Date", key: "claimDate", uniqueId: "", classVal: "" },
  { header: "Claim Time", uniqueId: "", key: "claimTime", classVal: "" },
  {
    header: "Claim Number",
    uniqueId: "claimNumberHistory",
    key: "claimNumber",
    classVal: "",
  },
  { header: "CRM Number", uniqueId: "", key: "crmNumber", classVal: "" },
  { header: "PO Number", uniqueId: "", key: "poNumber", classVal: "" },
  { header: "Claim Origin ", uniqueId: "", key: "claimOrigin", classVal: "" },
  {
    header: "Claim Value",
    uniqueId: "doller",
    key: "totalClaimValue",
    classVal: "",
  },
  { header: "Created By", uniqueId: "", key: "createdBy", classVal: "" },
  { header: "Status", uniqueId: "", key: "overAllStatus", classVal: "" },
  { header: "", uniqueId: "rfa_edit", key: "overAllStatus", classVal: "" },
];

export const claimHistoryDetailsTable = [
  { header: "Invoice #", key: "invoiceNumber", uniqueId: "", classVal: "" },
  {
    header: "Item #",
    uniqueId: "claimDatailsItem",
    key: "itemNumber",
    classVal: "",
  },
  { header: "Status", uniqueId: "", key: "status", classVal: "" },
  {
    header: "Return Reason",
    uniqueId: "claimDetailsReturnReason",
    key: "reason",
    classVal: "",
  },
  { header: "Description", uniqueId: "", key: "description", classVal: "" },
  { header: "Pack", uniqueId: "", key: "pack", classVal: "" },
  { header: "Claim Qty", uniqueId: "", key: "claimQty", classVal: "" },
  { header: "Qty Apprvd", uniqueId: "", key: "qtyAppeared", classVal: "" },
  { header: "Invoice Cost", uniqueId: "doller", key: "mbrCost", classVal: "" },
  {
    header: "Calc Rstck Chrg",
    uniqueId: "doller",
    key: "calcRstckChrg",
    classVal: "",
  },
  {
    header: "Calc Line Amt",
    uniqueId: "doller",
    key: "calcLineAmt",
    classVal: "",
  },
  { header: "Date Billed", uniqueId: "", key: "billedDate", classVal: "" },
  { header: "View Images", uniqueId: "claimImgPopup", key: "", classVal: "" },
  { header: "Remarks", uniqueId: "", key: "remarks", classVal: "" },
];

export const policyAclaimHistoryDetailsTable = [
  { header: "Type", key: "item_Type", uniqueId: "", classVal: "" },
  { header: "Invoice #", key: "invoice_Number", uniqueId: "", classVal: "" },
  { header: "Item #", uniqueId: "", key: "item_Nbr", classVal: "" },
  { header: "Qty", uniqueId: "", key: "quantity", classVal: "" },
  {
    header: "Return Reason",
    uniqueId: "claimDetailsReturnReason",
    key: "reason",
    classVal: "",
  },
  {
    header: "Short Description",
    uniqueId: "",
    key: "short_Desc",
    classVal: "",
  },
  { header: "MFR/Vendor", uniqueId: "", key: "vendor_Name", classVal: "" },
  { header: "Model #", uniqueId: "", key: "model_Nbr", classVal: "" },
  { header: "Comment", uniqueId: "", key: "comments", classVal: "" },

  { header: "View Images", uniqueId: "claimImgPopup", key: "", classVal: "" },
  {
    header: "Claim Value",
    uniqueId: "doller",
    key: "claim_Value",
    classVal: "",
  },
  { header: "Claim Status", uniqueId: "", key: "statusDisplay", classVal: "" },
];

export const claimItemDetailsTable = [
  { header: "Item #", uniqueId: "", key: "itemNumber", classVal: "" },
  { header: "Model #", uniqueId: "", key: "modelNumber", classVal: "" },
  { header: "Description", uniqueId: "", key: "description", classVal: "" },
  { header: "Manufacturer", uniqueId: "", key: "manufacturer", classVal: "" },
  { header: "Dept", uniqueId: "", key: "dept", classVal: "" },
  { header: "Pack", uniqueId: "", key: "pack", classVal: "" },
  { header: "Billed Qty.", uniqueId: "", key: "qtyAppeared", classVal: "" },
  { header: "Invoice Cost", uniqueId: "doller", key: "mbrCost", classVal: "" },
  {
    header: "Billed Cost",
    uniqueId: "doller",
    key: "billedCost",
    classVal: "",
  },
  {
    header: "Restock Charge Y/N",
    uniqueId: "",
    key: "restoreCharge",
    classVal: "",
  },
];
export const rfaClaimEditTable = [
  {
    header: "Invoice # / RDC",
    uniqueId: "",
    key: "invoiceNumber",
    classVal: "",
  },
  {
    header: "Item # / Rly Bk Pg Ln",
    uniqueId: "",
    key: "itemNumber",
    classVal: "",
  },
  { header: "Reason", uniqueId: "", key: "returnReason", classVal: "" },
  { header: "Pack", uniqueId: "", key: "pack", classVal: "" },
  { header: "Qty", uniqueId: "", key: "billedQty", classVal: "" },
  { header: "PO Number", uniqueId: "", key: "poNumber", classVal: "" },
  { header: "Comment", uniqueId: "", key: "comments", classVal: "" },
  { header: "Delete", uniqueId: "deleteCheckBox", key: "", classVal: "" },
];

export const claimDeletedItemTable = [
  { header: "Invoice#", uniqueId: "", key: "invoiceNumber", classVal: "" },
  { header: "Item #", uniqueId: "", key: "itemNumber", classVal: "" },
  {
    header: "Short Description",
    uniqueId: "",
    key: "description",
    classVal: "",
  },
  { header: "Retrun Reason", uniqueId: "", key: "returnReason", classVal: "" },
  { header: "Qty", uniqueId: "", key: "claimQty", classVal: "" },
  { header: "Cost", uniqueId: "doller", key: "cost", classVal: "" },
  { header: "Ext Cost", uniqueId: "doller", key: "extCost", classVal: "" },
  { header: "Comment", uniqueId: "", key: "comments", classVal: "" },
  {
    header: "Status",
    uniqueId: "",
    key: "status",
    classVal: "",
  },
];

export const browseCatTable = [
  { header: "TS Item #", uniqueId: "", key: "item_Nbr", classVal: "" },
  { header: "Vendor Name", uniqueId: "", key: "vendor_Name", classVal: "" },
  { header: "Model #", uniqueId: "", key: "model_Nbr", classVal: "" },
  {
    header: "Short Description",
    uniqueId: "",
    key: "short_Desc",
    classVal: "",
  },
];

export const vendorNameTable = [
  { header: "vendorName", uniqueId: "", key: "vendorNumber", classVal: "" },
];
