import React, { memo, useEffect, useState, useRef } from "react";
import { Form, Loader, FormGroup } from "semantic-ui-react";
import WindowedSelect from "react-windowed-select";
import { useImmer } from "use-immer";
import { CLAIMS_BASE_API } from "../../urlConstants";
import useStableCallback from "../common/CustomHook/useStableCallback";
import restUtils from "../../utils/restUtils";
import { useSelector } from "react-redux";
import utilities from "../../utils/utilities";
import "react-virtualized/styles.css"; // only needs to be imported once
import InfiniteScrollTable from "../CustomTable/InfiniteScrollTable";
import { browseCatTable } from "../common/TableConstant";
import history from "../../history";
import _ from "lodash";

const BrowseCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { storeId } = useSelector((state) => state.SessionReducer);
  let deptRef = useRef(null);
  let classRef = useRef(null);
  let subClassRef = useRef(null);
  const [dropDown, setDropDown] = useImmer({
    dept: [],
    class: [],
    subClass: [],
  });
  const [data, setData] = useState([]);
  const [innerWidth, setInnerWidth] = useState(0);
  const divRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        setInnerWidth(divRef.current.clientWidth);
      }
    };

    // Initial measurement
    if (divRef.current) {
      setInnerWidth(divRef.current.clientWidth);
    }

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [searchVal, setSearchVal] = useState(null);
  const fetchDept = useStableCallback(() => {
    setIsLoading(true);
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/GetStockRelayItemDepartment`,
      )
      .then((res) => {
        setIsLoading(false);
        const opt = res.data.map((ele) => {
          return {
            label: ele.departmentName.trim(),
            value: ele.departmentLetter,
            name: "dept",
          };
        });
        setDropDown((draft) => {
          draft.dept = opt;
          draft.class = [];
          draft.subClass = [];

          if (classRef.current) {
            classRef.current.clearValue();
          }
          if (subClassRef.current) {
            subClassRef.current.clearValue();
          }
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [dropDown.dept]);
  const fetchClass = useStableCallback((value) => {
    setIsLoading(true);
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/GetStockRelayItemClass?departmentLetter=${value}`,
      )
      .then((res) => {
        setIsLoading(false);
        const opt = res.data.map((ele) => {
          return {
            label: ele.className.trim(),
            value: `${ele.departmentLetter}_${ele.classNumber}`,
            name: "class",
          };
        });
        setDropDown((draft) => {
          draft.class = opt;
          draft.subClass = [];
        });
        if (classRef.current) {
          classRef.current.clearValue();
        }
        if (subClassRef.current) {
          subClassRef.current.clearValue();
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);
  const fetchSubClass = useStableCallback((value) => {
    const values = value.split("_");
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/GetStockRelayItemSubClass?departmentLetter=${values[0]}&classId=${values[1]}`,
      )
      .then((res) => {
        setIsLoading(false);
        const opt = res.data.map((ele) => {
          return {
            label: ele.subClassName.trim(),
            value: `${ele.departmentLetter}_${ele.classNumber}_${ele.subClassNumber}`,
            name: "subClass",
          };
        });
        setDropDown((draft) => {
          draft.subClass = opt;
        });
        if (subClassRef.current) {
          subClassRef.current.clearValue();
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    fetchDept();
  }, [storeId, fetchDept]);
  const handleOnchange = (e) => {
    if (e) {
      if (e.name === "dept") {
        fetchClass(e.value);
        setSearchVal("");
      } else if (e.name === "class") {
        fetchSubClass(e.value);
        setSearchVal("");
      } else if (e.name === "subClass") {
        setSearchVal(e.value);
      }
    }
  };

  function handleClear() {
    setDropDown((draft) => {
      draft.class = [];
      draft.subClass = [];
      draft.deptId = "";
      draft.classId = "";
      draft.subClassId = "";
    });
    if (deptRef.current) {
      deptRef.current.clearValue();
    }
    if (classRef.current) {
      classRef.current.clearValue();
    }
    if (subClassRef.current) {
      subClassRef.current.clearValue();
    }
    setData([]);
  }
  function submitSearch() {
    if (!searchVal || searchVal === "") {
      utilities.showToast(
        "You must choose a department and class and subclass.",
        true,
      );
      return;
    }
    const value = searchVal.split("_");
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/PolicyACatagorySearch?departmentLetter=${value[0]}&classId=${value[1]}&subClassId=${value[2]}`,
      )
      .then((res) => {
        if (_.isEmpty(res.data)) {
          utilities.showToast("No Data");
          return;
        }
        setData(res.data);
      })
      .catch(() => {});
  }
  const handleViewItem = useStableCallback((item) => {
    history.push(`/product/${item}`);
  }, []);
  return (
    <>
      {isLoading && <Loader active />}
      <div className="browseCategoryWrapper" ref={divRef}>
        <Form>
          <FormGroup>
            <div className="field customSelectBox six wide">
              <label style={{ display: "block" }}>
                Department:<span className="mandatoryField">*</span>
              </label>
              <WindowedSelect
                options={dropDown.dept}
                onChange={handleOnchange}
                isLoading={isLoading}
                ref={deptRef}
              />
            </div>
            <div className="field customSelectBox  six wide">
              <label style={{ display: "block" }}>
                Class:<span className="mandatoryField">*</span>
              </label>
              <WindowedSelect
                options={dropDown.class}
                onChange={handleOnchange}
                isLoading={isLoading}
                ref={classRef}
                disabled
              />
            </div>
            <div className="field customSelectBox six wide">
              <label style={{ display: "block" }}>
                SubClass:<span className="mandatoryField">*</span>
              </label>
              <WindowedSelect
                options={dropDown.subClass}
                onChange={handleOnchange}
                isLoading={isLoading}
                ref={subClassRef}
              />
            </div>
          </FormGroup>
          <div className="submitClaimBtn">
            <button className="ui button" onClick={handleClear}>
              Clear
            </button>
            <button className="ui button redBtn" onClick={submitSearch}>
              Search
            </button>
          </div>
        </Form>
      </div>

      <InfiniteScrollTable
        tableContent={browseCatTable}
        product={data}
        tableType={"browseCatTable"}
        width={innerWidth}
        viewItem={handleViewItem}
      />
      <p>Search results limited to 2000 rows</p>
    </>
  );
};

export default memo(BrowseCategory);
