import React, { useState, memo, useRef } from "react";
import Webcam from "react-webcam";
import { Modal, ModalContent } from "semantic-ui-react";
import utilities from "../../utils/utilities";

export const CamerCapture = ({
  handleUploadImg,
  disableBtn,
  enableCheckBox = false,
}) => {
  const [showCamera, setShowCamera] = useState(false);
  const videoConstraints = {
    width: 1300,
    facingMode: "environment",
  };
  const webcamRef = useRef(null);
  const closePoup = () => {
    setShowCamera(false);
  };
  const capture = React.useCallback(() => {
    const dataUri = webcamRef.current.getScreenshot();
    const base64String = dataUri.split(",")[1];
    const stringLength = base64String.length;
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    if (sizeInBytes > 5000000) {
      utilities.showToast("Size should not exceed 5MB");
      return;
    }
    let value = {
      imageName: "",
      imageContent: dataUri,
    };
    if (enableCheckBox) {
      value = {
        imageName: "",
        imageContent: dataUri,
        imageType: [],
      };
    }
    handleUploadImg(value);
    setShowCamera(false);
  }, [webcamRef, enableCheckBox, handleUploadImg]);
  return (
    <>
      <div className="uploadBtn">
        <label
          onClick={() => {
            if (disableBtn) {
              return;
            }
            setShowCamera(true);
          }}
          className={disableBtn ? "disableBtn" : ""}
        >
          <p className="ui button redBtn">
            <i className="fal fa-camera"></i>
            <span>Camera</span>
          </p>
        </label>
      </div>

      <Modal open={showCamera}>
        <ModalContent>
          <div className="cameraCaptureClose">
            <span className="closeSearchPoup" onClick={() => closePoup()}>
              <i className="fas fa-times"></i>
            </span>
          </div>
          <div className="webCamDiv">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
            <button className="ui button redBtn" onClick={capture}>
              Capture photo
            </button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(CamerCapture);
