import { useImmer } from "use-immer";
import _ from "lodash";

const useErrorValiadion = (
  intialValue,
  handleOnChangeValiadation,
  handleErrorOnSubmit,
  submitFeedbackForm,
) => {
  const [values, setValues] = useImmer(intialValue);
  const [error, setError] = useImmer({});

  const handleOnChange = (e, data) => {
    const getValue = handleOnChangeValiadation(e, data);
    if (_.isEmpty(getValue)) {
      return;
    } else {
      const key = Object.keys(getValue)[0];
      setValues((draft) => {
        draft[key] = getValue[key].value;
      });
      setError((draft) => {
        draft[key] = getValue[key].error;
      });
    }
  };
  const handleSubmit = () => {
    const getError = handleErrorOnSubmit(values);
    setError(getError);
    if (Object.keys(getError).length === 0) {
      // Proceed with form submission
      console.log("Form is valid:");
      submitFeedbackForm();
    } else {
      console.log("Form has errors:");
    }
  };
  const resetForm = () => {
    setValues(intialValue);
    setError({});
  };
  return {
    values,
    handleOnChange,
    error,
    handleSubmit,
    resetForm,
  };
};

export default useErrorValiadion;
