import React, { memo, useRef, useState } from "react";
import utilities from "../../utils/utilities";
import Resizer from "react-image-file-resizer";
import "./style.scss";
import { Loader } from "semantic-ui-react";

export const UploadDevice = ({
  handleUploadImg,
  disableBtn,
  enableCheckBox = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInput = useRef(null);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1300,
        1300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
      );
    });
  const onChange = async (event) => {
    setIsLoading(true);
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      const base64String = image.split(",")[1];
      const stringLength = base64String.length;
      const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
      if (sizeInBytes > 5000000) {
        utilities.showToast("Size should not exceed 5MB");
        return;
      }
      let values = {
        imageName: "",
        imageContent: image,
      };
      if (enableCheckBox) {
        values = {
          imageName: "",
          imageContent: image,
          imageType: [],
        };
      }
      handleUploadImg(values);
      fileInput.current.value = "";
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      utilities.showToast(" Attachments must be image format", true);
      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading && <Loader active />}
      <div className="uploadBtn">
        {!disableBtn && (
          <input
            type="file"
            name="upload-file"
            id="upload-file"
            onChange={onChange}
            ref={fileInput}
          />
        )}

        <label htmlFor="upload-file" className={disableBtn ? "disableBtn" : ""}>
          <p className="ui button redBtn">
            <i className="fal fa-cloud-upload"></i>
            <span>Choose File </span>
          </p>
        </label>
      </div>
    </>
  );
};

export default memo(UploadDevice);
