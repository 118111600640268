import {
  LOGIN,
  LOGOUT,
  AUTHORIZATION,
  SET_AUTHENTICATION,
  GET_SECRET,
  UPDATERDC,
} from "./actions";

const initState = { isAuthenticated: false };
const SessionReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        userName: payload.username,
        storeId: payload.storeId,
        isAuthenticated: payload.isAuthenticated,
        csr: payload.csr,
        storeguid: payload.storeguid,
        userguid: payload.userguid,
        firstName: payload.firstName,
        lastName: payload.lastName,
      };
    case AUTHORIZATION:
      const { id_token, access_token, expiresAt } = payload;
      return {
        ...state,
        id_token,
        access_token,
        expiresAt,
        isAuthenticated: true,
      };
    case UPDATERDC:
      const { rdc } = payload;
      return {
        ...state,
        rdc,
      };
    case SET_AUTHENTICATION:
      return { ...state, isAuthenticated: payload.isAuthenticated };
    case LOGOUT:
      return {
        isAuthenticated: false,
      };
    case GET_SECRET:
      return {
        ...state,
        secretKey: payload,
      };
    default:
      return state;
  }
};

export default SessionReducer;
