import React, { useState, memo } from "react";
import CustomTable from "../CustomTable";
import { loadFeedBackItemDetailsTable } from "../common/TableConstant";
import { ViewImageGallery } from "./ImageGallery";
import useStableCallback from "../common/CustomHook/useStableCallback";
export const FeedbackHistoryDetails = (props) => {
  const { item, id } = props;
  const [viewImagePopup, setViewImagePopup] = useState(false);
  const [images, setImages] = useState([]);
  const openViewImage = useStableCallback(
    (item) => {
      let generateImg = item.imageDetails.map((ele) => {
        const imgUrl = ele.imageObject_URL.split("&hash")[0];
        return {
          original: imgUrl,
          thumbnail: imgUrl,
          originalHeight: 500,
          thumbnailHeight: 75,
        };
      });
      setImages(generateImg);
      setViewImagePopup(true);
    },
    [viewImagePopup],
  );
  const closePopUp = useStableCallback(() => {
    setViewImagePopup(false);
  }, [viewImagePopup]);
  return (
    <div>
      <p className="closePopupIcon">
        <span
          className="closeSearchPoup"
          onClick={() => props.closeDetailsPopup()}
        >
          <i className="fas fa-times"></i>
        </span>
      </p>
      <div className="detailsBlock">
        <CustomTable
          product={[item]}
          tableType="loadFeedbackDetails"
          heading={"Load Feedback History Details"}
          tableContent={loadFeedBackItemDetailsTable}
          id={id}
          viewImgPopup={openViewImage}
        />
      </div>
      {viewImagePopup && (
        <ViewImageGallery closePopUp={closePopUp} images={images} />
      )}
    </div>
  );
};

export default memo(FeedbackHistoryDetails);
