import React, { useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import { CLAIMS_HISTORY, CLAIMS_BASE_API } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import BreadcrumbComp from "../common/BreadcrumbComp";
import { Loader } from "semantic-ui-react";
import { CustomTable } from "../CustomTable";
import { rfaClaimEditTable } from "../common/TableConstant";
import _ from "lodash";
import useStableCallback from "../common/CustomHook/useStableCallback";
import history from "../../history";
import utilities from "../../utils/utilities";
import "./style.scss";
const EditClaim = (props) => {
  const { storeId, userName, firstName, lastName } = useSelector(
    (state) => state.SessionReducer,
  );
  const claimNumber = props?.location?.state?.claimNumber
    ? props?.location?.state?.claimNumber
    : props?.claimNumber
      ? props?.claimNumber
      : null;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState([]);
  const getEditData = useStableCallback(() => {
    setIsLoading(true);
    restUtils
      .getDataWithBearer(
        `${CLAIMS_HISTORY}/${storeId}/GetRFAEditItems?claimNumber=${claimNumber}`,
      )
      .then((res) => {
        console.log(res);
        setData(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [storeId, claimNumber]);
  useEffect(() => {
    getEditData();
  }, [claimNumber, storeId, getEditData]);
  const handleDeleteItem = useStableCallback(
    (e, data) => {
      const { value } = data;
      let items = deleteItem.includes(value)
        ? deleteItem.filter((item) => item !== value)
        : [...deleteItem, value];
      setDeleteItem(items);
    },
    [deleteItem],
  );
  const deleteItemFun = () => {
    let requestPayLoad = deleteItem.map((itemNumber) => {
      const item = data.find((ele) => ele.itemNumber === itemNumber);
      return {
        storeNumber: storeId,
        invoiceNumberRDC: item.invoiceNumber,
        itemNumberRlyBk: item.itemNumber,
        reasonId: 0,
        pack: "",
        qty: item.claimQty,
        comments: "",
        rowId: 0,
        claimNumber: item.claimNumber,
        delFlag: "D",
        poNumber: item.poNumber,
        subReasonId: item.subReasonId,
        shipmentType: "",
        claimType: "",
        claimToGet: "",
        claimItemSessionId: 0,
        subReason: "",
        reason: "",
        createdBy: `${firstName} ${lastName} (${userName.substr(1)})`,
      };
    });
    setIsLoading(true);
    restUtils
      .postDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/SubmitRFAClaims`,
        requestPayLoad,
      )
      .then((res) => {
        history.push({
          pathname: "/claimDeletedItem",
          state: {
            data: res.data,
          },
        });
        getEditData();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        utilities.showToast("Error");
      });
  };
  return (
    <>
      {isLoading && (
        <div className="commonLoader">
          <Loader active></Loader>
        </div>
      )}
      <div className="claimHistoryWrapper">
        <h3>Edit Claim Item</h3>
        <BreadcrumbComp
          path={[
            { link: "/", text: "Home" },
            { link: "/ClaimsHistory", text: "Claim History" },
            { link: "/claimsDetails", text: "Edit Claim Item", active: true },
            { link: "/", text: "Inactive" },
          ]}
        ></BreadcrumbComp>
        <div className="rfaClaimEditWrapper">
          {!_.isEmpty(data) && (
            <>
              <div className="deleteItemDetails">
                {deleteItem.length > 0 && (
                  <p>
                    Selected Item For Delete:
                    {deleteItem.map((item) => {
                      return <span>{item}</span>;
                    })}
                  </p>
                )}
                <button
                  className="ui button redBtn"
                  disabled={deleteItem.length === 0 ? true : false}
                  onClick={deleteItemFun}
                >
                  Delete
                </button>
              </div>
              <CustomTable
                product={data}
                tableType="rfaClaimEditTable"
                tableContent={rfaClaimEditTable}
                handleDeleteItem={handleDeleteItem}
                deleteItem={deleteItem}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(EditClaim);
