import React, { useState } from "react";
import { downloadExcel } from "./GenericExcelExport/exportUtils";
import { Loader, Popup } from "semantic-ui-react";
import utilities from "../../utils/utilities";
import { FEEDBACK_HISTORY } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import { useSelector } from "react-redux";
const ExportExcel = (props) => {
  const { storeId } = useSelector((state) => state.SessionReducer);
  const [loading, setLoading] = useState(false);
  const exportToCSV = (props) => {
    const { name } = props;
    let productsData = [];
    let url = "";
    let fileName = `${name}-${utilities.getCurrDate()}.xlsx`;
    utilities.showToast("Exporting to Excel...");
    let row = {};
    if (name === "loadFeedback") {
      url = `${FEEDBACK_HISTORY}?storeNumber=${storeId}`;
      setLoading(true);
      restUtils
        .getDataWithBearer(url)
        .then((response) => {
          setLoading(false);
          let data = [];
          data = response.data;
          data.map((col, index) => {
            row = {
              ...row,
              "Load Feedback Nbr": col.loadFeedback_Nbr,
              "CRM Number": col.crM_Nbr,
              "Invoice Number": col.invoice_Nbr,
              "Created Date": col.created_Date,
            };
            productsData.push(row);
            return true;
          });
        })
        .catch((err) => {
          setLoading(false);
          return false;
        });
      downloadExcel(productsData, fileName);
    } else if (name === "loadFeedbackHistoryDetails") {
      props.data.map((col) => {
        row = {
          ...row,
          "Load Feedback Nbr": col.loadFeedback_Nbr,
          "Invoice Number": col.invoice_Nbr,
          "CRM Nbr": col.crM_Nbr,
          "Created Date": col.created_Date,
          "Feedback/Comment": col.loadFeedback_Comments,
        };
        productsData.push(row);
        return true;
      });
      downloadExcel(productsData, fileName);
    } else if (name === "ClaimsHistoryDetails") {
      if (props.isPolicyA) {
        props.data.map((col) => {
          row = {
            ...row,
            "Store #": storeId,
            "Claim #": props?.claimData.claimNumber,
            "Type #": col.item_Type,
            "Invoice #": col.invoice_Number,
            "Item #": col.item_Nbr,
            Qty: col.quantity,
            "Retrun Reason": `${col.reason}-${col.subReason}`,
            "Short Description": col.short_Desc,
            "MFR/Vendor": col.vendor_Name,
            "Model #": col.model_Nbr,
            Comment: col.comments,
            "Claim Value": utilities.roundWithdollar(col.claim_Value),
            "Claim Status": col.statusDisplay,
            "Total Claim Value": props?.policyATotalValue,
          };
          productsData.push(row);
          return true;
        });
      } else {
        props.data.map((col, index) => {
          row = {
            ...row,
            "Store #": storeId,
            "Claim #": props?.claimData.claimNumber,
            "Claim Date": props?.claimData.claimDate,
            "Claim Time": props?.claimData.claimTime,
            Origin: props?.claimData.claimOrigin,
            "Invoice #": col.invoiceNumber,
            "Item #": col.itemNumber,
            Status: col.status,
            "Retrun Reason": col.returnReason,
            Description: col.description,
            Pack: col.pack,
            "Claim Qty": col.claimQty,
            "Qty Apprvd": col.qtyAppeared,
            "Invoice Cost": utilities.roundWithdollar(col.mbrCost),
            "Calc Rstck Chrg": col.calcRstckChrg,
            "Calc Line Amt": utilities.roundWithdollar(col.calcLineAmt),
            "Date Billed": col.billedDate,
            Remarks: col.remarks,
          };
          productsData.push(row);
          return true;
        });
      }

      downloadExcel(productsData, fileName);
    } else if (name === "serialNumberDetails") {
      props.data.map((col, index) => {
        row = {
          ...row,
          "Serial No": col.serial_nbr,
          "Warranty Reason": col.warrantyDescription,
          "Return Reason": col.returnReason,
        };
        productsData.push(row);
        return true;
      });

      downloadExcel(productsData, fileName);
    }
  };
  return (
    <>
      {loading && (
        <div className="exportLoader">
          <Loader active />
        </div>
      )}
      <span className={props.className}>
        <Popup
          trigger={
            <span
              className="fas fa-file-excel"
              onClick={() => exportToCSV(props)}
            ></span>
          }
          content="Export Excel"
          size="mini"
          position="top right"
          basic
        />
      </span>
    </>
  );
};

export default ExportExcel;
