import config from "./config";
import pdfLinkDev from "./components/NavBar/qr-code-development.pdf";
import pdfLinkUat from "./components/NavBar/qr-code-uat.pdf";
import pdfLinkProd from "./components/NavBar/qr-code-production.pdf";
const getEnv = () => {
  const environments = {
    development: "dev",
    QA: "qa",
    production: "prod",
    uat: "uat",
  };
  return environments[config.env];
};

export const getRedirectUri = () => {
  const environments = {
    development: "https://claimscustexp-ui-dev.truevalue.com",
    uat: "https://claimscustexp-ui-uat.truevalue.com",
    production: "https://claimscustexp.truevalue.com",
  };
  return environments[config.env];
};

export const getRedirectUriNWH = () => {
  const environments = {
    development: "https://nwa-dev.truevalue.com",
    QA: "https://nwa-qa.truevalue.com",
    uat: "https://nwa-uat.truevalue.com",
    production: "https://nwa.truevalue.com",
  };
  return environments[config.env];
};
export const getRedirectUriMOL = () => {
  const environments = {
    development: "https://dev.membersonline.com/mol2/website/Account/Login",
    QA: "https://qa.membersonline.com/mol2/website/Account/Login",
    uat: "https://uat.membersonline.com/mol2/website/Account/Login",
    production: "https://www.membersonline.com/mol2/website/Account/Login",
  };
  return environments[config.env];
};
export const getDomain = () => {
  const domainUrls = {
    uat: "uat.membersonline.com/mol2/website/identity/connect/",
    QA: "qa.membersonline.com/mol2/website/identity/connect/",
    development: "dev.membersonline.com/mol2/website/identity/connect/",
    production: "www.membersonline.com/mol2/website/identity/connect/",
  };
  return domainUrls[config.env];
};
export const getPolicyBLink = () => {
  const domainUrls = {
    uat: "https://molapps-uat.truevalue.com/Policy_B/Default.aspx",
    QA: "https://molapps-uat.truevalue.com/Policy_B/Default.aspx",
    development: "https://molapps-uat.truevalue.com/Policy_B/Default.aspx",
    production: "https://apps.membersonline.com/Policy_B/Default.aspx",
  };
  return domainUrls[config.env];
};

export const getBaseApiUrl = () => {
  const environments = {
    development:
      "https://claimscustexp-api-dev.truevalue.com/api/claimsbaseapi",
    QA: "https://claimscustexp-api-dev.truevalue.com/api/claimsbaseapi",
    uat: "https://claimscustexp-api-uat.truevalue.com/api/claimsbaseapi",
    production: "https://claimscustexp-api.truevalue.com/api/claimsbaseapi",
  };
  return environments[config.env];
};
export const getCloudRunApi = () => {
  const environments = {
    development: "https://nw-api-dev.truevalue.com",
    QA: "https://nw-api-qa.truevalue.com",
    uat: "https://nw-api-uat.truevalue.com",
    production: "https://nw-api.truevalue.com",
  };
  return environments[config.env];
};
export const getMolInfoApi = () => {
  const environments = {
    development: `${getCloudRunApi()}/api/molbaseapis/MolInfo`,
    QA: `${getCloudRunApi()}/api/molbaseapis/MolInfo`,
    uat: `${getCloudRunApi()}/api/molbaseapis/MolInfo`,
    production: `${getCloudRunApi()}/api/molbaseapis/MolInfo`,
  };
  return environments[config.env];
};
export const GET_USER_INFO = `${getMolInfoApi()}/GetUserInfo`;
export const GET_STOREINFO = `${getMolInfoApi()}/GetStoreInfo`;
export const JWKS_URI = () => {
  const environments = {
    development:
      "https://dev.membersonline.com/mol2/website/identity/.well-known/jwks",
    uat: "https://uat.membersonline.com/mol2/website/identity/.well-known/jwks",
    QA: "https://qa.membersonline.com/mol2/website/identity/.well-known/jwks",
    production:
      "https://www.membersonline.com/mol2/website/identity/.well-known/jwks",
  };
  return environments[config.env];
};

export const issuer = () => {
  const environments = {
    development: "https://dev.membersonline.com/mol2/website/identity",
    uat: "https://uat.membersonline.com/mol2/website/identity",
    QA: "https://qa.membersonline.com/mol2/website/identity",
    production: "https://membersonline.com/mol2/website/identity",
  };
  return environments[config.env];
};

export const audience = () => {
  const environments = {
    development:
      "https://dev.membersonline.com/mol2/website/identity/resources",
    uat: "https://uat.membersonline.com/mol2/website/identity/resources",
    QA: "https://qa.membersonline.com/mol2/website/identity/resources",
    production: "https://membersonline.com/mol2/website/identity/resources",
  };
  return environments[config.env];
};
export const pdfLink = () => {
  const environments = {
    development: pdfLinkDev,
    uat: pdfLinkUat,
    production: pdfLinkProd,
  };
  return environments[config.env];
};
export const MULTIPLE_ORDER_PAD_ITEMS =
  "https://3ai3h4if2l.execute-api.us-east-2.amazonaws.com/" + getEnv();

export const PRODUCT_DETAILS =
  "https://k50tuskmge.execute-api.us-east-2.amazonaws.com/" +
  getEnv() +
  "?itemId=";

export const userObjectUrl =
  "https://glz9ml2jek.execute-api.us-east-2.amazonaws.com/" +
  getEnv() +
  "/PostRetrieveNWUser";

export const HISTORYDATA =
  "https://2c508aikde.execute-api.us-east-2.amazonaws.com/" +
  getEnv() +
  "/GetAllOrdersByStoreId";

export const GET_LOAD_FEEDBACK_API = `${getBaseApiUrl()}/LoadFeedback/Member`;

export const GET_ITEM_PACK = `${getBaseApiUrl()}/Claims/GetItemPackDetails`;

export const FEEDBACK_HISTORY = `${getBaseApiUrl()}/LoadFeedback/Member`;

export const CLAIMS_BASE_API = `${getBaseApiUrl()}/Claims/Member`;

export const CLAIMS_HISTORY = `${getBaseApiUrl()}/ClaimsHistory/Member`;
