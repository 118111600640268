import React, { useState, useEffect } from "react";
import {
  Loader,
  FormGroup,
  FormSelect,
  Input,
  Popup,
  Pagination,
  Modal,
  ModalContent,
} from "semantic-ui-react";
import { useImmer } from "use-immer";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "./style.scss";
import BreadcrumbComp from "../common/BreadcrumbComp";
import useStableCallback from "../common/CustomHook/useStableCallback";
import { useSelector } from "react-redux";
import utilities from "../../utils/utilities";
import { CLAIMS_HISTORY } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import _ from "lodash";
import CustomTable from "../CustomTable";
import { claimHistoryTable } from "../common/TableConstant";
import history from "../../history";

const sortOpt = [
  { key: "claimdate", text: "Claim Date", value: "claimdate" },
  { key: "claimtime", text: "Claim Time", value: "claimtime" },
  { key: "crmNumber", text: "Claim Number", value: "claimnumber" },
  { key: "claimorigin", text: "Claim Origin", value: "claimorigin" },
  { key: "claimvalue", text: "Claim Value", value: "claimvalue" },
  { key: "claimstatus", text: "Claim Status", value: "claimstatus" },
  { key: "crmnumber", text: "CRM Number", value: "crmnumber" },
  { key: "createdBy", text: "Created By", value: "createdby" },
];

const searchOpt = [
  { key: "claim", text: "Claim Number", value: "claimnumber" },
  { key: "itemNbr", text: "Item Number", value: "itemNumber" },
  { key: "poNum", text: "Po Number", value: "ponumber" },
  { key: "crmnumber", text: "CRM Number", value: "crmnumber" },
  { key: "createdDate", text: "Created Date", value: "claimdate" },
  { key: "createdBy", text: "Created By", value: "createdby" },
];

const sortOrder = [
  { key: "asc", text: "Ascending", value: "asc" },
  { key: "desc", text: "Descending", value: "desc" },
];

const ClaimsHistory = () => {
  let date = new Date();
  date.setDate(date.getDay() - 90);
  const [noData, setNoData] = useState(false);
  const { storeId } = useSelector((state) => state.SessionReducer);
  const [searchDate, setSearchDate] = useImmer({
    fromDate: "",
    toDate: "",
  });
  const [searchBy, setSearchBy] = useImmer({
    label: "",
    value: "",
  });
  const [pagination, setPagination] = useImmer({
    currentPage: 1,
    totalPages: 1,
    totalCount: "",
    firstItem: "",
    lastItem: "",
  });
  const [data, setData] = useState([]);
  const [showDeletePopUp, setShowDeletePopup] = useImmer({
    show: false,
    claim: null,
  });
  const [sorting, setSorting] = useImmer({
    sortBy: "claimdate",
    order: "desc",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchBy = (e, data) => {
    if (data.name === "label") {
      setSearchBy((draft) => {
        draft.label = data.value;
        draft.value = "";
      });
      return;
    } else {
      setSearchBy((draft) => {
        draft[data.name] = data.value;
      });
    }
  };

  const getHistoryData = useStableCallback(
    (claimNumber = false, reset = false, fromPagination = null) => {
      let requestPayLoad = {
        displayLength: 50,
        displayPage: fromPagination ? fromPagination : "1",
        sortColumn: sorting.sortBy,
        sortDirection: sorting.order,
        searchBy: reset ? "" : searchBy.label,
        searchValue: reset ? "" : searchBy.value,
        fromDate: !reset
          ? utilities.getDateFromTimeStampShip(searchDate.fromDate)
          : "",
        toDate: !reset
          ? utilities.getDateFromTimeStampShip(searchDate.toDate)
          : "",
        delFlag: "N",
      };
      if (claimNumber) {
        requestPayLoad = {
          ...requestPayLoad,
          delFlag: "D",
          searchBy: "claimnumber",
          searchValue: claimNumber,
          displayPage: "1",
        };
      }
      setIsLoading(true);
      restUtils
        .postDataWithBearer(
          `${CLAIMS_HISTORY}/${storeId}/GetClaimsHistory`,
          requestPayLoad,
        )
        .then((res) => {
          setData(res.data.data);
          setIsLoading(false);
          if (_.isEmpty(res.data)) {
            setNoData(true);
            return;
          }
          setNoData(false);
          setData(res.data.data);
          setPagination((draft) => {
            draft.totalPages = Math.ceil(res.data.iTotalRecords / 50);
            draft.totalCount = res.data.iTotalRecords;
            draft.firstItem = res.data.firstItem;
            draft.lastItem = res.data.lastItem;
          });
          if (claimNumber) {
            setShowDeletePopup((draft) => {
              draft.show = false;
              draft.claim = null;
            });
            setPagination((draft) => {
              draft.currentPage = "1";
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (claimNumber) {
            setShowDeletePopup((draft) => {
              draft.show = false;
              draft.claim = null;
            });
          }
          utilities.showToast("Error", true);
          setIsLoading(false);
        });
    },
    [],
  );
  const handleSearchSubmit = () => {
    const { value } = searchBy;

    if (value === "") {
      utilities.showToast("Please Enter The Search Value", true);
      return;
    }
    setPagination((draft) => {
      draft.currentPage = "1";
    });
    getHistoryData();
  };
  const handleSearchDateSubmit = () => {
    const { fromDate, toDate } = searchDate;
    if (fromDate === "" || toDate === "") {
      utilities.showToast("Please Enter Valid Date", true);
      return;
    }
    setPagination((draft) => {
      draft.currentPage = "1";
    });
    getHistoryData();
  };
  const handleSearchDate = (e, data) => {
    setSearchDate((draft) => {
      draft[data.name] = data.value;
    });
  };
  const handleSearchClear = () => {
    setSearchBy((draft) => {
      draft.label = "";
      draft.value = "";
    });
    setSearchDate((draft) => {
      draft.fromDate = "";
      draft.toDate = "";
    });
    setPagination((draft) => {
      draft.currentPage = "1";
    });
    getHistoryData(false, true);
  };
  const handleSort = (e, data) => {
    setSorting((draft) => {
      draft[data.name] = data.value;
    });
  };
  useEffect(() => {
    getHistoryData(false, false, pagination.currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, getHistoryData]);
  useEffect(() => {
    getHistoryData();
  }, [storeId, getHistoryData]);

  const onpageClick = useStableCallback(
    (e) => {
      let queryPage = e.currentTarget.text;
      let { totalPages, currentPage } = pagination;
      if (e.currentTarget.text === ">") {
        queryPage = currentPage < totalPages ? currentPage + 1 : currentPage;
      }
      if (e.currentTarget.text === "<") {
        queryPage = currentPage === 1 ? 1 : currentPage - 1;
      }
      if (e.currentTarget.text === "»") {
        queryPage = totalPages;
      }
      if (e.currentTarget.text === "«") {
        queryPage = 1;
      }
      setPagination((draft) => {
        draft.currentPage = queryPage;
      });
      //this.scrollPosition = window.pageYOffset;
      window.scrollTo(0, 0);
      getHistoryData(false, false, queryPage);
    },
    [pagination],
  );
  const claimsDetails = (claim) => {
    history.push({
      pathname: "/claimsDetails",
      state: {
        claimData: claim,
      },
    });
  };
  const handleEdit = (claim) => {
    history.push({
      pathname: "/editClaim",
      state: {
        claimNumber: claim.claimNumber,
      },
    });
  };
  const handleDelete = (claim) => {
    setShowDeletePopup((draft) => {
      draft.show = true;
      draft.claim = claim;
    });
  };
  function yesDelete() {
    getHistoryData(showDeletePopUp.claim.claimNumber);
  }
  function noDelete() {
    setShowDeletePopup((draft) => {
      draft.show = false;
      draft.claim = null;
    });
  }
  return (
    <>
      {isLoading && (
        <div className="commonLoader">
          <Loader active></Loader>
        </div>
      )}
      <div className="claimHistoryWrapper">
        <h3>Claim History</h3>
        <BreadcrumbComp
          path={[
            { link: "/", text: "Home" },
            { link: "/ClaimsHistory", text: "Claim History", active: true },
            { link: "/", text: "Inactive" },
          ]}
        ></BreadcrumbComp>
        <div className="searchDataWrapper">
          <div className="searchTypeDiv">
            <FormGroup>
              <FormSelect
                label="Search By"
                name="label"
                placeholder="Select Search Type "
                value={searchBy.label}
                options={searchOpt}
                width={5}
                required
                onChange={handleSearchBy}
              />
              <div className="loadFeedbackSearchBlock">
                {searchBy.label === "claimdate" ? (
                  <>
                    <SemanticDatepicker
                      name="fromDate"
                      onChange={handleSearchDate}
                      id="startDatePicker1"
                      maxDate={new Date()}
                    />
                    <SemanticDatepicker
                      name="toDate"
                      onChange={handleSearchDate}
                      id="startDatePicker2"
                      minDate={
                        searchDate.fromDate !== "" && searchDate.fromDate
                      }
                      maxDate={new Date()}
                    />
                    <span
                      className="loadFeedbackSearchIcon"
                      onClick={handleSearchDateSubmit}
                    >
                      <i className="fa-search icon icon"></i>
                    </span>
                  </>
                ) : (
                  <>
                    <Input
                      placeholder=""
                      value={searchBy.value}
                      name={"value"}
                      onChange={handleSearchBy}
                      width={5}
                      disabled={_.isEmpty(searchBy.label)}
                    />
                    <span
                      className="loadFeedbackSearchIcon"
                      onClick={handleSearchSubmit}
                    >
                      {" "}
                      <i className="fa-search icon icon"></i>
                    </span>
                  </>
                )}
                <Popup
                  trigger={
                    <span
                      className="loadFeedbackSearchIcon clearIcon"
                      onClick={handleSearchClear}
                    >
                      <i className="fas fa-times"></i>
                    </span>
                  }
                  content="Clear Search"
                  size="mini"
                  position="top right"
                  basic
                ></Popup>
              </div>
            </FormGroup>
          </div>
          <div className="sortTypeDiv">
            <FormGroup>
              <FormSelect
                label="Sort By"
                name="sortBy"
                value={sorting.sortBy}
                options={sortOpt}
                width={5}
                onChange={handleSort}
                required
              />
              <FormSelect
                name="order"
                value={sorting.order}
                options={sortOrder}
                width={5}
                onChange={handleSort}
                required
              />
            </FormGroup>
          </div>
        </div>
        {!noData ? (
          <>
            <p className="itemCountInfo">
              <span>{pagination.firstItem}</span>- {pagination.lastItem}{" "}
              <span>of {pagination.totalCount} Items</span>
            </p>
            <CustomTable
              product={data}
              tableType="loadFeedback"
              tableContent={claimHistoryTable}
              claimsDetails={claimsDetails}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
            <div className="paginationBlock">
              <Pagination
                className="Pagination"
                activePage={pagination.currentPage}
                onPageChange={(e) => onpageClick(e)}
                firstItem={
                  pagination.currentPage === 1
                    ? { content: <span className="disabled">«</span> }
                    : { content: "«" }
                }
                lastItem={
                  pagination.currentPage === pagination.totalPages
                    ? { content: <span className="disabled">»</span> }
                    : { content: "»" }
                }
                prevItem={
                  pagination.currentPage === 1
                    ? { content: <span className="disabled">{"<"}</span> }
                    : { content: "<" }
                }
                nextItem={
                  pagination.currentPage === pagination.totalPages
                    ? { content: <span className="disabled">{">"}</span> }
                    : { content: ">" }
                }
                totalPages={pagination.totalPages}
                pointing
                secondary
              />
            </div>
          </>
        ) : (
          <p className="noData">No Item to Display</p>
        )}
        {showDeletePopUp.show && (
          <Modal className="deleteItemPopup" open={showDeletePopUp.show}>
            <ModalContent>
              <h4>Are you sure delete the item ?</h4>
              <div>
                <button className="ui button" onClick={noDelete}>
                  No
                </button>
                <button className="ui button redBtn" onClick={yesDelete}>
                  Yes
                </button>
              </div>
            </ModalContent>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ClaimsHistory;
