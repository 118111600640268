import { persistor } from "../../configureStore";
import { JWKS_URI } from "../../urlConstants";
import Axios from "axios";
import utilities from "../../utils/utilities";

export const LOGIN = "LOGIN_ACTION";
export const AUTHORIZATION = "AUTHORIZATION";
export const GET_SECRET = "GET_SECRET";
export const LOGOUT = "LOGOUT";
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";
export const UPDATERDC = "UPDATERDC";

export const setAuthentication = (isAuthenticated) => ({
  type: SET_AUTHENTICATION,
  payload: {
    isAuthenticated,
  },
});

export const login = (
  username,
  storeId,
  isAuthenticated,
  csr,
  userguid,
  storeguid,
  firstName,
  lastName,
) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN,
      payload: {
        username,
        storeId,
        isAuthenticated,
        csr,
        userguid,
        storeguid,
        firstName,
        lastName,
      },
    });
  };
};

export const authorizeUser = (id_token, access_token, expires_at) => ({
  type: AUTHORIZATION,
  payload: {
    id_token,
    access_token,
    expires_at,
  },
});

export const updateRdc = (rdc) => ({
  type: UPDATERDC,
  payload: {
    rdc,
  },
});

export const getSecretKey = () => {
  const jwks_url = JWKS_URI();
  return (dispatch) => {
    Axios.get(jwks_url).then((response) => {
      dispatch({
        type: GET_SECRET,
        payload: response.data.keys[0].n,
      });
    });
  };
};
export const logout = () => {
  return (dispatch) => {
    sessionStorage.clear();
    localStorage.clear();
    utilities.cookieClear();
    persistor.purge().then(() => {
      return dispatch({ type: LOGOUT });
    });
  };
};

export const retrunToMol = (url) => {
  return () => {
    localStorage.clear();
    sessionStorage.clear();
    utilities.cookieClear();
    persistor.purge().then(() => {
      window.location.replace(url);
    });
  };
};
