import restUtils from "../../utils/restUtils";
import utilities from "../../utils/utilities";
export const quantityValidator = (quantity, allowZero = false) => {
  if (quantity !== "") {
    const regularExpression = /^\d+$/;
    if (!regularExpression.test(quantity)) {
      return false;
    }
    const quantityValue = parseFloat(quantity);
    if (quantityValue <= 0) {
      if (allowZero) {
        return true;
      }
      utilities.showToast(`Quantity should be positive number!`, false);
      return false;
    }

    if (quantityValue > 9999) {
      utilities.showToast(`Quantity should not exceed 9999!`, false);
      return false;
    }
  }

  return true;
};
const handleUploadImageToGcp = (imageData) =>{
  if(!imageData) {
    return
  }
  return imageData.map(image=>{
    if(image.imageContent === '') {
      return  null
     }
      const data = atob(image.imageContent.split(',')[1]);
      const bytes = Uint8Array.from(data, b => b.charCodeAt(0));
      const blob = new Blob([bytes], { type: 'image/jpeg' });
      return fetch(image.url, {
        method: "PUT",
        headers: {
          "Content-Type": 'image/jpeg',
        },
        body: blob, // the file blob
      });
  })
 
}
 export const uploadImagesToGcp = async (imageObjectNames, url) =>{
  const name = imageObjectNames.map(ele=> ele.imageName)
    let signUrls = await restUtils.postDataWithBearer(url, name).then((res)=>{
      return res.data.map((ele,index)=>{
         return {
              url:ele.signedImageObjectURL.split("&hash")[0],
              imageContent:imageObjectNames[index].imageContent
         }
       })
      }).catch(err=>{
        console.log(err)
        return null
      })
   return signUrls ? handleUploadImageToGcp(signUrls) :  null
}