import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  showLoader: false,
};

export const UtilityReducer = createSlice({
  name: "UtilityReducer",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.showLoader = action.payload;
    },
  },
});

export const { setLoader } = UtilityReducer.actions;

export const setCommonLoader = (payload) => (dispatch) => {
  dispatch(setLoader(payload));
};

export default UtilityReducer.reducer;
