import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CLAIMS_HISTORY } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import BreadcrumbComp from "../common/BreadcrumbComp";
import { Loader, Modal, ModalContent } from "semantic-ui-react";
import { CustomTable } from "../CustomTable";
import {
  claimHistoryDetailsTable,
  claimItemDetailsTable,
  policyAclaimHistoryDetailsTable,
} from "../common/TableConstant";
import { useImmer } from "use-immer";
import _ from "lodash";
import useStableCallback from "../common/CustomHook/useStableCallback";
import { ViewImageGallery } from "../LoadFeedback/ImageGallery";
const imageType = {
  1: "Crushed",
  2: "Bent",
  3: "Expired",
  4: "Dent",
};
const ClaimsDetails = (props) => {
  const { storeId } = useSelector((state) => state.SessionReducer);
  const claimData = props?.location?.state?.claimData
    ? props?.location?.state?.claimData
    : props?.claimData
      ? props?.claimData
      : null;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewImagePopup, setViewImagePopup] = useState(false);
  const [images, setImages] = useState([]);
  const [totalClaimValue, setTotalClaimValue] = useState("");
  const [serialNumberDetails, setSerialNumberDetails] = useState([]);
  const [itemDetailsPopup, setItemDetailsPopup] = useImmer({
    show: false,
    data: {},
  });
  useEffect(() => {
    let url = `${CLAIMS_HISTORY}/${storeId}/GetRFAClaimDetails?claimNumber=${claimData.claimNumber}`;
    if (claimData.claimType === "PolicyA") {
      url = `${CLAIMS_HISTORY}/${storeId}/GetPolicyAClaimDetails?claimNumber=${claimData.claimNumber}`;
    }
    setIsLoading(true);
    restUtils
      .getDataWithBearer(url)
      .then((res) => {
        let data = res.data;
        if (claimData.claimType === "PolicyA") {
          data = res.data.policyAHistoryClaimDetails;
          setTotalClaimValue(res.data.totalClaimValue);
          setSerialNumberDetails(res.data.serialNumberDetails);
        }
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [claimData, storeId]);
  const openViewImage = useStableCallback(
    (item) => {
      if(_.isEmpty(item.imageDetails)) {
          setImages([]);
         setViewImagePopup(true);
        return
      }
      let generateImg = item.imageDetails.map((ele) => {
        const imgUrl = ele.imageObject_URL.split("&hash")[0];
        let status = [];
        !_.isEmpty(ele.image_Damage_Type) &&
          ele.image_Damage_Type.split(",").forEach((ele) => {
            status.push(imageType[ele]);
          });
        return {
          original: imgUrl,
          thumbnail: imgUrl,
          originalHeight: 500,
          thumbnailHeight: 75,
          description: status.join(","),
        };
      });
      setImages(generateImg);
      setViewImagePopup(true);
    },
    [viewImagePopup],
  );
  const handleItemDetailsPopup = (item) => {
    setItemDetailsPopup((draft) => {
      draft.show = true;
      draft.data = item;
    });
    //restUtils.getDataWithBearer(`${CLAIMS_HISTORY}/${storeId}`)
  };
  const closePopUp = useStableCallback(() => {
    setViewImagePopup(false);
  }, [viewImagePopup]);

  const closeDetailsPoup = useStableCallback(() => {
    setItemDetailsPopup((draft) => {
      draft.show = false;
      draft.data = {};
    });
  }, [itemDetailsPopup]);
  return (
    <>
      {isLoading && (
        <div className="commonLoader">
          <Loader active></Loader>
        </div>
      )}
      <div className="claimHistoryWrapper">
        <h3>Claim History Details</h3>
        <BreadcrumbComp
          path={[
            { link: "/", text: "Home" },
            { link: "/ClaimsHistory", text: "Claim History" },
            {
              link: "/claimsDetails",
              text: "Claim History Detail",
              active: true,
            },
            { link: "/", text: "Inactive" },
          ]}
        ></BreadcrumbComp>
        <div className="claimHistoryDetails">
          <CustomTable
            product={data}
            tableType={
              claimData.claimType === "PolicyA"
                ? "policyAclaimHistoryDetails"
                : "claimHistoryDetails"
            }
            tableContent={
              claimData.claimType === "PolicyA"
                ? policyAclaimHistoryDetailsTable
                : claimHistoryDetailsTable
            }
            viewClaimsImgPopup={openViewImage}
            itemDetailsPopup={handleItemDetailsPopup}
            claimData={claimData}
            isPolicyA={claimData.claimType === "PolicyA" ? true : false}
            policyATotalValue={totalClaimValue}
            serialNumberDetails={serialNumberDetails}
          />
        </div>
      </div>
      {viewImagePopup && (
        <ViewImageGallery closePopUp={closePopUp} images={images} />
      )}
      {itemDetailsPopup.show && !_.isEmpty(itemDetailsPopup.data) && (
        <Modal className="claimItemDetailsPopup" open={true}>
          <ModalContent>
            <div className="closeSearchPoup">
              <span onClick={closeDetailsPoup}>
                <i className="fas fa-times"></i>
              </span>
            </div>
            <CustomTable
              product={[itemDetailsPopup.data]}
              tableType="claimItemDetailsTable"
              tableContent={claimItemDetailsTable}
              claimData={claimData}
            />
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ClaimsDetails;
