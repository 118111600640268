import React, { useState, useEffect, useRef } from "react";
import {
  Loader,
  FormGroup,
  Form,
  FormField,
  Radio,
  Modal,
  ModalContent,
} from "semantic-ui-react";
import { useImmer } from "use-immer";
import UploadImage from "../UploadImage";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "./style.scss";
import BreadcrumbComp from "../common/BreadcrumbComp";
import useStableCallback from "../common/CustomHook/useStableCallback";
import { useSelector } from "react-redux";
import utilities from "../../utils/utilities";
import { GET_LOAD_FEEDBACK_API, FEEDBACK_HISTORY } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import _ from "lodash";
import WindowedSelect from "react-windowed-select";
import useErrorValiadion from "../common/CustomHook/useErrorValidation";
import moment from 'moment-timezone';
import { v4 as uuid } from "uuid";
import {  uploadImagesToGcp } from "../common/productHandler";
const LoadFeedback = () => {
  let date = new Date();
  date.setDate(date.getDay() - 90);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [invoiceOpt, setInVoiceOpt] = useState([]);
  const [images, setImages] = useState([]);
  const [clearImages, setClearImages] = useState(false);
  const [isErrorImg, setErrorImg] = useState(false);
  let selectRef = useRef(null);
  const [noData, setNoData] = useState(false);
  const { storeId, userName, firstName, lastName } = useSelector(
    (state) => state.SessionReducer,
  );
  const [searchFormVal, setSearchFormVal] = useImmer({
    searchBy: "days",
    fromDate: "",
    toDate: "",
    errorFromDate: false,
    errorToDate: false,
  });
  const [showForm, setShowFrom] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [succesPopup, setSuccessPopup] = useImmer({
    open: false,
    crm: "",
    loadfeedbackNbr: "",
  });

  const handleUploadImg = useStableCallback(
    (img) => {
      setClearImages(false);
      setImages(img);
      setErrorImg(false);
    },
    [images],
  );
  const handleSearchBy = (e, data) => {
    if (data.name !== "searchBy") {
      if (data.name === "fromDate") {
        if (data.value === "" || !data.value) {
          setSearchFormVal((draft) => {
            draft.errorFromDate = true;
          });
        } else {
          setSearchFormVal((draft) => {
            draft.errorFromDate = false;
          });
        }
      } else if (data.name === "toDate") {
        if (data.value === "" || !data.value) {
          setSearchFormVal((draft) => {
            draft.errorToDate = true;
          });
        } else {
          setSearchFormVal((draft) => {
            draft.errorToDate = false;
          });
        }
      }
    }
    setSearchFormVal((draft) => {
      draft[data.name] = data.value;
    });
  };
  const handleOnChangeValiadation = (e) => {
    if (!_.isEmpty(e)) {
      if (e?.name === "invoiceNumber") {
        if (e.value === "") {
          return {
            invoiceNumber: {
              value: e.value,
              error: true,
            },
          };
        }
        return {
          invoiceNumber: {
            value: e.value,
            error: false,
          },
        };
      } else if (e?.target?.name === "loadFeedbackComments") {
        if (e.target.value === "") {
          return {
            loadFeedbackComments: {
              value: e.target.value,
              error: true,
            },
          };
        } else {
          if (e.target.value.split("").length > 2000) {
            utilities.showToast("maximum 2000 characters allowed", true);
            return {};
          }
          return {
            loadFeedbackComments: {
              value: e.target.value,
              error: false,
            },
          };
        }
      }
    }
    return;
  };
  const feedbackFrom = useErrorValiadion(
    { invoiceNumber: "", loadFeedbackComments: "" },
    handleOnChangeValiadation,
    handleErrorOnSubmit,
    submitFeedbackForm,
  );
  const {
    values: feedbackFromValues,
    handleOnChange: feedbackFormOnChange,
    error,
    handleSubmit: handleSubmitFeedBack,
    resetForm: resetFeedbackForm,
  } = feedbackFrom;

  const searchInvoice = useStableCallback(() => {
    let { searchBy, fromDate, toDate } = searchFormVal;
    let url = `${GET_LOAD_FEEDBACK_API}/${storeId}/GetLoadFeedbackInvoices`;
    if (searchBy === "invoiceDate") {
      if (fromDate === "" || !fromDate) {
        setSearchFormVal((draft) => {
          draft.errorFromDate = true;
        });
      }
      if (toDate === "" || !toDate) {
        setSearchFormVal((draft) => {
          draft.errorToDate = true;
        });
      }
      if (!fromDate || fromDate === "" || !toDate || toDate === "") {
        utilities.showToast("Please enter valid From and To date", true);
        return;
      }
      url =
        url +
        `?fromDate=${utilities.getDateFromTimeStampShip(fromDate)}&toDate=${utilities.getDateFromTimeStampShip(toDate)}`;
    }
    setIsLoading(true);
    restUtils
      .getDataWithBearer(url)
      .then((res) => {
        if (_.isEmpty(res.data)) {
          setNoData(true);
          setShowFrom(false);
          setIsLoading(false);
          return;
        }
        let data = res.data;
        data = data.map((opt) => {
          return { label: opt, value: opt, name: "invoiceNumber" };
        });
        setNoData(false);
        if (selectRef.current) {
          selectRef.current.clearValue();
        }

        setInVoiceOpt(data);
        setShowFrom(true);
        setNoData(false);
        setTimeout(() => {
          setIsLoading(false);
        }, [2000]);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setShowFrom(false);
      });
  }, []);
  useEffect(() => {
    searchInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClear = () => {
    resetFeedbackForm();
    setImages([]);
    selectRef.current.clearValue();
    setClearImages(true);
  };
  function handleErrorOnSubmit(getValues) {
    let errorKey = { ...error };
    const validationFields = ["invoiceNumber", "loadFeedbackComments"];
    validationFields.forEach((ele) => {
      if (getValues[ele] === "") {
        errorKey[ele] = true;
      } else {
        delete errorKey[ele];
      }
    });
    if (_.isEmpty(images)) {
      setErrorImg(true);
    }
    if (
      feedbackFromValues.invoiceNumber === "" ||
      feedbackFromValues.loadFeedbackComments === "" ||
      _.isEmpty(images)
    ) {
      utilities.showToast("Please Enter All Mandatory Fields", true);
    }
    return errorKey;
  }
  function submitFeedbackForm() {
    if (_.isEmpty(images)) {
      setErrorImg(true);
      return;
    }
    let requestPayLoad = {
      CreatedBy: `${firstName} ${lastName} (${userName.substr(1)})`,
      invoiceNumber: feedbackFromValues.invoiceNumber,
      loadFeedbackComments: feedbackFromValues.loadFeedbackComments,
    };
   setIsLoading(true);
    restUtils
      .postDataWithBearer(
        `${GET_LOAD_FEEDBACK_API}/${storeId}/SaveLoadFeedbackDetails`,
        requestPayLoad,
      )
      .then((resData) => {
        //var objectName = "2024/05/00069/LF00069202405300505/abcd_2024-05-30-05-05-00-111.png";
        let imageObjectNames = images.map(ele=> {
          return {
            ...ele,
            imageName: `${utilities.getFormattedUploadDate()}/${storeId}/${resData?.data?.loadFeedback_Nbr}/${uuid().substring(0, 5)}_${utilities.getFormattedUploadDate_1()}.jpeg` 
        }
    })
        let url = `${FEEDBACK_HISTORY}/${storeId}/GetSignedImageURLsForLfUpload?contentType=image/jpeg`
        uploadImagesToGcp(imageObjectNames,url).then(res=>{
          if(res) {
            Promise.all(res).then(getRes=>{
              if(getRes[0].status === 200) {
                let requestPayLoad = { };
                requestPayLoad =  imageObjectNames.map((imageItem,index)=>{
                  return {
                    "claim_LoadFeedback_Nbr": resData?.data?.loadFeedback_Nbr,
                    "imageObject_Name": imageItem.imageName,
                    "image_Type": "",
                    "created_By":`${firstName} ${lastName} (${userName.substr(1)})`,
                    "created_Date": moment().tz('America/Chicago').format(),
                    "itemNbr": "",
                    "sessionID": ""
                  }
                })
                restUtils
                  .postDataWithBearer(`${GET_LOAD_FEEDBACK_API}/${storeId}/AddLoadFeedbackImageDetailsToDB`,requestPayLoad).then(res=>{
                    console.log('Saved Images to DB')
                    setIsLoading(false);
                    handleClear();
                    setSuccessPopup((draft) => {
                      draft.open = true;
                      draft.crm = resData?.data?.crM_Nbr;
                      draft.loadfeedbackNbr = resData?.data?.loadFeedback_Nbr;
                    });
                    }).catch(err=>{
                      console.log(err)
                      setIsLoading(false);
                    })
              }else {
                utilities.showToast('Images Not Uploaded to GCP', true)
                setIsLoading(false);
              }
              
             })
            }
            else {
              utilities.showToast('Images Not Uploaded to GCP', true)
              setIsLoading(false);
            }
          })
     
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    if (searchFormVal.searchBy === "invoiceDate") {
      setShowDateFilter(true);
    } else {
      setSearchFormVal((draft) => {
        draft.fromDate = "";
        draft.toDate = "";
      });
      setShowDateFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFormVal.searchBy]);
  const clearSearchInvoice = () => {
    setSearchFormVal((draft) => {
      draft.searchBy = "days";
      draft.fromDate = "";
      draft.toDate = "";
    });
    let url = `${GET_LOAD_FEEDBACK_API}/${storeId}/GetLoadFeedbackInvoices`;
    setIsLoading(true);
    restUtils
      .getDataWithBearer(url)
      .then((res) => {
        if (_.isEmpty(res.data)) {
          setNoData(true);
          setShowFrom(false);
          return;
        }
        let data = res.data;
        data = data.map((opt) => {
          return { label: opt, value: opt };
        });
        setNoData(false);
        setInVoiceOpt(data);
        setShowFrom(true);
        setNoData(false);
        setTimeout(() => {
          setIsLoading(false);
        }, [2000]);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setShowFrom(false);
      });
  };
  const closeMsgPopup = () => {
    setSuccessPopup((draft) => {
      draft.open = false;
      draft.value = "";
    });
  };

  return (
    <>
      {isLoading && (
        <div className="commonLoader">
          <Loader active></Loader>
        </div>
      )}
      <div className="loadFeedbackWrapper">
        <h3>Load Feedback Form</h3>
        <BreadcrumbComp
          path={[
            { link: "/", text: "Home" },
            { link: "/loadFeedback", text: "Load Feedback", active: true },
            { link: "/loadFeedbackHistory", text: "View History" },
            { link: "/", text: "Inactive" },
          ]}
        ></BreadcrumbComp>
        <div className="loadFeedbackSearch">
          <Form>
            <FormGroup>
              <label>Search By:</label>
              <FormGroup>
                <FormField
                  control={Radio}
                  value="days"
                  checked={searchFormVal.searchBy === "days"}
                  label="90 Days"
                  name="searchBy"
                  onChange={handleSearchBy}
                ></FormField>
                <FormField
                  control={Radio}
                  value="invoiceDate"
                  label="Invoice Date"
                  name="searchBy"
                  checked={searchFormVal.searchBy === "invoiceDate"}
                  onChange={handleSearchBy}
                ></FormField>
              </FormGroup>
              {showDateFilter && (
                <div className="dateWrapper">
                  <SemanticDatepicker
                    name="fromDate"
                    onChange={handleSearchBy}
                    id="startDatePicker"
                    className={
                      searchFormVal.errorFromDate ? "errorDateField" : ""
                    }
                    minDate={date}
                    maxDate={new Date()}
                    placeholder={"From Date"}
                  />
                  <SemanticDatepicker
                    name="toDate"
                    onChange={handleSearchBy}
                    id="startDatePicker"
                    className={
                      searchFormVal.errorToDate ? "errorDateField" : ""
                    }
                    minDate={
                      searchFormVal.fromDate !== "" && searchFormVal.fromDate
                    }
                    maxDate={new Date()}
                    placeholder={"To Date"}
                  />
                </div>
              )}
              <div className="btnWrapper">
                <button className="ui button redBtn" onClick={searchInvoice}>
                  Search
                </button>
                <button className="ui button" onClick={clearSearchInvoice}>
                  Clear
                </button>
              </div>
            </FormGroup>
          </Form>
        </div>
        {noData && <p className="noData">No Item to Display</p>}
        {showForm && (
          <div className="loadFeedbackUpload">
            <Form>
              <div
                className="field customSelectBox"
                id={error?.invoiceNumber ? "errorField" : ""}
              >
                <label style={{ display: "block" }}>
                  Invoice Number <span className="mandatoryField">*</span>
                </label>
                <WindowedSelect
                  options={invoiceOpt}
                  ref={selectRef}
                  onChange={feedbackFormOnChange}
                  isLoading={isLoading}
                />
              </div>
              <FormField
                label="Feedback Comments"
                name="loadFeedbackComments"
                placeholder="Enter Feedback Comments"
                width={16}
                control="textarea"
                error={error?.loadFeedbackComments}
                rows="3"
                required
                value={feedbackFromValues?.loadFeedbackComments}
                onChange={feedbackFormOnChange}
              />
              <UploadImage
                handleUploadImg={handleUploadImg}
                maxImage={10}
                clearImages={clearImages}
                label={"Load Feedback images"}
                mandatory={true}
                isErrorImg={isErrorImg}
              />
            </Form>
            <div className="btnWrapper">
              <button className="ui button" onClick={handleClear}>
                Clear
              </button>
              <button
                className="ui button redBtn"
                onClick={handleSubmitFeedBack}
              >
                Submit
              </button>
            </div>
          </div>
        )}
        {succesPopup && (
          <Modal className="viewMsgPopup" open={succesPopup.open}>
            <ModalContent>
              <div>
                <p>Load Feedback Form Submitted Successfully</p>
                <p>CRM Nbr:{succesPopup.crm}</p>
                <p>Load Feedback Nbr:{succesPopup.loadfeedbackNbr}</p>
              </div>
              <p>
                <button className="closeInfoMsg" onClick={closeMsgPopup}>
                  Close
                </button>
              </p>
            </ModalContent>
          </Modal>
        )}
      </div>
    </>
  );
};

export default LoadFeedback;
