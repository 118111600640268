import React, { useEffect, useState } from "react";
import "./style.scss";
import { Grid, Segment, GridColumn, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { SearchSku } from "../common/SearchSku";
import useStableCallback from "../common/CustomHook/useStableCallback";
import { useDispatch, useSelector } from "react-redux";
import { retrunToMol, updateRdc } from "../Login/actions";
import history from "../../history";
import _ from "lodash";
import {
  CLAIMS_BASE_API,
  GET_STOREINFO,
  getPolicyBLink,
  getRedirectUriNWH,
} from "../../urlConstants";
import { useImmer } from "use-immer";
import restUtils from "../../utils/restUtils";
export const Home = () => {
  const [openSearch, setOpenSearch] = useState(false);
  const { storeId, userguid, csr, rdc } = useSelector(
    (state) => state.SessionReducer,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [counts, setCounts] = useImmer({
    claims: "",
    policyB: "",
    loadFeedback: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (_.isEmpty(rdc)) {
      const payLoad = {
        storeNumber: storeId,
      };
      restUtils
        .postDataWithBearer(GET_STOREINFO, payLoad)
        .then((res) => {
          dispatch(updateRdc(res.data.ManinRDCNumber));
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, rdc]);
  useEffect(() => {
    setIsLoading(true);
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/GetDashboardClaimsCount`,
      )
      .then((res) => {
        setCounts((draft) => {
          draft.claims = res.data.claimsData;
          draft.policyB = res.data.policyBClaimsData;
          draft.loadFeedback = res.data.loadFeedbackData;
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
      history.push('/home')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId]);
  const closeSearchPopUp = useStableCallback(() => {
    setOpenSearch(false);
  }, [openSearch]);
  const redirectPolicyB = (val) => {
    setIsLoading(true);
    restUtils
      .getDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/GetPolicyBClaimGuidToNavigate?userGuid=${userguid}`,
      )
      .then((res) => {
        if (val === "FORM") {
          dispatch(
            retrunToMol(
              `${getPolicyBLink()}?guid=${res.data}&csr=${csr}&startpage=claimform`,
            ),
          );
        } else {
          dispatch(
            retrunToMol(
              `${getPolicyBLink()}?guid=${res.data}&csr=${csr}&startpage=history`,
            ),
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  
  return (
    <>
      {isLoading && (
        <div className="commonLoader">
          <Loader active></Loader>
        </div>
      )}
      <div className="homePageWrapper">
        <Segment>
          <Grid>
            <GridColumn mobile={16} computer={5} className="menuBox">
              <div className="infoBtnBox">
                <div
                  className="informationBox"
                  onClick={() => {
                    history.push("/ClaimsHistory");
                  }}
                >
                  <h4>Claims</h4>
                  <p>
                    <span>{counts.claims}</span>
                    <span>Claims Submitted</span>
                  </p>
                </div>
                <div className="infoBtnLink">
                  <button
                    onClick={() => {
                      setOpenSearch(true);
                    }}
                    className="linkBtn"
                  >
                    Create a new claim
                  </button>
                  <Link to={{ pathname: "/ClaimsHistory" }} className="linkBtn">
                    Claim History
                  </Link>
                  <button
                    className="linkBtn"
                    onClick={() =>
                      dispatch(
                        retrunToMol(
                          `${getRedirectUriNWH()}/content/claims-and-requests-for-adjustment`,
                        ),
                      )
                    }
                  >
                    Policies/Help
                  </button>
                </div>
              </div>
              <div className="descriptionBox">
              <p>Submit RFA & Policy A (includes drop ship) claims</p>
              </div>
            </GridColumn>
            <GridColumn mobile={16} computer={5} className="menuBox">
              <div className="infoBtnBox">
                <div
                  className="informationBox"
                  onClick={() => redirectPolicyB("HISTORY")}
                >
                  <h4>Policy B Claims</h4>
                  <p>
                    <span>{counts.policyB}</span>
                    <span>Claims Submitted</span>
                  </p>
                </div>
                <div className="infoBtnLink">
                  <button
                    className="linkBtn"
                    onClick={() => redirectPolicyB("FORM")}
                  >
                    Policy B Claim Form
                  </button>
                  <button
                    className="linkBtn"
                    onClick={() => redirectPolicyB("HISTORY")}
                  >
                    Policy B Claim History
                  </button>
                  <button
                    className="linkBtn"
                    onClick={() =>
                      dispatch(
                        retrunToMol(
                          `${getRedirectUriNWH()}/content/claims-and-requests-for-adjustment`,
                        ),
                      )
                    }
                  >
                    Policies/Help
                  </button>
                </div>
              </div>
              <div className="descriptionBox">
                <p>Submit Policy B claims for stock.</p>
              </div>
            </GridColumn>
            <GridColumn mobile={16} computer={5} className="menuBox">
              <div className="infoBtnBox">
                <div
                  className="informationBox"
                  onClick={() => {
                    history.push("/loadFeedbackHistory");
                  }}
                >
                  <h4>Load Feedback</h4>
                  <p>
                    <span>{counts.loadFeedback}</span>
                    <span>Load Feedback Submitted</span>
                  </p>
                </div>
                <div className="infoBtnLink">
                  <Link to={{ pathname: "/loadFeedback" }} className="linkBtn">
                    Load Feedback Form
                  </Link>
                  <Link
                    to={{ pathname: "/loadFeedbackHistory" }}
                    className="linkBtn"
                  >
                    Load Feedback History
                  </Link>
                  <button
                    className="linkBtn"
                    onClick={() =>
                      dispatch(
                        retrunToMol(
                          `${getRedirectUriNWH()}/content/claims-and-requests-for-adjustment`,
                        ),
                      )
                    }
                  >
                    Policies/Help
                  </button>
                </div>
              </div>
              <div className="descriptionBox">
                <p>Submit Load Feedback for stock.</p>
              </div>
            </GridColumn>
          </Grid>
        </Segment>
        {openSearch && <SearchSku closePoup={closeSearchPopUp} />}
      </div>
    </>
  );
};
export default Home;
