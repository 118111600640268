import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { logout } from "../../views/Login/actions";
import Home from "../../views/Home";
import Header from "../../components/NavBar/Header";
import AuthPage from "./AuthPage";
import { connect } from "react-redux";
import history from "../../history";
import { Loader } from "semantic-ui-react";
import Footer from "./Footer";
import AuthLoader from "./AuthLoader";
import NotFound from "../../views/ErrorPages/404_error_page";
import Scanner from "../Scanner";
import UploadImage from "../UploadImage";
import ClaimsHistory from "../ClaimsHistory";
import ConfirmItem from "../ConfirmItem";
import CreateClaims from "../CreateClaims";
import ConfirmationPage from "../ConfirmationPage";
import LoadFeedback from "../LoadFeedback";
import LoadFeedbackHistory from "../LoadFeedback/LoadFeedbackHistory";
import ClaimsDetails from "../ClaimsHistory/ClaimsDetails";
import EditClaim from "../ClaimsHistory/EditClaim";
import DeletedItem from "../ClaimsHistory/DeletedItem";
import SearchItem from "../PolicyAsearch/SearchItem";
import DirectShipSearch from "../PolicyAsearch/DirectShipSearch";
import SearchSku from "./SearchSku";
import InternalServerError from "../ErrorPages/500_error_page";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    this.clearSessionTimeout = this.clearSessionTimeout.bind(this);
    this.setSessionTimeout = this.setSessionTimeout.bind(this);

    for (let i in this.events) {
      window.addEventListener(this.events[i], this.clearSessionTimeout);
    }
    this.setSessionTimeout();
  }
  clearSessionTimeout() {
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }

    this.setSessionTimeout();
  }

  setSessionTimeout() {
    this.logoutTimeout = setTimeout(
      () => {
        this.props.history.push("/");
        this.props.logout();
      },
      30 * 60 * 1000,
    );
  }

  render() {
    return (
      <>
        <Header content={this.props.children} />
        <Footer />
      </>
    );
  }
}

const mapStateToLayoutProps = (state) => {
  return {
    ...state.SessionReducer,
  };
};

const LayoutContainer = connect(mapStateToLayoutProps, { logout })(Layout);

class RenderRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      renderComp: false,
      // If we need Layout and AuthErrorBoundary for a screen, need_layout_and_auth flag should be yes
      // If we have to pass any params to a screen need_props should be yes.
      list_of_pages: [
        // {
        //   path: '/error',
        //   component: ErrorPage,
        //   need_props: 'no'
        // },
        {
          path: "/serverError",
          component: InternalServerError,
          need_props: "no",
        },
        {
          path: "/",
          component: Home,
          need_props: "no",
        },
        {
          path: "/index.html",
          component: Home,
          need_props: "no",
        },
        {
          path: "/home",
          component: Home,
          need_props: "yes",
        },
        {
          path: "/scan",
          component: Scanner,
          need_props: "no",
        },
        {
          path: "/product/:sku",
          component: ConfirmItem,
          need_props: "yes",
        },
        {
          path: "/uploadImg",
          component: UploadImage,
          need_props: "no",
        },
        {
          path: "/ClaimsHistory",
          component: ClaimsHistory,
          need_props: "yes",
        },
        {
          path: "/createClamis",
          component: CreateClaims,
          need_props: "yes",
        },
        {
          path: "/claimConfirmation",
          component: ConfirmationPage,
          need_props: "yes",
        },
        {
          path: "/loadFeedback",
          component: LoadFeedback,
          need_props: "yes",
        },
        {
          path: "/loadFeedbackHistory",
          component: LoadFeedbackHistory,
          need_props: "yes",
        },
        {
          path: "/claimsDetails",
          component: ClaimsDetails,
          need_props: "yes",
        },
        {
          path: "/editClaim",
          component: EditClaim,
          need_props: "yes",
        },
        {
          path: "/claimDeletedItem",
          component: DeletedItem,
          need_props: "yes",
        },
        {
          path: "/searchItem",
          component: SearchItem,
          need_props: "yes",
        },
        {
          path: "/directShipSearch",
          component: DirectShipSearch,
          need_props: "yes",
        },
        {
          path: "/newClaim",
          component: SearchSku,
          need_props: "yes",
        },
        {
          path: "",
          component: NotFound,
          need_props: "no",
        },
      ],
    };
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.showLoader !== this.state.showProps) {
      this.setState({ showLoader: newProps.showLoader });
    }
    if (newProps.storeId !== this.state.renderComp) {
      this.setState({ renderComp: newProps.storeId });
    }
  }
  getRenderRoute(component, props) {
    const { showLoader } = this.state;
    return (
      <AuthPage>
        <LayoutContainer
          isAuthenticated={this.props.isAuthenticated}
          {...props}
        >
          {component}
          {showLoader && (
            <div className="commonLoader">
              <Loader active />
            </div>
          )}
        </LayoutContainer>
      </AuthPage>
    );
  }

  render() {
    return (
      <>
        {!this.props.isAuthenticated && <AuthLoader />}
        <Router history={history}>
          <Switch>
            {this.state.list_of_pages.map((item, index) => (
              <Route
                key={index}
                exact
                path={item.path}
                render={(props) =>
                  this.getRenderRoute(<item.component {...props} />, props)
                }
              />
            ))}
          </Switch>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.SessionReducer.isAuthenticated,
  showLoader: state.UtilityReducer.showLoader,
  storeId: state.SessionReducer.storeId,
});

export default connect(mapStateToProps)(RenderRoutes);
